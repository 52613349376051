import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";

const FilterModal = ({
  show, 
  setShow, 
  filters,
  setFilters,
  filterOptions,
  onApply,
  onReset
}) => {
  const [localFilters, setLocalFilters] = useState({});

  useEffect(() => {
    if (show) {
      setLocalFilters(filters);
    }
  }, [show, filters]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setLocalFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleApplyFilters = () => {
    if (onApply) {
      onApply(localFilters);
    } else {
      setFilters(localFilters);
    }
    setShow(false);
  };

  const handleResetFilters = () => {
    const emptyFilters = Object.keys(localFilters).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});
    setLocalFilters(emptyFilters);
    if (onReset) {
      onReset(emptyFilters);
    } else {
      setFilters(emptyFilters);
    }
    setShow(false);
  };

  
  const content = (
    <div className="flex gap-1 flex-wrap flex-col px-2">
      {filterOptions.map((option) => (
        <div key={option.name} className="flex-1 ">
          <label
            htmlFor={option.name}
            className="block truncate text-sm font-medium leading-6 text-gray-900"
          >
            {option.label}
          </label>
          {option.type === "selector" ? (
            <select
              name={option.name}
              id={option.name}
              className="mt-2 block w-full rounded-sm border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6"
              value={localFilters[option.name] || ""}
              onChange={handleFilterChange}
            >
              {option.options.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          ) : (
            <input
              type={option.type}
              name={option.name}
              id={option.name}
              className="mt-2 block w-full rounded-sm border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6"
              placeholder={option.label}
              value={localFilters[option.name] || ""}
              onChange={handleFilterChange}
            />
          )}
        </div>
      ))}

      <div className="flex w-full gap-2 mt-4">
        <button
          onClick={handleResetFilters}
          className="flex-1 bg-gray-100 rounded-sm text-gray-800 font-medium py-1.5"
        >
          Reset Filters
        </button>
        <button
          onClick={handleApplyFilters}
          className="flex-1 bg-accent-100 rounded-sm text-white font-medium py-1.5"
        >
          Apply Filters
        </button>
      </div>
    </div>
  );

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="flex h-full flex-col overflow-auto bg-white ">
                  <div className="px-4 sm:px-6 border-b border-gray-200">
                    <div className="flex items-start justify-between ">
                      <div>
                        <Dialog.Title className="text-base font-semibold leading-4 text-secondary ">
                          Advanced Filters
                        </Dialog.Title>
                        <Dialog.Description
                          className={"text-sm leading-2 text-gray-500 py-2"}
                        >
                          Search for just what you need
                        </Dialog.Description>
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="justify-center items-center relative rounded-sm bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                          onClick={() => setShow(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close
                            style={{ fontSize: "1rem" }}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-2">{content}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default FilterModal;

