import React, { useState, useEffect, useContext, Fragment } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import {
  ArrowDropDown,
  ArrowDropUp,
  CalendarMonthOutlined,
  Person,
} from "@mui/icons-material";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import GenericModal from "../modals/GenericModal";
import StatusBadge from "../general-ui/StatusBadges";
import { calculateDays, capitalizeString } from "../../utils/FormatFunctions";

const WorkOrdersTable = ({
  workOrders,
  setWorkOrders,
  users,
  setSelectedNotes,
  handleView,
  searchParams,
  updateSearchParams,
}) => {
  const sortColumn = searchParams.get("sortColumn") || "dateReported";
  const sortDirection = searchParams.get("sortOrder") || 1;

  const { currentUser } = useContext(AuthContext);

  const [showCloseout, setShowCloseout] = useState(false);
  const [selectedToClose, setSelectedToClose] = useState(null);

  // Function to handle sorting
  const handleSort = (column) => {
    if (column === sortColumn) {
      // If the same column is clicked again, toggle the sort direction
      updateSearchParams({ sortOrder: -sortDirection, sortColumn: column });
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending order
      updateSearchParams({ sortOrder: 1, sortColumn: column });
    }
  };

  const handleAssignment = (workOrder, selectedUser) => {
    const workOrderId = workOrder._id;
    const userObject = users.find((user) => user._id === selectedUser);

    axios
      .put(
        `/api/work-order/assign/${workOrderId}`,
        {
          assignedTo: selectedUser === "unassign" ? null : selectedUser,
          assignedBy: currentUser._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Work order assigned successfully:", response.data);
        // setRefresh(!refresh);
        const updatedWorkOrders = workOrders.map((wo) => {
          if (wo._id === workOrderId) {
            const updatedHistory = [
              ...wo.history,
              {
                completedBy: currentUser,
                assignedTo: selectedUser === "unassign" ? null : userObject,
                timestamp: new Date(),
                action: "assigned",
              },
            ];
            return {
              ...wo,
              assignedTo: selectedUser === "unassign" ? null : userObject,
              history: updatedHistory,
            };
          }
          return wo;
        });
        setWorkOrders(updatedWorkOrders);
      })
      .catch((error) => {
        console.error("Error assigning work order:", error);
      });
  };

  const handleNotes = (task) => {
    setSelectedNotes(task);
  };

  return (
    <table className="min-w-full">
      <thead>
        <tr
          key={"header"}
          className="sticky top-0 outline outline-1 outline-secondary-1000 rounded-t-sm shadow-sm cursor-pointer bg-secondary-1200"
        >
          <th
            onClick={() => handleSort("title")}
            scope="col"
            className="py-3.5 group pl-4 pr-2 text-left text-sm font-medium text-secondary-100 cursor-pointer"
          >
            <a className="sm:inline-flex hidden">Title</a>
            <a className="inline-flex sm:hidden">Work Order</a>
            {sortColumn === "title" ? (
              sortDirection === "1" ? (
                <ArrowDropDown className="text-secondary-500" />
              ) : (
                <ArrowDropUp className="text-secondary-500" />
              )
            ) : (
              <ArrowDropDown className="text-transparent group-hover:text-secondary-400" />
            )}
          </th>
          {/* <th
                    // onClick={() => handleSort("_id")}
                    scope="col"
                    className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer 2xl:table-cell"
                  >
                    <a className="inline-flex">ID</a>
                    {/* {sortColumn === "ID" ? (
                      sortDirection === "1" ? (
                        <ArrowDropDown className="text-secondary-500" />
                      ) : (
                        <ArrowDropUp className="text-secondary-500" />
                      )
                    ) : (
                      <ArrowDropDown className="text-transparent group-hover:text-secondary-400" />
                    )} 
                  </th> */}
          <th
            onClick={() => handleSort("dateReported")}
            scope="col"
            className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
          >
            <a className="inline-flex">Reported</a>
            {sortColumn === "dateReported" ? (
              sortDirection === "1" ? (
                <ArrowDropDown className="text-secondary-500" />
              ) : (
                <ArrowDropUp className="text-secondary-500" />
              )
            ) : (
              <ArrowDropDown className="text-transparent group-hover:text-secondary-400" />
            )}
          </th>
          {/* <th
              onClick={() => handleSort("requestedBy")}
              scope="col"
              className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer xl:table-cell"
            >
              <a className="group inline-flex">Requested By</a>
              {sortColumn === "requestedBy" ? (
                sortDirection === "1" ? (
                  <ArrowDropDown className="text-secondary-500" />
                ) : (
                  <ArrowDropUp className="text-secondary-500" />
                )
              ) : (
                <ArrowDropDown className="text-transparent group-hover:text-secondary-400" />
              )}
            </th> */}
          {/* <th
              onClick={() => handleSort("requestType")}
              scope="col"
              className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer 2xl:table-cell"
            >
              <a className="inline-flex"> Category</a>
              {sortColumn === "requestType" ? (
                sortDirection === "1" ? (
                  <ArrowDropDown className="text-secondary-500" />
                ) : (
                  <ArrowDropUp className="text-secondary-500" />
                )
              ) : (
                <ArrowDropDown className="text-transparent group-hover:text-secondary-400" />
              )}
            </th> */}

          <th
            onClick={() => handleSort("location")}
            scope="col"
            className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer xl:table-cell"
          >
            <a className="inline-flex">Location</a>
            {sortColumn === "location" ? (
              sortDirection === "1" ? (
                <ArrowDropDown className="text-secondary-500" />
              ) : (
                <ArrowDropUp className="text-secondary-500" />
              )
            ) : (
              <ArrowDropDown className="text-transparent group-hover:text-secondary-400" />
            )}
          </th>
          <th
            onClick={() => handleSort("urgency")}
            scope="col"
            className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
          >
            <a className="inline-flex">Urgency</a>
            {sortColumn === "urgency" ? (
              sortDirection === "1" ? (
                <ArrowDropDown className="text-secondary-500" />
              ) : (
                <ArrowDropUp className="text-secondary-500" />
              )
            ) : (
              <ArrowDropDown className="text-transparent group-hover:text-secondary-400" />
            )}
          </th>
          <th
            onClick={() => handleSort("status")}
            scope="col"
            className="group  hidden px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
          >
            <a className="inline-flex">Status</a>
            {sortColumn === "status" ? (
              sortDirection === "1" ? (
                <ArrowDropDown className="text-secondary-500" />
              ) : (
                <ArrowDropUp className="text-secondary-500" />
              )
            ) : (
              <ArrowDropDown className="text-transparent group-hover:text-secondary-400" />
            )}
          </th>
          <th
            scope="col"
            className="hidden px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
          >
            <a className="group inline-flex">Assigned to</a>
          </th>
          {/*                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer"
                  >
                    <a className="group inline-flex">View</a>
                  </th> */}

          {/*     <th scope="col" className="relative py-3.5 pl-3 pr-0">
              <span className="sr-only">Edit</span>
            </th> */}
        </tr>
      </thead>
      <tbody>
        {workOrders.map((workOrder) => (
          <Fragment key={workOrder._id}>
            <tr key={`${workOrder._id}-gap`} className="h-2"></tr>
            <tr
              key={workOrder._id}
              className="outline outline-1 outline-secondary-1000 rounded-sm cursor-pointer hover:bg-secondary-1200"
              onClick={() => handleView(workOrder)}
            >
              <td className="py-1 pl-4 pr-2 text-sm font-medium  text-secondary-100 ">
                <span className="sm:max-w-96 max-w-56 flex overflow-auto text-sm">
                  {capitalizeString(workOrder.title)}
                </span>
                <dl className="">
                  <dt className="sr-only hidden">Details</dt>
                  <dd className="mt-1 sm:max-w-72 max-w-56  text-secondary-100  text-xs font-light">
                    {workOrder.details}
                  </dd>
                  <dt className="sr-only hidden">Days since reported</dt>
                  <dd className="mt-1 truncate text-secondary-100 text-xs sm:hidden font-light">
                    <CalendarMonthOutlined style={{ fontSize: "1rem" }} />{" "}
                    {calculateDays(workOrder.dateReported)} days ago
                  </dd>

                  <dd className="sm:hidden mt-1 flex w-full justify-between text-secondary-100 font-normal text-xs">
                    <StatusBadge status={workOrder.status} />

                    <span className="">
                      {workOrder.assignedTo ? (
                        <span>
                          <Person
                            style={{ fontSize: "1rem" }}
                            className="h-4 w-4 mr-1"
                          />
                          {capitalizeString(
                            workOrder.assignedTo.firstname.slice(0, 1)
                          )}{" "}
                          {capitalizeString(workOrder.assignedTo.lastname)}
                        </span>
                      ) : (
                        <span>
                          <Person
                            style={{ fontSize: "1rem" }}
                            className="h-4 w-4 mr-1"
                          />
                          Unassigned
                        </span>
                      )}
                    </span>
                  </dd>

                  <dt className="sr-only hidden">Status and urgency</dt>
                  <dd className="mt-1 truncate text-secondary-100 sm:hidden flex w-full justify-between my-2">
                    <StatusBadge status={workOrder.urgency} />
                    <span className="text-secondary-100 font-normal text-xs">
                      ID {workOrder?.shortId}
                    </span>
                  </dd>
                </dl>
              </td>
              <td className="hidden whitespace-nowrap px-2 py-1 text-left text-xs text-secondary-600 sm:table-cell">
                {calculateDays(workOrder.dateReported)} days ago
              </td>

              <td
                className="hidden whitespace-nowrap px-2 py-1 text-sm text-secondary-400 xl:table-cell"
                style={{
                  maxWidth: "100px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {capitalizeString(workOrder.location)}
              </td>
              <td className="hidden whitespace-nowrap px-2 py-1 text-sm text-secondary-400 sm:table-cell">
                <StatusBadge status={workOrder.urgency} />
              </td>

              <td className=" whitespace-nowrap px-2 py-1 text-sm text-secondary-400 hidden sm:table-cell">
                <StatusBadge status={workOrder.status} />
              </td>

              <td className="hidden whitespace-nowrap px-2 py-1 text-left text-sm text-secondary-400 sm:table-cell">
                <div onClick={(e) => e.stopPropagation()}>
                  <AssignmentSelector
                    users={users}
                    selectedUser={
                      workOrder.assignedTo ? workOrder.assignedTo : ""
                    }
                    workOrder={workOrder}
                    handleChange={handleAssignment}
                  />
                </div>
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default WorkOrdersTable;
