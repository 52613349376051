import { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  AttachmentOutlined,
  ListAltOutlined,
  TimelineOutlined,
  UnarchiveOutlined,
} from "@mui/icons-material";
import Lightbox from "../../updatedcomponents/data-display/LightBox";
import { AuthContext } from "../../context/AuthContext";
import WorkOrderHistory from "../../updatedcomponents/data-display/WorkOrderHistory";
import Attachments from "../../updatedcomponents/data-display/Attachments";
import Details from "../../updatedcomponents/data-display/WorkOrderInfo";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import { useSwipeable } from "react-swipeable";
import IncompleteNotes from "../../components/incompletenotes/IncompleteNotes";
import { WorkOrderContext } from "../../context/WorkOrderContext";

const WorkOrderDetails = ({
  permissions,
  setSelectedNotes,
  images,
  users,
  setWorkOrder,
  workOrder
}) => {
  const { currentUser } = useContext(AuthContext);

  const { updateWorkOrderInContext } = useContext(WorkOrderContext);

  const handleAssignment = (workOrder, selectedUser) => {
    const workOrderId = workOrder._id;
    const userObject = users.find((user) => user._id === selectedUser);

    console.log("Assigning work order:", workOrderId, selectedUser);

    axios
      .put(
        `/api/work-order/assign/${workOrderId}`,
        {
          assignedTo: selectedUser === "unassign" ? null : selectedUser,
          assignedBy: currentUser._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Work order assigned successfully:", response.data);
        const updatedWorkOrder = {
          ...workOrder,
          assignedTo: userObject,
          history: [
            ...workOrder.history,
            {
              completedBy: currentUser,
              assignedTo: selectedUser === "unassign" ? null : userObject,
              timestamp: new Date(),
              action: "assigned",
            },
          ],
        }
        setWorkOrder(updatedWorkOrder);
        updateWorkOrderInContext(updatedWorkOrder);
      })
      .catch((error) => {
        console.error("Error assigning work order:", error.response?.data || error.message);
      });
  };

  const [showUnable, setShowUnable] = useState(false);
  const [unableToCompleteWorkOrder, setUnableToCompleteWorkOrder] =
    useState(null);

  const [note, setNote] = useState("");

  const handleMarkUnable = async (event, workOrder) => {
    event.preventDefault();

    if (!note || note.trim() === "") {
      console.error("Justification required.");
      return; // Exit the function without making the request
    }
    const workOrderId = workOrder._id;

    try {
      const response = await axios
        .put(
          `/api/employee-tasks/unable/${workOrderId}`,

          {
            note: note,
            user: currentUser._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log("Work order marked as unable:", response.data);
          setWorkOrder((wo) => {
            return {
              ...wo,
              status: "unable",
              history: [
                ...wo.history,
                {
                  completedBy: currentUser,
                  timestamp: new Date(),
                  action: "unable",
                },
              ],
              notes: [
                ...wo.notes,
                { text: note, userId: currentUser, timestamp: new Date() },
              ],
            };
          });
        })
        .finally(() => {
          setShowUnable(false);
        });
    } catch (error) {
      console.error("Error marking work order as unable:", error);
    }
  };

  const handleWorkOrderStatusChange = async (event, workOrder, status) => {
    event.preventDefault();

    const workOrderId = workOrder._id;
    let url;
    let payload;

    switch (status) {
      case "complete":
        url = `/api/employee-tasks/complete/${workOrderId}`;
        payload = { completedBy: currentUser._id };
        break;
      case "incomplete":
        url = `/api/work-order/re-open/${workOrderId}`;
        payload = { reopenedBy: currentUser._id };
        break;
      case "unable":
        console.log("Unable to complete work order");
        break;
      default:
        console.error("Invalid status.");
        return;
    }

    try {
      if (status === "unable") {
        setUnableToCompleteWorkOrder(workOrder);
        setShowUnable(true);
      } else if (status !== "unable") {
        await axios
          .put(url, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            console.log(`Work order marked as ${status}:`, response.data);
            setWorkOrder((prev) => {
              return {
                ...prev,
                status: status,
                history: [
                  ...prev.history,
                  {
                    completedBy: currentUser,
                    timestamp: new Date(),
                    action: status,
                  },
                ],
              };
            });
          });
      }
    } catch (error) {
      console.error(`Error marking work order as ${status}:`, error);
    }
  };

  const [showCloseout, setShowCloseout] = useState(false);

  const handleClickClosed = () => {
    setShowCloseout(true);
  };

  const handleCloseOut = (workOrder) => {
    const workOrderId = workOrder._id;
    axios
      .put(
        `/api/work-order/close-out/${workOrderId}`,
        {
          closedBy: currentUser,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Work order closed successfully:", response.data);
        setShowCloseout(false);
        setWorkOrder((prev) => {
          return {
            ...prev,
            status: "closed",
            history: [
              ...prev.history,
              {
                completedBy: currentUser,
                timestamp: new Date(),
                action: "closed",
              },
            ],
          };
        });
      })
      .catch((error) => {
        console.error("Error closing work order:", error);
      });
  };

  const [showReopen, setShowReopen] = useState(false);

  const handleReopenClick = () => {
    setShowReopen(true);
  };

  const handleReopen = (workOrder) => {
    const workOrderId = workOrder._id;
    const token = localStorage.getItem("token");
    axios
      .put(
        `/api/work-order/re-open/${workOrderId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // update te status of the work order
        setWorkOrder((prev) => {
          return { ...prev, status: "incomplete" };
        });
        setShowReopen(false);
      })
      .catch((error) => {
        console.error("Error reopening work order:", error);
      });
  };

  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleDelete = () => {
    const workOrderId = workOrder._id;
    axios
      .delete(`/api/work-order/delete/${workOrderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        setShowDelete(false);
      })
      .finally(() => {
        window.history.back();
      })
      .catch((error) => {
        console.error("Error deleting work order:", error);
      });
  };

  const dataToRender = [
    { title: "Title", dataTag: "title", dataType: "text" },
    {
      title: "Status",
      dataTag: "status",
      dataType: "selector",
      options: ["closed", "incomplete", "unable"],
    },
    {
      title: "Request Type",
      dataTag: "requestType",
      dataType: "selector",
      options: ["not-specified", "Electrical", "Plumbing", "HVAC", "Other"],
    },
    {
      title: "Date Reported",
      dataTag: "dateReported",
      dataType: "datetime-local",
    },
    { title: "Location", dataTag: "location", dataType: "text" },
    {
      title: "Urgency",
      dataTag: "urgency",
      dataType: "selector",
      options: ["urgent", "standard"],
    },
    { title: "Details", dataTag: "details", dataType: "textarea" },
    { title: "Requested By", dataTag: "requestedBy", dataType: "text" },
    { title: "Request Origin", dataTag: "requestOrigin", dataType: "text" },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const [navItems, setNavItems] = useState([
    {
      name: "Details",
      icon: <ListAltOutlined style={{ fontSize: "1rem" }} />,
      component: Details,
      current: true,
      access: [
        "worker",
        "regional",
        "director",
        "administrator",
        "organization-admin",
        "system-admin",
      ],
    },
    {
      name: "Activity",
      component: WorkOrderHistory,
      icon: <TimelineOutlined style={{ fontSize: "1rem" }} />,
      current: false,
      access: [
        "regional",
        "director",
        "administrator",
        "organization-admin",
        "system-admin",
      ],
    },
    {
      name: "Attachments",
      component: Attachments,
      icon: <AttachmentOutlined style={{ fontSize: "1rem" }} />,
      current: false,
      access: [
        "worker",
        "regional",
        "director",
        "administrator",
        "organization-admin",
        "system-admin",
      ],
    },
  ]);

  const viewBar = () => {
    return (
      <div className="bg-primary py-1 flex sticky items-center justify-evenly w-full">
        {navItems.map((item, index) => {
          if (item.access.includes(permissions)) {
            return (
              <button
                key={index}
                onClick={() => {
                  setNavItems((prev) =>
                    prev.map((navItem) => {
                      return {
                        ...navItem,
                        current: navItem.name === item.name ? true : false,
                      };
                    })
                  );
                }}
                className={classNames(
                  item.current
                    ? "text-secondary-100 border-b-2 border-secondary-100"
                    : "text-secondary-600 hover:text-secondary-400 border-b border-secondary-1000 hover:border-secondary-700",
                  "px-3 py-2 text-sm font-medium w-full flex justify-center items-center gap-2"
                )}
              >
                {item.icon} {item.name}
              </button>
            );
          }
          return null;
        })}
      </div>
    );
  };

  return (
    <div
      className="relative px-4 sm:px-6 flex flex-col h-[calc(100vh-205px)] md:h-[calc(100vh-126px)] overflow-y-auto thin-scrollbar"
    >
      {viewBar()}

      {navItems
        .filter((item) => item.current)
        .map((item, index) => (
          <div key={index} className="py-4">
            <item.component
              workOrder={workOrder}
              users={users}
              handleAssignment={handleAssignment}
              images={images}
              setSelectedImage={setSelectedImage}
              setSelectedNotes={setSelectedNotes}
              setShowLightbox={setShowLightbox}
              dataToRender={dataToRender}
              permissions={permissions}
              handleDeleteClick={handleDeleteClick}
              handleReopenClick={handleReopenClick}
              handleWorkOrderStatusChange={handleWorkOrderStatusChange}
              handleClickClosed={handleClickClosed}
            />
          </div>
        ))}

      {showLightbox && (
        <Lightbox
          images={images}
          selectedImage={selectedImage}
          show={showLightbox}
          setShow={setShowLightbox}
        />
      )}
      {showDelete && (
        <GenericModal
          text={"Delete this work order."}
          item={workOrder}
          handleFunction={handleDelete}
          showModal={showDelete}
          setShowModal={setShowDelete}
        />
      )}
      {showReopen && (
        <GenericModal
          text={"Reopen this work order."}
          item={workOrder}
          handleFunction={handleReopen}
          showModal={showReopen}
          setShowModal={setShowReopen}
          color="secondary-100"
          secondaryColor="primary"
          buttonText="Reopen"
          ModalIcon={UnarchiveOutlined}
        />
      )}
      {showUnable && (
        <IncompleteNotes
          note={note}
          setNote={setNote}
          selectedTask={unableToCompleteWorkOrder}
          showNotes={showUnable}
          setShowNotes={setShowUnable}
          handleMarkUnable={handleMarkUnable}
        />
      )}
      {showCloseout && (
        <GenericModal
          text={"Close out this work order."}
          item={workOrder}
          handleFunction={handleCloseOut}
          showModal={showCloseout}
          setShowModal={setShowCloseout}
          color="secondary-100"
          secondaryColor={"secondary-1000"}
          buttonText="Close Out"
        />
      )}
    </div>
  );
};

export default WorkOrderDetails;
