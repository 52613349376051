import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import VendorsTable from "../../updatedcomponents/tables/VendorsList";
import NewVendorForm from "../../updatedcomponents/forms/NewVendorForm";
import { FacilityContext } from "../../context/FacilityContext";
import { AuthContext } from "../../context/AuthContext";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import { FilterAltOutlined } from "@mui/icons-material";
import useFetchData from "../../hooks/useFetchData";

const Vendors = () => {
  const { selectedFacility } = useContext(FacilityContext);
  const { currentUser } = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "10";
  const filtersString = searchParams.get("filters") || "{}";
  const [filters, setFilters] = useState(JSON.parse(filtersString));

  useEffect(() => {
    setFilters(JSON.parse(filtersString));
    updateActiveFilterCount();
  }, [filtersString]);

  const updateSearchParams = (newParams) => {
    setSearchParams(
      (prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        Object.entries(newParams).forEach(([key, value]) => {
          if (key === "filters") {
            updatedParams.set(key, JSON.stringify(value));
          } else {
            updatedParams.set(key, value.toString());
          }
        });
        return updatedParams;
      },
      { replace: true }
    );
  };

  const updateActiveFilterCount = () => {
    const currentFilters = JSON.parse(searchParams.get("filters") || "{}");
    const count = Object.values(currentFilters).filter(value => value !== "").length;
    setActiveFilterCount(count);
  };

  const permissions = currentUser.access;

  const filterOptions = [
    {
      label: "Company Name",
      type: "text",
      name: "name",
    },
    {
      label: "Company Type",
      type: "selector",
      name: "type",
      options: [
        { label: "All", value: "" },
        { label: "Electrician", value: "electrician" },
        { label: "HVAC", value: "HVAC" },
        { label: "Plumber", value: "plumber" },
        { label: "Sprinkler", value: "sprinkler" },
        { label: "Other", value: "other" },
      ],
    },
  ];

  const handleResetFilters = () => {
    const emptyFilters = filterOptions.reduce((acc, option) => {
      acc[option.name] = "";
      return acc;
    }, {});
    updateSearchParams({ filters: emptyFilters, page: "1" });
    setFilters(emptyFilters);
    setActiveFilterCount(0);
    setShowFilter(false);
  };

  const { data: vendorData, error: vendorError } = useFetchData(
    selectedFacility ? `/api/vendor/list/${selectedFacility}` : null,
    { page, limit: pageSize, filter: JSON.stringify(filters) },
    [selectedFacility, refresh, page, pageSize, filters],
    { vendors: [], totalPages: 1 }
  );

  const vendors = vendorData?.vendors || [];
  const totalPages = vendorData?.totalPages || 0;

  if (vendorError) {
    console.error("Error fetching vendor:", vendorError);
  }

  const handleVendor = (vendor) => {
    setSelectedVendor(vendor);
    setShowPopup(true);
  };

  const handlePageSizeChange = ({ pageSize, page }) => {
    updateSearchParams({ pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage) => {
    updateSearchParams({ page: newPage.toString() });
  };
  return (
    <div>
      <PageHeader
        title="Vendors"
        subtitle="All vendors servicing your facility"
        buttons={
          (permissions === "regional" || permissions === "organization-admin") && (
            <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly mx-6 sm:gap-4 gap-2">
              <button
                type="button"
                className="flex items-center justify-center gap-2 rounded-sm bg-secondary-100 px-4 py-1 text-center text-sm font-semibold text-primary border-transparent border shadow-sm hover:bg-secondary-200"
                onClick={() => handleVendor(null)}
              >
                Add Vendor
              </button>
            </div>
          )
        }
      />
      <button
        className="ml-2 sm:ml-8 mt-4 flex gap-1 bg-secondary-1100 text-secondary-100 items-center px-2 py-1.5 rounded-sm"
        onClick={() => setShowFilter(true)}
      >
        <FilterAltOutlined style={{ fontSize: "1rem" }} />
        <span className="text-sm font-semibold">Advanced Filters</span>
        {activeFilterCount > 0 && (
          <span className="ml-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-black rounded-full">
            {activeFilterCount}
          </span>
        )}
      </button>
      <FilterModal
        show={showFilter}
        setShow={setShowFilter}
        filters={filters}
        // setFilters={(newFilters) => {
        //   updateSearchParams({ filters: newFilters, page: "1" });
        //   setFilters(newFilters);
        //   updateActiveFilterCount();
        // }}
        filterOptions={filterOptions}
        onApply={(newFilters) => {
          updateSearchParams({ filters: newFilters, page: "1" });
          setFilters(newFilters);
          updateActiveFilterCount();
        }}
        onReset={handleResetFilters}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <VendorsTable
          handleVendor={handleVendor}
          permissions={permissions}
          vendors={vendors}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <Pagination
          itemsToShow={pageSize}
          setItemsToShow={handlePageSizeChange}
          currentPage={page}
          setCurrentPage={handlePageChange}
          totalPages={totalPages}
        />
      </div>

      {showPopup && (
        <div>
          <NewVendorForm
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            refresh={refresh}
            setRefresh={setRefresh}
            facility={selectedFacility}
            selectedVendor={selectedVendor}
          />
        </div>
      )}
    </div>
  );
};

export default Vendors;