import { Fragment, useEffect, useRef } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import moment from "moment";
import { set } from "react-hook-form";

function generateMonthWithPadding(inputDate) {
  const monthStart = moment(inputDate).startOf("month");
  const monthEnd = moment(inputDate).endOf("month");

  const days = [];

  // Add padding days at the beginning of the month until the first day of the week
  for (
    let date = monthStart.clone().startOf("week");
    date.isBefore(monthStart, "day");
    date.add(1, "day")
  ) {
    days.push({
      date: date.format("YYYY-MM-DD"),
      isCurrentMonth: false,
      isToday: false,
      isSelected: false,
    });
  }

  // Add actual days of the month
  for (
    let date = monthStart.clone();
    date.isSameOrBefore(monthEnd);
    date.add(1, "day")
  ) {
    const isToday = date.isSame(moment(), "day");
    const isSelected = date.isSame(moment(inputDate), "day");

    days.push({
      date: date.format("YYYY-MM-DD"),
      isCurrentMonth: true,
      isToday: isToday,
      isSelected: isSelected,
    });
  }

  // Add additional padding days at the end of the month until the total number of days is at least 42
  for (
    let date = monthEnd.clone().add(1, "day");
    days.length < 42;
    date.add(1, "day")
  ) {
    days.push({
      date: date.format("YYYY-MM-DD"),
      isCurrentMonth: false,
      isToday: false,
    });
  }

  return {
    days,
  };
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DayView({ dateParams, setDateParams }) {
  const currentDate = dateParams.currentDate;

  const days = generateMonthWithPadding(currentDate).days;

  return (
    <div className="flex h-full flex-col">
      <div className="isolate flex flex-auto overflow-hidden bg-white">
        <div className="flex flex-auto flex-col overflow-auto">
          <div className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden">
            <button
              type="button"
              className="flex flex-col items-center pb-1.5 pt-3"
            >
              <span>W</span>
              {/* Default: "text-gray-900", Selected: "bg-gray-900 text-white", Today (Not Selected): "text-indigo-600", Today (Selected): "bg-indigo-600 text-white" */}
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                19
              </span>
            </button>
            <button
              type="button"
              className="flex flex-col items-center pb-1.5 pt-3"
            >
              <span>T</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-indigo-600">
                20
              </span>
            </button>
            <button
              type="button"
              className="flex flex-col items-center pb-1.5 pt-3"
            >
              <span>F</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                21
              </span>
            </button>
            <button
              type="button"
              className="flex flex-col items-center pb-1.5 pt-3"
            >
              <span>S</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full bg-gray-900 text-base font-semibold text-white">
                22
              </span>
            </button>
            <button
              type="button"
              className="flex flex-col items-center pb-1.5 pt-3"
            >
              <span>S</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                23
              </span>
            </button>
            <button
              type="button"
              className="flex flex-col items-center pb-1.5 pt-3"
            >
              <span>M</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                24
              </span>
            </button>
            <button
              type="button"
              className="flex flex-col items-center pb-1.5 pt-3"
            >
              <span>T</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                25
              </span>
            </button>
          </div>
          <div className="flex w-full flex-auto">
            <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Events */}
              <ol className="col-start-1 col-end-2 row-start-1 grid grid-cols-1">
                <li className="relative mt-px flex">
                  <a
                    href="#"
                    className="group absolute inset-1 flex flex-col rounded-lg bg-gray-100 p-2 text-xs leading-5 hover:bg-blue-100"
                  >
                    <p className="order-1 font-semibold text-gray-900">
                      Breakfast
                    </p>
                  </a>
                </li>
                <li className="relative mt-px flex">
                  <a
                    href="#"
                    className="group absolute inset-1 flex flex-col rounded-lg bg-gray-100 p-2 text-xs leading-5 hover:bg-blue-100"
                  >
                    <p className="order-1 font-semibold text-gray-900">Lunch</p>
                  </a>
                </li>
                <li className="relative mt-px flex">
                  <a
                    href="#"
                    className="group absolute inset-1 flex flex-col rounded-lg bg-gray-100 p-2 text-xs leading-5 hover:bg-blue-100"
                  >
                    <p className="order-1 font-semibold text-gray-900">
                      Dinner
                    </p>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 px-8 py-10 md:block">
          <div className="flex items-center text-center text-gray-900">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={() => {
                setDateParams((prev) => ({
                  ...prev,
                  currentDate: moment(currentDate)
                    .subtract(1, "month")
                    .format("YYYY-MM-DD"),
                }));
              }}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">
              {moment(currentDate).format("MMMM")}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={() => {
                setDateParams((prev) => ({
                  ...prev,
                  currentDate: moment(currentDate)
                    .add(1, "month")
                    .format("YYYY-MM-DD"),
                }));
              }}
            >
              <span className="sr-only">Next month</span>
              <ChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {days.map((day, dayIdx) => (
              <button
                key={day.date}
                type="button"
                className={classNames(
                  "py-1.5 hover:bg-gray-100 focus:z-10",
                  day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                  (day.isSelected || day.isToday) && "font-semibold",
                  day.isSelected && "text-white",
                  !day.isSelected &&
                    day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-900",
                  !day.isSelected &&
                    !day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-400",
                  day.isToday && !day.isSelected && "text-accent-200",
                  dayIdx === 0 && "rounded-tl-lg",
                  dayIdx === 6 && "rounded-tr-lg",
                  dayIdx === days.length - 7 && "rounded-bl-lg",
                  dayIdx === days.length - 1 && "rounded-br-lg"
                )}
                onClick={() => {
                  setDateParams((prev) => ({
                    ...prev,
                    currentDate: day.date,
                  }));
                }}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                    day.isSelected && day.isToday && "bg-accent-200",
                    day.isSelected && !day.isToday && "bg-gray-900"
                  )}
                >
                  {day.date.split("-").pop().replace(/^0/, "")}
                </time>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
