import React from "react";

const PageHeader = ({ title, subtitle, buttons }) => {
  return (
    <div className="sticky top-16 bg-primary z-30 flex flex-col justify-between sm:flex-row pt-2 sm:pl-6 border-b border-secondary-1000 items-center">
      <div className=" flex flex-col sm:flex-row justify-between sm:items-center pl-4 w-full sm:w-auto">
        <span className="text-xl text-secondary-100 py-3 ">
          {title}
        </span>
        <span className="flex text-sm text-secondary-500 sm:py-0 sm:ml-4 sm:border-secondary-1000 sm:border-l-2 sm:pl-3">
          {subtitle}
        </span>
      </div>
      {buttons && (
        <div className="py-2 sm:py-0 sm:mr-6 sm:justify-center flex w-full sm:w-fit mt-2 sm:mt-0">
          {buttons}
        </div>
      )}
    </div>
  );
};

export default PageHeader;
