import React, {
  useState,
  useEffect,
  useContext,
  Fragment
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import {
  ArrowBack,
  ArrowForward,
  CheckCircleOutline,
  Close,
  Edit,
} from "@mui/icons-material";
import moment from "moment-timezone";
import { useFieldArray, useForm, useWatch, Controller } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FacilityContext } from "../../context/FacilityContext";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import useFetchData from "../../hooks/useFetchData";

const CreateRecurring = ({
  permissions,
  showPopup,
  setShowPopup,
  refresh,
  setRefresh,
  existingTask,
  setExistingTask,
  setResponse,
}) => {
  //   initialize current step to 1
  const [currentStep, setCurrentStep] = useState(1);

  const { selectedFacility } = useContext(FacilityContext);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  //   YUP validation schema
  const validationSchema = Yup.object().shape({
    taskType: Yup.string()
      .required("Task type is required")
      .oneOf(
        ["life-safety", "prev-maint", "administrative"],
        "You must select a task type."
      ),
    category: Yup.string().oneOf(
      [
        "fire safety",
        "electrical safety",
        "operational safety",
        "gas safety",
        "not specified",
      ],
      "You must select a valid category."
    ),
    title: Yup.string().required("Title is required"),
    startDate: Yup.date()
      .typeError("Valid date required")
      .required("Start date is required"),
    recurrence: Yup.object({
      frequency: Yup.string()
        .required("Please select a frequency")
        .oneOf(
          [
            "daily",
            "weekly",
            "bi-weekly",
            "monthly",
            "quarterly",
            "semi-annually",
            "annually",
            "biennially",
            "3-year",
            "4-year",
            "5-year",
          ],
          "You must select a frequency"
        ),
    }),

    source: Yup.string()
      .required("Please select a source")
      .oneOf(["internal", "external"], "You must select a source"),
    details: Yup.string()
      .required("Task details are required")
      .min(10, "Task details must be at least 10 characters long"),
    inputs: Yup.array().min(1, "At least 1 input is required."),
    cycleTime: Yup.number("A positive number is required.")
      .typeError("A number is required.")
      .min(1, "Must be greater than 0")
      .required("Cycle time is required"),
  });

  // Instantiate react-hook-form
  const {
    setValue,
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  //   Stores the template data in the form
  const [templateObject, setTemplateObject] = useState({});

  //   Write in each item from the template if the template exists
  useEffect(() => {
    if (templateObject) {
      setValue("title", templateObject?.title || "");
      setValue("taskType", templateObject?.taskType || "");
      setValue("category", templateObject?.category || "");
      setValue("source", templateObject?.source || "");
      setValue("notify", templateObject?.notify || "false");
      setValue(
        "recurrence.frequency",
        templateObject?.recurrence?.frequency || ""
      );
      setValue("inputs", templateObject?.inputs || []);
      setValue("details", templateObject?.details || "");
      setValue("hasCycle", templateObject?.hasCycle || "false");
      setValue("cycleTime", templateObject?.cycleTime || 1);
      setValue("cycleUnit", templateObject?.cycleUnit || "week");
      setValue("sourceTemplateId", templateObject?._id || "");
      setValue("skipWeekends", templateObject?.skipWeekends || false);
    }
  }, [templateObject, setValue]);

  useEffect(() => {
    if (existingTask) {
      setValue("title", existingTask?.title || "");
      setValue("taskType", existingTask?.taskType || "");
      setValue("category", existingTask?.category || "");
      setValue("source", existingTask?.source || "");
      setValue("vendor", existingTask?.vendor || "");
      setValue("notify", existingTask?.notify || "false");
      setValue("notify", existingTask?.notify || "false");
      setValue(
        "startDate",
        formatDate(existingTask?.startDate, "YYYY-MM-DD", "utc") || ""
      );
      setValue(
        "endDate",
        formatDate(existingTask?.endDate, "YYYY-MM-DD", "utc") || ""
      );
      setValue(
        "recurrence.frequency",
        existingTask?.recurrence?.frequency || ""
      );
      setValue("inputs", existingTask?.inputs || []);
      setValue("details", existingTask?.details || "");
      setValue("hasCycle", existingTask?.hasCycle || "false");
      setValue("cycleTime", existingTask?.cycleTime || 1);
      setValue("cycleUnit", existingTask?.cycleUnit || "week");
      setValue("map", existingTask?.map || "");
      setValue("skipWeekends", existingTask?.skipWeekends || false);
    }
    if (existingTask && Object.keys(existingTask).length > 0) {
      setCurrentStep(2);
    }
  }, [existingTask]);

  //   Add listeners for each of the values below to dynamically render different form components
  const formValues = watch();
  const title = useWatch({ control, name: "title" });
  const taskType = useWatch({ control, name: "taskType" });
  const category = useWatch({ control, name: "category" });
  const startDate = useWatch({ control, name: "startDate" });
  const endDate = useWatch({ control, name: "endDate" });
  const frequency = useWatch({ control, name: "recurrence.frequency" });
  const source = useWatch({ control, name: "source" });
  const vendor = useWatch({ control, name: "vendor" });
  const notify = useWatch({ control, name: "notify" });
  const map = useWatch({ control, name: "map" });
  const details = useWatch({ control, name: "details" });
  const hasCycle = useWatch({ control, name: "hasCycle" });
  const cycleTime = useWatch({ control, name: "cycleTime" });
  const cycleUnit = useWatch({ control, name: "cycleUnit" });
  const skipWeekends = useWatch({ control, name: "skipWeekends" });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "inputs",
  });

  //Function for retrieving map options
  // Fetching maps associated with the selected facility
  const { data: mapsData, error: mapsError } = useFetchData(
    selectedFacility ? `/api/log/maps/list/${selectedFacility}` : null,
    {},
    [selectedFacility],
    [] // default value for maps data
  );
  const maps = mapsData || [];

  if (mapsError) {
    console.error("Error fetching maps:", mapsError);
  }

  // Fetching documents associated with the selected facility
  const { data: docsData, error: docsError } = useFetchData(
    selectedFacility ? `/api/log/docs/list/${selectedFacility}` : null,
    {},
    [selectedFacility],
    [] // default value for docs data
  );

  const docs = docsData || [];

  if (docsError) {
    console.error("Error fetching docs:", docsError);
  }

  const attachmentOptions = docs.map((doc) => ({
    value: doc._id,
    label: doc.name,
  }));

  //Function for retrieving vendors
  const { data: vendorsData, error: vendorsError } = useFetchData(
    selectedFacility ? `/api/log/vendors/list/${selectedFacility}` : null,
    {},
    [selectedFacility],
    [] // default value for vendors data
  );

  const vendors = vendorsData || [];

  if (vendorsError) {
    console.error("Error fetching vendors:", vendorsError);
  }

  //   Retrieves templates
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`api/log/templates/list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setTemplates(response.data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    if (selectedFacility) {
      fetchData();
    }
  }, [selectedFacility]);

  //   Assign values to custom input data
  const [inputName, setInputName] = useState("");
  const [inputDataType, setInputDataType] = useState("");
  const [inputValidator, setInputValidator] = useState({
    lowerLimit: undefined,
    upperLimit: undefined,
    required: true,
    requireNote: false,
  });
  // Add custom inputs to array
  const handleAddInput = () => {
    // Append a new input item with the captured values
    if (
      inputValidator.lowerLimit !== undefined &&
      inputValidator.upperLimit !== undefined &&
      inputValidator.lowerLimit >= inputValidator.upperLimit
    ) {
      setError("validators", {
        type: "manual",
        message: "Upper limit must be greater than lower limit",
      });
      return;
    }
    const encodedInputName = encodeURIComponent(inputName);
    append({
      name: encodedInputName,
      dataType: inputDataType,
      validator: inputValidator,
    });

    // Clear the input and select fields if needed
    setInputName("");
    setInputDataType("");
    setInputValidator({
      lowerLimit: undefined,
      upperLimit: undefined,
      required: true,
      requireNote: false,
    });
  };

  //   When the form's data is filled, validate data and move to next step
  const onSubmit = () => {
    setCurrentStep(3);
  };

  //   Last and final step to upload data to the backend
  const onSubmitStage2 = async () => {
    // Actual submit function
    const data = getValues();
    data.facility = selectedFacility;

    const taskId = existingTask ? existingTask._id : "createId";

    try {
      await axios
        .post(`/api/log/task/${taskId}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setRefresh(!refresh);
        });
      setShowPopup(false);
      if (existingTask) {
        setExistingTask({});
      }
      setSelectedTemplate({});
      setCurrentStep(1);

      reset({ inputs: [] });
      setResponse({
        show: true,
        title: "Success",
        body: `Task ${
          existingTask === null ? "created" : "updated"
        } successfully`,
        icon: (
          <CheckCircleOutline
            className="text-accent-500"
            style={{ fontSize: "1rem" }}
          />
        ),
      });
    } catch (error) {
      console.error(error);
    }
  };

  //   Steps required for our form submission process
  const steps = [
    { id: "Step 1", name: "Select template", stepCount: 1 },
    { id: "Step 2", name: "Customize template", stepCount: 2 },
    { id: "Step 3", name: "Preview", stepCount: 3 },
  ];

  //   change steps
  const nextStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };

  const prevStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };

  // If the validation schema fails, disable step 3
  const [isStep3Disabled, setIsStep3Disabled] = useState(true);

  useEffect(() => {
    const validate = async () => {
      const isValid = await validationSchema.isValid(formValues);
      setIsStep3Disabled(!isValid);
    };
    validate();
  }, [formValues]);

  // ui component for displaying status
  const stepStatus = (
    <nav aria-label="Progress" className="px-5 py-2 shadow-md rounded-sm">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            <button
              onClick={() => setCurrentStep(step.stepCount)}
              disabled={isStep3Disabled && step.stepCount === 3}
              className={`disabled:text-secondary-900 cursor-pointer group flex w-full flex-col border-l-4 ${
                currentStep === step.stepCount
                  ? "border-accent-500"
                  : "border-secondary-1000"
              } py-2 pl-4 hover:border-accent-400 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 disabled:border-secondary-1000`}
            >
              <span className="text-sm font-medium ">{step.id}</span>
              <span className="text-sm font-medium">{step.name}</span>
            </button>
          </li>
        ))}
      </ol>
    </nav>
  );

  //   When template is changed, the data is written to the template object
  useEffect(() => {
    const populatedTemplate = templates.find(
      (template) => template._id === selectedTemplate
    );
    setTemplateObject(populatedTemplate);
  }, [selectedTemplate]);

  //   Step 1: display template data, given id value
  const getTemplateInfo = (templateId) => {
    const selectedTemplate = templates.find(
      (template) => template._id === templateId
    );

    if (!selectedTemplate) {
      return (
        <p className="text-secondary font-sm font-semibold mt-6 italic text-center">
          Click next step below, to begin customizing your task!
        </p>
      );
    }

    return (
      <div>
        <div className="mt-6 ">
          <dl className="divide-y divide-secondary-1000 bg-secondary-1100 p-4 rounded-sm">
            <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <h2 className="text-secondary-100 text-lg font-semibold pb-4">
                Template Overview
              </h2>
            </div>
            <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-secondary-100">
                Task Title
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">
                  {capitalizeString(selectedTemplate.title)}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                Task Type
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">
                  {capitalizeString(selectedTemplate.taskType)}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                Task Category
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">
                  {capitalizeString(selectedTemplate.category)}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                Frequency
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">
                  {capitalizeString(selectedTemplate.recurrence.frequency)}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                Inputs
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">
                  <ul>
                    {selectedTemplate.inputs.map((input, index) => (
                      <li
                        key={`${input}-${index}`}
                        className={` inline-flex items-center gap-x-0.5 rounded-sm  px-2 py-1 text-xs font-medium  ring-1 ring-inset ring-gray-500/10`}
                      >
                        {capitalizeString(decodeURIComponent(input.name))}
                      </li>
                    ))}
                  </ul>
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-secondary-100">
                Description
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">
                  {capitalizeString(selectedTemplate.details)}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-secondary-100">
                Region
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{selectedTemplate.region}</span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };
  // Step 2: Render form component with loaded template (if applicable)
  const formContent = () => {
    return (
      <form
        noValidate
        className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-100 sm:py-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Task Type */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="project-name"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Task Type
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              className={`block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                errors.taskType
                  ? "ring-2 ring-inset ring-red-500"
                  : "ring-1 ring-gray-300"
              }`}
              id="taskType"
              {...register("taskType")}
            >
              <option value={""}>Please select a value</option>
              <option value={"life-safety"}>Life Safety</option>
              <option value={"prev-maint"}>Preventative Maintenance</option>
              {permissions === "organization-admin" && (
                <option value={"administrative"}>Administrative</option>
              )}
            </select>
            <p className="text-xs py-1 text-red-500">
              {errors.taskType?.message}
            </p>
          </div>
        </div>
        {/* Category */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="category"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Regulation Category
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              className={`block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                errors.category
                  ? "ring-2 ring-inset ring-red-500"
                  : "ring-1 ring-gray-300"
              }`}
              id="category"
              {...register("category")}
            >
              <option value={"not specified"}>Unspecified</option>
              <option value={"fire safety"}>Fire Safety</option>
              <option value={"electrical safety"}>Electrical Safety</option>
              <option value={"operational safety"}>Operational Safety</option>
              <option value={"gas safety"}>Gas/Air Safety</option>
            </select>
            <p className="text-xs py-1 text-red-500">
              {errors.category?.message}
            </p>
          </div>
        </div>
        {/* Title */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Task Title
            </label>
          </div>
          <div className="sm:col-span-2">
            <input
              className={`block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                errors.title
                  ? "ring-2 ring-inset ring-red-500"
                  : "ring-1 ring-gray-300"
              }`}
              id="title"
              type="text"
              {...register("title")}
            />
            <p className="text-xs py-1 text-red-500">{errors.title?.message}</p>
          </div>
        </div>
        {/* Start Date */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="startDate"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Start Date
            </label>
          </div>
          <div className="sm:col-span-2">
            <input
              className={`block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                errors.startDate
                  ? "ring-2 ring-inset ring-red-500"
                  : "ring-1 ring-gray-300"
              }`}
              id="startDate"
              name="startDate"
              type="date"
              max={formatDate(endDate)}
              {...register("startDate", {
                value: formatDate(startDate, "YYYY-MM-DD", "utc"),
              })}
            />
            <p className="text-xs py-1 text-red-500">
              {errors.startDate?.message}
            </p>
          </div>
        </div>
        {/* End Date */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="startDate"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              End Date
            </label>
          </div>
          <div className="sm:col-span-2">
            <input
              className={`block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                errors.endDate
                  ? "ring-2 ring-inset ring-red-500"
                  : "ring-1 ring-gray-300"
              }`}
              id="endDate"
              name="endDate"
              type="date"
              min={startDate ? formatDate(startDate) : ""}
              {...register("endDate", { value: formatDate(endDate) })}
            />
            <p className="text-xs py-1 text-red-500">
              {errors.endDate?.message}
            </p>
          </div>
        </div>
        {/* Frequency */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="frequency"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Frequency
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              className={`block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                errors.recurrence?.frequency
                  ? "ring-2 ring-inset ring-red-500"
                  : "ring-1 ring-gray-300"
              }`}
              id="recurrence.frequency"
              {...register("recurrence.frequency")}
            >
              <option value={undefined}>Please select a value</option>
              <option value={"daily"}>Daily</option>
              <option value={"weekly"}>Weekly</option>
              <option value={"bi-weekly"}>Biweekly</option>
              <option value={"monthly"}>Monthly</option>
              <option value={"quarterly"}>Quarterly</option>
              <option value={"semi-annually"}>Semi-Annually</option>
              <option value={"annually"}>Annually</option>
              <option value={"biennially"}>2-Year</option>
              <option value={"3-year"}>3-Year</option>
              <option value={"4-year"}>4-Year</option>
              <option value={"5-year"}>5-Year</option>
            </select>
            <p className="text-xs py-1 text-red-500">
              {errors.recurrence?.frequency?.message}
            </p>
          </div>
        </div>
        <div></div>{" "}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="skipWeekends"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Skip Weekends
            </label>
          </div>
          <div className="sm:col-span-2 flex justify-end text-right">
            <label
              htmlFor={"skipWeekends"}
              className="flex items-center cursor-pointer"
            >
              <div className="relative">
                <input
                  {...register("skipWeekends")}
                  type="checkbox"
                  id="skipWeekends"
                  className="sr-only"
                />
                <div
                  className={`block w-10 h-6 rounded-full ${
                    skipWeekends ? "bg-secondary-100 " : "bg-secondary-600"
                  }`}
                ></div>
                <div
                  className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${
                    skipWeekends ? "translate-x-full" : ""
                  }`}
                ></div>
              </div>
            </label>
          </div>
        </div>
        {/* Source */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="source"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Source
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              className={`block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                errors.source
                  ? "ring-2 ring-inset ring-red-500"
                  : "ring-1 ring-gray-300"
              }`}
              id="source"
              {...register("source")}
            >
              <option value={""}>Please select a value</option>
              <option value={"internal"}>Internal</option>
              <option value={"external"}>External</option>
            </select>
            <p className="text-xs py-1 text-red-500">
              {errors.source?.message}
            </p>
          </div>
        </div>
        {/* Vendor  (id external task*/}
        {source === "external" && (
          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <label
                htmlFor="vendor"
                className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
              >
                Vendor
              </label>
            </div>
            <div className="sm:col-span-2">
              <select
                className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ring-1 ring-gray-300"
                id="vendor"
                {...register("vendor")}
              >
                <option value={""}>Not-specified</option>
                {vendors.map((vendor) => (
                  <option key={vendor._id} value={vendor._id}>
                    {vendor.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {/* Notify Vendor (if a vendor is selected) */}
        {vendor && vendor !== "not-specified" && (
          <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <legend className="sr-only">notify</legend>
            <div
              className="text-sm font-medium leading-6 text-secondary-100"
              aria-hidden="true"
            >
              Notify Vendor
            </div>
            <div className="space-y-5 sm:col-span-2">
              <div className="space-y-5 sm:mt-0">
                <div className="relative flex items-start">
                  <div className="absolute flex h-6 items-center">
                    <input
                      id="notify"
                      name="notify"
                      aria-describedby="notify-description"
                      type="radio"
                      value={true}
                      checked={notify === "true"}
                      className="h-4 w-4 border-gray-300 text-accent-100 focus:ring-accent-100"
                      {...register("notify")}
                    />
                  </div>
                  <div className="pl-7 text-sm leading-6">
                    <label
                      htmlFor="notify"
                      className="font-medium text-secondary-100"
                    >
                      Notify Vendor
                    </label>
                    <p id="public-access-description" className="text-gray-500">
                      Vendors will recieve email updates regarding upcoming
                      tasks
                    </p>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="absolute flex h-6 items-center">
                    <input
                      id="notify"
                      name="notify"
                      aria-describedby="notify-true"
                      type="radio"
                      value={false}
                      checked={notify === "false"}
                      className="h-4 w-4 border-gray-300 text-accent-100 focus:ring-accent-100"
                      {...register("notify")}
                    />
                  </div>
                  <div className="pl-7 text-sm leading-6">
                    <label
                      htmlFor="notify"
                      className="font-medium text-secondary-100"
                    >
                      Do not notify
                    </label>
                    <p id="notify-false-description" className="text-gray-500">
                      Vendors will not receive automatic updates related to this
                      task
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        )}
        {/* task description */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="details"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Description
            </label>
          </div>
          <div className="sm:col-span-2">
            <textarea
              id="details"
              rows={3}
              className={`block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 ${
                errors.details
                  ? "ring-2 ring-inset ring-red-500"
                  : "ring-1 ring-gray-300"
              }`}
              placeholder="Enter task details here"
              {...register("details")}
            />
            <p className="text-xs py-1 text-red-500">
              {errors.details?.message}
            </p>
          </div>
        </div>
        {/* Inputs */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="inputs"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Log Input Fields
            </label>
          </div>
          <div className="sm:col-span-2">
            <div className="flex flex-wrap ">
              {fields.map((item, index) => (
                <div className="flex m-1" key={index}>
                  <span
                    className={`inline-flex items-center gap-x-0.5 rounded-sm  px-2 py-1 text-xs font-medium  ring-1 ring-inset ring-gray-500/10`}
                  >
                    {decodeURIComponent(item.name)} (
                    {item.dataType === "number"
                      ? item.validator.lowerLimit !== undefined &&
                        item.validator.upperLimit !== undefined
                        ? `${item.dataType} ${item.validator.lowerLimit} - ${item.validator.upperLimit}`
                        : item.validator.lowerLimit !== undefined
                        ? ` >${item.validator.lowerLimit} `
                        : ` <${item.validator.upperLimit} `
                      : item.dataType}
                    )
                    <button
                      type="button"
                      className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
                      onClick={() => remove(index)}
                    >
                      <span className="sr-only">Remove</span>
                      <svg
                        viewBox="0 0 14 14"
                        className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
                      >
                        <path d="M4 4l6 6m0-6l-6 6" />
                      </svg>
                      <span className="absolute -inset-1" />
                    </button>
                  </span>
                </div>
              ))}

              {errors.inputs && (
                <p className="text-xs py-1 text-red-500">
                  {errors.inputs?.message}
                </p>
              )}
            </div>
            <div className="flex-col ">
              <div className="flex">
                <input
                  className="block w-full rounded-l-md border-0 py-1 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm  focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                  type="text"
                  placeholder="Input label"
                  value={inputName}
                  onChange={(e) => {
                    setInputName(e.target.value);
                  }}
                />
                <select
                  className={`flex w-fit max-w-sm rounded-r-md border-0 py-1 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6`}
                  value={inputDataType}
                  onChange={(e) => {
                    setInputDataType(e.target.value);
                  }}
                >
                  <option value={""}>Please select an input type</option>

                  <option value="text">Text</option>
                  <option value="number">Number</option>
                  <option value="radio">Pass/Fail</option>
                  <option value="radio-na">Pass/Fail/NA</option>
                  <option value="file">File</option>
                </select>
              </div>
              <span className="flex justify-center text-sm text-gray-400 p-1">
                *Logs are automatically generated with a date field
              </span>
              <div className="my-1 space-y-2  sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 bg-gray-50 rounded-sm shadow">
                <div className="col-span-3">
                  <label
                    htmlFor="validators"
                    className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
                  >
                    Validators
                  </label>
                  <p className="text-xs text-gray-600">
                    Set the preferred validation methods and limits
                  </p>
                </div>
                <div className="col-span-3 flex">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="required"
                        aria-describedby="Required-description"
                        name="required"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-accent-200 focus:ring-accent-200"
                        checked={inputValidator.required}
                        onChange={(e) =>
                          setInputValidator((validators) => ({
                            ...validators,
                            required: e.target.checked,
                          }))
                        }
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="required"
                        className="font-medium text-gray-600"
                      >
                        Require Field
                      </label>
                      <p id="required-description" className="text-gray-500">
                        Require users to fill out this field upon submission.
                      </p>
                    </div>
                  </div>
                </div>

                {inputDataType === "number" && (
                  <div className="col-span-3 flex">
                    <div className="col-span-1 text-sm font-medium text-gray-600 p-2 pl-0">
                      Value Range
                    </div>
                    <div className="sm:col-span-2 flex">
                      <input
                        className="block w-full rounded-l-md border-0 py-1 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm  focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                        type="number"
                        placeholder="Lower Limit"
                        value={inputValidator.lowerLimit}
                        onKeyDown={(e) => {
                          if (e.key === "+" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setInputValidator((validators) => ({
                            ...validators,
                            lowerLimit: e.target.value,
                          }))
                        }
                      />

                      <input
                        className="block w-full rounded-r-md border-0 py-1 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm  focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                        type="number"
                        placeholder="Upper Limit"
                        value={inputValidator.upperLimit}
                        min={inputValidator.lowerLimit}
                        onKeyDown={(e) => {
                          if (e.key === "+" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setInputValidator((validators) => ({
                            ...validators,
                            upperLimit: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                )}
                {errors.validators && (
                  <p className="text-xs col-span-3 py-1 text-red-500">
                    {errors.validators?.message}
                  </p>
                )}
              </div>

              {/* {inputDataType === "radio" && (
                <div className="col-span-3 flex">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="notes"
                        aria-describedby="Required-description"
                        name="notes"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-accent-200 focus:ring-accent-200"
                        checked={inputValidator.requireNote}
                        onChange={(e) =>
                          setInputValidator((validators) => ({
                            ...validators,
                            requireNote: e.target.checked,
                          }))
                        }
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="notes"
                        className="font-medium text-gray-600"
                      >
                        Require Notes
                      </label>
                      <p id="notes-description" className="text-gray-500">
                        Require users to write a note if failed.
                      </p>
                    </div>
                  </div>
                </div>
              )} */}

              <button
                className={`mt-2 rounded bg-white px-2 py-1 text-sm font-semibold text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${
                  inputName === "" || inputDataType === ""
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                type="button"
                disabled={inputName === "" || inputDataType === ""}
                onClick={handleAddInput}
              >
                + Add input
              </button>
            </div>
          </div>
        </div>
        {/* Files */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="attachments"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Attachments
            </label>
          </div>
          <div className="sm:col-span-2">
            <Controller
              name="attachments"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti
                  options={attachmentOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select files (optional)"
                  onChange={(selected) => {
                    field.onChange(selected.map((item) => item.value));
                  }}
                  value={attachmentOptions.filter((option) =>
                    field.value.includes(option.value)
                  )}
                  styles={{
                    input: (base) => ({
                              ...base,
                              "input:focus": {
                                   boxShadow: "none",
                                   },
                            })
                 }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "black", // To match map dropdown's primary color
                      primary25: "lightgray", // Light hover effect on options
                    },
                  })}
                />
              )}
            />
            <p className="mt-1 text-sm text-gray-500">
              Click to select/deselect. You can select multiple files.
            </p>
          </div>
        </div>
        {/* Map */}
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="map"
              className="block text-sm font-medium leading-6 text-secondary-100 sm:mt-1.5"
            >
              Map
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
              id="map"
              {...register("map")}
            >
              <option value={""}>Select a map (optional)</option>
              {maps.map((map) => (
                <option key={map._id} value={map._id}>
                  {map.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* Cycle Inputs */}
        {map && (
          <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <legend className="sr-only">Cycle inputs</legend>
            <div
              className="text-sm font-medium leading-6 text-secondary-100"
              aria-hidden="true"
            >
              Cycle Inputs
            </div>
            <div className="space-y-5 sm:col-span-2">
              <div className="space-y-5 sm:mt-0">
                <div className="relative flex items-start">
                  <div className="absolute flex h-6 items-center">
                    <input
                      id="hasCycleFalse"
                      name="hasCycle"
                      type="radio"
                      value={false}
                      checked={hasCycle !== "true"}
                      className="h-4 w-4 border-gray-300 text-accent-100 focus:ring-accent-100"
                      {...register("hasCycle")}
                    />
                  </div>
                  <div className="pl-7 text-sm leading-6">
                    <label
                      htmlFor="restricted-access"
                      className="font-medium text-secondary-100"
                    >
                      Do not cycle
                    </label>
                    <p
                      id="restricted-access-description"
                      className="text-gray-500"
                    >
                      Map items will not be cycled through
                    </p>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="absolute flex h-6 items-center">
                    <input
                      id="hasCycle"
                      name="hasCycle"
                      type="radio"
                      value={true}
                      checked={hasCycle === "true"}
                      className="h-4 w-4 border-gray-300 text-accent-100 focus:ring-accent-100"
                      {...register("hasCycle")}
                    />
                  </div>
                  <div className="pl-7 text-sm leading-6">
                    <label
                      htmlFor="hasCycle"
                      className="font-medium text-secondary-100"
                    >
                      Cycle
                    </label>
                    <p id="hasCycle-description" className="text-gray-500">
                      Map items will be cycled through to complete within a
                      specified time frame
                    </p>
                    {hasCycle === "true" && (
                      <div>
                        <span>Every: </span>
                        <div>
                          <input
                            className="block w-fit rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                            type="number"
                            id="cycleTime"
                            min={1}
                            {...register("cycleTime")}
                          />
                        </div>
                        <select
                          className="block w-full mt-2 rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                          id="cycleUnit"
                          {...register("cycleUnit")}
                        >
                          <option value={"week"}>
                            Week{cycleTime > 1 && "s"}
                          </option>
                          <option value={"month"}>
                            Month{cycleTime > 1 && "s"}
                          </option>
                          <option value={"quarter"}>
                            Quarter{cycleTime > 1 && "s"}
                          </option>
                          <option value={"annual"}>
                            Year{cycleTime > 1 && "s"}
                          </option>
                        </select>
                        {errors.cycleTime && (
                          <p className="text-xs py-1 text-red-500">
                            {errors.cycleTime?.message}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        )}
      </form>
    );
  };

  //   Step 3: Display data review for user before submission
  const displayPreview = () => {
    return (
      <div>
        <div className="mt-6 ">
          <dl className="divide-y divide-gray-100 bg-gray-50 p-4 rounded-xl shadow-md">
            <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <h2 className="text-secondary text-lg font-bold py-4">
                Review Recurring Task
              </h2>
            </div>
            <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-secondary-100">
                Task Title
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{capitalizeString(title)}</span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                Task Type
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{capitalizeString(taskType)}</span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                Task Category
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{capitalizeString(category)}</span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                Start Date
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{formatDate(startDate)}</span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                End Date
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{formatDate(endDate)}</span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                Frequency
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{capitalizeString(frequency)}</span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium  text-secondary-100">
                Inputs
              </dt>
              <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">
                  <ul>
                    {fields.map((input, index) => (
                      <li
                        key={`${input}-${index}`}
                        className={` inline-flex items-center gap-x-0.5 rounded-sm  px-2 py-1 text-xs font-medium  ring-1 ring-inset ring-gray-500/10`}
                      >
                        {capitalizeString(decodeURIComponent(input.name))}
                      </li>
                    ))}
                  </ul>
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-secondary-100">
                Description
              </dt>
              <dd className="mt-1 flex text-sm leading-6 text-secondary-400 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{capitalizeString(details)}</span>
              </dd>
            </div>
            {hasCycle === "true" && (
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-secondary-100">
                  Cycle
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-secondary-400 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">
                    Cycle every {cycleTime} {cycleUnit}
                    {cycleTime > 1 && "s"}
                  </span>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    );
  };

  //   Depending on the current step each of the above steps arw conditionally rendered
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="p-3">
            <div>{stepStatus}</div>

            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label
                  htmlFor="project-name"
                  className="block text-sm font-medium leading-6 text-gray-800 sm:mt-1.5"
                >
                  Select a template or build from new
                </label>
              </div>
              <div className="sm:col-span-2">
                <select
                  className="block w-full rounded-sm border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                  value={selectedTemplate}
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                >
                  <option value={""}>Build from new</option>

                  {templates.map((template) => (
                    <option key={template._id} value={template._id}>
                      {capitalizeString(template.title)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="sm:col-span-3">
                {getTemplateInfo(selectedTemplate)}
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="p-3">
            <div className="sticky">{stepStatus}</div>
            <div className="sm:col-span-3">{formContent()}</div>
          </div>
        );
      case 3:
        return (
          <div className="p-3">
            <div className="sticky">{stepStatus}</div>
            <div className="sm:col-span-3">{displayPreview()}</div>
          </div>
        );
      default:
        return null;
    }
  };

  //Render buttons for the creating of a new task
  const newTaskButtons = (
    <div className=" flex gap-2">
      {currentStep !== 1 && (
        <button
          type="buton"
          className="inline-flex rounded-sm bg-white px-3 py-2 text-sm font-semibold text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={prevStep}
        >
          <ArrowBack style={{ fontSize: "1.1rem" }} className="pt-1" /> Previous
          Step
        </button>
      )}
      {currentStep < 3 ? (
        <button
          type="buton"
          className="inline-flex justify-center rounded-sm bg-secondary-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-seondary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
          onClick={currentStep === 1 ? nextStep : handleSubmit(onSubmit)}
        >
          Next Step
          <ArrowForward style={{ fontSize: "1.1rem" }} className="pt-1" />
        </button>
      ) : (
        <button
          type="submit"
          className="inline-flex justify-center rounded-sm bg-accent-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
          onClick={onSubmitStage2}
        >
          {existingTask ? "Update" : "Create"}
        </button>
      )}
    </div>
  );
  // Root of component containing slide feature
  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShowPopup}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div
                    className="flex  h-full flex-col overflow-y-scroll bg-white shadow-xl thin-scrollbar"
                    onSubmit={null}
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-secondary-100 px-4 py-6 sm:px-6 sticky top-0 z-50">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-primary">
                              {existingTask
                                ? "Update Recurring Task"
                                : "New Recurring Task"}
                            </Dialog.Title>
                            <p className="text-sm text-secondary-1100">
                              Get started by filling in the information below to
                              create a new recurring task.
                            </p>
                          </div>

                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-primary hover:text-gray-100"
                              onClick={() => {
                                setShowPopup(false);
                                existingTask ? setExistingTask({}) : null;
                              }}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <Close className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {renderStepContent()}
                    </div>
                    {/* Action buttons */}

                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        {newTaskButtons}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateRecurring;
