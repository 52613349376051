import { Add } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import CreateTemplate from "../../components/createtemplate/CreateTemplate";
import axios from "axios";
import TemplateList from "../../updatedcomponents/tables/TemplateList";

const ScheduledTasks = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState({});

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleAddTemplate = () => {
    setSelectedTemplate(null);
    setShowPopup(true);
  };

  const handleEditTemplate = (template) => {
    setSelectedTemplate(template);
    setShowPopup(true);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/admin/templates/list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      } catch (error) {
        console.log("error: ", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <div className="fixed bottom-8 right-8 z-10">
        <button
          type="button"
          className="rounded-full bg-accent-500 p-2 text-primary shadow-sm hover:bg-accent-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
          onClick={handleAddTemplate}
        >
          <Add className="h-10 w-10" aria-hidden="true" />
        </button>
      </div>
      <TemplateList templates={data} handleEditTemplate={handleEditTemplate} />

      <CreateTemplate
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        existingLog={selectedTemplate}
        setExistingLog={setSelectedTemplate}
      />
    </div>
  );
};

export default ScheduledTasks;
