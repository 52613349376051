import React, { useState, useContext, useEffect, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import { FacilityContext } from "../../context/FacilityContext";
import {
  DeleteOutline,
  EditOutlined,
  MapOutlined,
  More,
} from "@mui/icons-material";
import CreateMapButton from "../modals/MapButton";
import GenericModal from "../modals/GenericModal";
import useFetchData from "../../hooks/useFetchData";

const MapList = ({ refreshMap, setRefreshMap }) => {
  const { selectedFacility } = useContext(FacilityContext);
  const [showMap, setShowMap] = useState(false);
  const [selectedMap, setSelectedMap] = useState({});

  // Fetching maps associated with the selected facility
  const { data: mapsData, error: mapsError } = useFetchData(
    selectedFacility ? `/api/building/get-maps/${selectedFacility}` : null,
    {},
    [selectedFacility, refreshMap],
    [] // default value for maps data
  );

  const maps = mapsData || [];

  if (mapsError) {
    console.error("Error fetching maps:", mapsError);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleShowMap = (map) => {
    setSelectedMap(map);
    setShowMap(true);
  };

  const [showDelete, setShowDelete] = useState(false);

  const handleDelete = (map) => {
    const { _id: id } = map;
    const token = localStorage.getItem("token");
    axios
      .delete(`/api/building/delete-map/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setRefreshMap(!refreshMap);
        setShowDelete(false);
      })
      .catch((error) => console.error("Error deleting map:", error));
  };

  return (
    <div className="w-full">
      <div className="mt-4 flex gap-3">
        <div className="flex flex-row gap-4">
          <MapOutlined
            style={{ fontSize: "3.5rem" }}
            className="text-secondary-100 bg-secondary-1100 rounded-full p-2"
          />
          <div className="flex flex-col">
            <h2 className="text-xl font-semibold text-secondary-100">Maps</h2>
            <p className="text-sm text-secondary-500 font-light">
              View and manage your buildings assets
            </p>
          </div>
        </div>
      </div>
      <ul
        role="list"
        className="divide-y divide-secondary-1000  pt-4 pb-2 px-2 border-b border-secondary-1000"
      >
        {maps.length > 0 ? (
          maps.map((map) => (
            <li
              key={map._id}
              className="flex items-center justify-between gap-x-6 py-5"
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-secondary-100">
                    {capitalizeFirstLetter(map.name)}
                  </p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <button
                  className="bg-secondary-1000 px-2.5 py-0.5 rounded-sm flex justify-center items-center gap-x-1 text-sm font-normal text-secondary-100 shadow-sm hover:bg-secondary-1100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
                  onClick={() => handleShowMap(map)}
                >
                  <EditOutlined style={{ fontSize: "1rem" }} />
                  Edit
                </button>
                <button
                  className="bg-secondary-1100 text-reds-500 px-0.5 py-0.5 rounded-sm flex justify-center items-center gap-x-1 text-sm font-semibold border border-secondary-1100  shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-reds-500"
                  onClick={() => {
                    setSelectedMap(map);
                    setShowDelete(true);
                  }}
                >
                  <DeleteOutline style={{ fontSize: "1rem" }} />
                </button>
              </div>
            </li>
          ))
        ) : (
          <div className="p-4">
            <h2 className="text-md text-secondary-500 italic">
              No maps present
            </h2>
          </div>
        )}
      </ul>
      {showMap && (
        <div>
          <CreateMapButton
            showMapCreate={showMap}
            setShowMapCreate={setShowMap}
            refreshMap={refreshMap}
            setRefreshMap={setRefreshMap}
            selectedMap={selectedMap}
          />
        </div>
      )}
      {showDelete && (
        <GenericModal
          text="Delete Map"
          showModal={showDelete}
          item={selectedMap}
          setShowModal={setShowDelete}
          handleFunction={handleDelete}
        />
      )}
    </div>
  );
};

export default MapList;
