import React from "react";
import moment from "moment-timezone";
import {
  Add,
  CheckOutlined,
  CloseOutlined,
  DoneAllOutlined,
  PersonOutline,
  WarningAmberOutlined,
  QuestionMarkOutlined,
} from "@mui/icons-material";

const WorkOrderHistory = ({ workOrder }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function formatDate(date) {
    if (date) {
      const userTimezone = "America/New_York";
      const userLocalTime = moment.tz(date, userTimezone);
      const formattedDate = userLocalTime.format("MMM D, YYYY h:mm a");

      return formattedDate;
    } else {
      return "n/a";
    }
  }

  function appendIconToHistory(history) {
    if (!history) {
      return [];
    }
    return history.map((item, index) => {
      let icon = "";
      let iconColor = "";
      let iconBackground = "";
      let text = "";
      let id = index;
      switch (item.action) {
        case "created":
          text = "Work order created.";
          icon = Add;
          iconColor = "bg-secondary-100";
          iconBackground = "bg-secondary-1000";
          break;
        case "assigned":
          text = "assigned work order to ";
          icon = PersonOutline;
          iconColor = "bg-accent-300";
          iconBackground = "bg-accent-1100";
          break;
        case "complete":
          text = " marked as complete";
          icon = CheckOutlined;
          iconColor = "bg-accent-500";
          iconBackground = "bg-accent-900";
          break;
        case "incomplete":
          text = " marked as incomplete";
          icon = CloseOutlined;
          iconColor = "bg-reds-700";
          iconBackground = "bg-reds-1000";
          break;
        case "unable":
          text = " marked as unable to complete";
          icon = WarningAmberOutlined;
          iconColor = "bg-reds-200";
          iconBackground = "bg-reds-1100";
          break;
        case "closed":
          text = " marked as closed";
          icon = DoneAllOutlined;
          iconColor = "bg-accent-300";
          iconBackground = "bg-accent-1000";
          break;
        // Add more cases for other actions as needed
        default:
          text = "default action";
          icon = QuestionMarkOutlined;
          iconColor = "bg-secondary-100";
          iconBackground = "bg-primary";
          break;
      }
      return { ...item, id, text, icon, iconColor, iconBackground };
    });
  }

  const updatedHistory = appendIconToHistory(workOrder.history);

  return (
    <ul role="list" className="w-full ">
      {updatedHistory.map((history, historyIdx) => (
        <li key={history.id}>
          <div className="relative pb-8">
            {historyIdx !== updatedHistory.length - 1 ? (
              <span
                className="absolute left-2 top-4 -ml-px h-full w-0.5 bg-secondary-1000"
                aria-hidden="true"
              />
            ) : null}
            <div className="relative flex space-x-3">
              <div className="flex align-middle items-center">
                <span
                  className={classNames(
                    history.iconColor,
                    "rounded-full flex align-middle justify-center ring-8 ring-white"
                  )}
                >
                  <history.icon
                    style={{ fontSize: "1.1rem" }}
                    className={classNames(
                      history.iconBackground,
                      "rounded-full"
                    )}
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="flex min-w-0 flex-1 justify-between space-x-4 ">
                <div>
                  <p className="text-sm text-gray-500">
                    {history.completedBy?.firstname} {history.text}{" "}
                    {history.assignedTo !== null ? (
                      <span className="font-medium text-gray-900">
                        {history.assignedTo?.firstname}{" "}
                        {history.assignedTo?.lastname}
                      </span>
                    ) : (
                      <span className="font-medium text-gray-900">
                        Unassigned
                      </span>
                    )}
                  </p>
                </div>
                <div className="whitespace-nowrap text-right text-sm text-gray-500">
                  <time dateTime={history.timestamp}>
                    {formatDate(history.timestamp)}
                  </time>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default WorkOrderHistory;
