import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { FacilityContext } from "../../context/FacilityContext";
import Widget from "../../updatedcomponents/data-display/Widget";
import UrgentWorkOrderTable from "../../updatedcomponents/tables/UrgentWorkOrderTable";
import HomeChart from "../../updatedcomponents/data-display/HomeChart";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import {
  CalendarTodayOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import DateFilter from "../../updatedcomponents/general-ui/DateFilter";
import useFetchData from "../../hooks/useFetchData";

const Home = () => {
  // const [widgetData, setWidgetData] = useState([]);

  const defaultRecurringTableData = {
    complete: 1,
    incomplete: 1,
    closed: 1,
    overdue: 1,
  };

  const defaultWorkOrders = {
    workOrders: [],
    workOrderStatusCounts: { complete: 1, incomplete: 1, closed: 1, unable: 1 },
  };

  const { selectedFacility } = useContext(FacilityContext);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [dateParams, setDateParams] = useState({
    displayText: moment().format("MMMM YYYY"),
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    selectedRange: "month",
  });

  const { data: recurringTableData, error: recurringTableError } = useFetchData(
    selectedFacility ? `/api/home/recurring-table/${selectedFacility}` : null,
    {
      startDate: dateParams.startDate,
      endDate: dateParams.endDate,
    },
    [selectedFacility, dateParams],
    defaultRecurringTableData
  );

  const { data: workOrders, error: workOrdersError } = useFetchData(
    selectedFacility ? `/api/home/work-orders/${selectedFacility}` : null,
    {
      startDate: dateParams.startDate,
      endDate: dateParams.endDate,
    },
    [selectedFacility, dateParams],
    defaultWorkOrders
  );

  if (recurringTableError || workOrdersError) {
    console.error(
      "Error fetching Dashboard data:",
      recurringTableError || workOrdersError
    );
  }

  const currentRecurringTableData =
    recurringTableData || defaultRecurringTableData;
  const currentWorkOrders = workOrders || defaultWorkOrders;

  return (
    <div>
      <div className="relative bg-secondary-1100 sm:bg-primary pb-1">
        <PageHeader
          title="Dashboard"
          buttons={
            <div className="flex w-full h-fit justify-evenly mx-4 gap-4">
              <div className="flex-col flex ">
                <DateFilter
                  dateParams={dateParams}
                  setDateParams={setDateParams}
                />
              </div>
              <button
                onClick={() => {
                  setDateParams({
                    displayText: moment().format("MMMM YYYY"),
                    startDate: moment().startOf("month").toDate(),
                    endDate: moment().endOf("month").toDate(),
                  });
                }}
                className="flex-1 flex items-center justify-center rounded-sm bg-secondary-100 px-2 py-1 h-fit text-center text-sm text-primary border-transparent border hover:bg-secondary-200"
              >
                Reset to Default
              </button>
            </div>
          }
        />
        <div className="m-4">
          {/* <Widget value={workOrders.workOrderStatusCounts} /> */}
          <div className="mt-4">
            <HomeChart
              recurringTable={currentRecurringTableData}
              workOrders={currentWorkOrders?.workOrderStatusCounts}
            />
          </div>
          <div className="my-4">
            <UrgentWorkOrderTable workOrders={currentWorkOrders?.workOrders} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
