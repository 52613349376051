import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  ArrowBack,
  CalendarMonthOutlined,
  CheckCircleOutlined,
  ChecklistOutlined,
  Close,
  DeleteOutline,
  Email,
  EmailOutlined,
  Person,
  PhoneOutlined,
  RemoveCircleOutlineOutlined,
  Sms,
  Verified,
  WorkOutline,
} from "@mui/icons-material";
import moment from "moment-timezone";
import MirrorDensityPlot from "../../updatedcomponents/data-display/MirrorDensityPlot";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { FacilityContext } from "../../context/FacilityContext";
import DeleteModal from "../../components/deletemodal/DeleteModal";
import { useNavigate } from "react-router-dom";
import StatusBadge from "../../updatedcomponents/general-ui/StatusBadges";
import {
  capitalizeString,
  formatDate,
  formatPhoneNumber,
} from "../../utils/FormatFunctions";
import useFetchData from "../../hooks/useFetchData";

const UserView = ({ userData, setUserData }) => {
  const { selectedFacility } = useContext(FacilityContext);
  const { currentUser } = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);

  const [woMessage, setWoMessage] = useState("No work orders to show.");

  const [recurringMessage, setRecurringMessage] = useState(
    "No recurring tasks to show."
  );
  const userId = userData._id;


  //Enpoint for fetching work orders
  // Fetching work orders associated with the userId and selectedFacility
  const { data: woTasksData, error: woTasksError } = useFetchData(
    userId && selectedFacility
      ? `/api/employee-tasks/list/${userId}?facility=${selectedFacility}`
      : null,
    {},
    [userId, selectedFacility],
    [] // default value for woTasks data
  );

  const woTasks = woTasksData || [];

  if (woTasksError) {
    console.error("Error fetching work orders:", woTasksError);
    setWoMessage("Unexpected error, unable to retrieve work orders");
  }

    // Fetching recurring tasks associated with the selectedFacility and userId
    const { data: recurringTasksData, error: recurringTasksError } = useFetchData(
      selectedFacility && userId
        ? `/api/employee-tasks/recurring/list/${selectedFacility}/${userId}`
        : null,
      {},
      [selectedFacility, userId],
      [] // default value for recurringTasks data
    );
  
    const recurringTasks = recurringTasksData || [];
  
    if (recurringTasksError) {
      console.error("Error fetching recurring tasks:", recurringTasksError);
      setRecurringMessage("Unexpected error, unable to retrieve recurring tasks.");
    }

  // Fetching task history associated with the selectedFacility and userId
  const { data: taskHistoryData, error: taskHistoryError } = useFetchData(
    selectedFacility && userId
      ? `/api/user/tasks/history/${selectedFacility}/${userId}`
      : null,
    {},
    [selectedFacility, userId],
    [] // default value for taskHistory data
  );

  const taskHistory = taskHistoryData || [];

  if (taskHistoryError) {
    console.error("Error fetching task history:", taskHistoryError);
  }

  const handleDelete = async (user) => {
    const userToDelete = user._id;
    try {
      await axios.delete(`/api/user/delete/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          userToDelete: userToDelete,
          deletedBy: currentUser._id,
        },
      });
      setShowPopup(false);
      setUserData({});
    } catch (error) {
      console.log("Error deleting user:", error);
    }
  };

  const navigate = useNavigate();

  const handleView = (task) => {
    if (task.shortId) {
      navigate(`/work-orders/${task.shortId}`);
    }
  };

  const userInfoTable = (
    <div className=" my-4 grid grid-cols-1 md:grid-cols-2 w-full">
      {/* First column: Image, Name, Status, and Notification Settings */}
      <div className="my-8 col-span-full md:col-span-1 flex flex-col gap-4 justify-center items-center border-r-2 border-secondary-1000 md:border-r">
        <div className="flex-shrink-0 ">
          {userData.avatar ? (
            <img
              className="h-16 w-16 rounded-full"
              src={userData.avatar}
              alt=""
            />
          ) : (
            <Person className="h-11 w-11 rounded-full" />
          )}
        </div>
        <h2 className="text-xl font-medium">
          {capitalizeString(userData.firstname)}{" "}
          {capitalizeString(userData.lastname)}
        </h2>
        <div className="flex gap-4">
          {userData.isActive === true ? (
            <div className="w-fit inline-flex items-center rounded-sm bg-accent-1000 px-2 py-1 text-xs font-medium text-accent-200 ring-1 ring-inset ring-accent-200/20">
              <CheckCircleOutlined
                className="mr-1"
                style={{ fontSize: ".9rem" }}
              />{" "}
              Active
            </div>
          ) : (
            <div className="w-fit inline-flex items-center rounded-sm bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
              <Close className="mr-1" style={{ fontSize: ".9rem" }} /> Inactive
            </div>
          )}
          {userData.isVerified === true ? (
            <div className="w-fit inline-flex items-center rounded-sm bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              <Verified className="mr-1" style={{ fontSize: ".9rem" }} />{" "}
              Verified
            </div>
          ) : (
            <div className="w-fit inline-flex items-center rounded-sm bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
              <Verified className="mr-1" style={{ fontSize: ".9rem" }} />{" "}
              Unverified
            </div>
          )}
        </div>
      </div>

      {/* Second column: Rest of the Information */}
      <div className="md:col-span-1 flex flex-col justify-center  ml-14 gap-y-2">
        <div className="text-secondary-100 font-semibold mb-2">
          User Information
        </div>
        <div className="text-secondary-500 text-sm flex items-center gap-2">
          <EmailOutlined style={{ fontSize: "1rem" }} /> {userData.email}
        </div>
        <div className="text-secondary-500 text-sm flex items-center gap-2">
          <PhoneOutlined style={{ fontSize: "1rem" }} />{" "}
          {formatPhoneNumber(userData.phone)}
        </div>
        <div className="text-secondary-500 text-sm flex items-center gap-2">
          <WorkOutline style={{ fontSize: "1rem" }} />{" "}
          {capitalizeString(userData.access)}
        </div>
        <div className="text-secondary-500 text-sm flex items-center gap-2">
          <CalendarMonthOutlined style={{ fontSize: "1rem" }} />{" "}
          {formatDate(userData.joinDate, "YYYY-MM-DD h:mm a")}{" "}
          <span className="text-xs">(Join Date)</span>
        </div>
        <div className="text-secondary-500 text-sm flex items-center gap-2">
          {userData.smsNotify === true ? (
            <div className="w-fit inline-flex items-center rounded-sm bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              <Sms className="mr-1" style={{ fontSize: ".9rem" }} /> SMS
              Notifications Enabled
            </div>
          ) : (
            <div className="w-fit inline-flex items-center rounded-sm bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
              <RemoveCircleOutlineOutlined
                className="mr-1"
                style={{ fontSize: ".9rem" }}
              />{" "}
              SMS Notifications Disabled
            </div>
          )}
        </div>
        <div className="text-secondary-500 text-sm flex items-center gap-2">
          {userData.emailNotify === true ? (
            <div className="w-fit inline-flex items-center rounded-sm bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              <Email className="mr-1" style={{ fontSize: ".9rem" }} />
              Email Notifications Enabled
            </div>
          ) : (
            <div className="w-fit inline-flex items-center rounded-sm bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
              <RemoveCircleOutlineOutlined
                className="mr-1"
                style={{ fontSize: ".9rem" }}
              />{" "}
              Email Notifications Disabled
            </div>
          )}
        </div>
      </div>
      <div className="mt-6 mx-1 md:col-span-2 border-b border-secondary-1000" />
    </div>
  );

  const assignedWorkOrderTable = (tasks, type, message) => (
    <div className="flex-1 px-4 sm:px-6  border border-secondary-1000 rounded-sm  h-96 overflow-y-scroll  hide-scrollbar-arrows">
      <h2 className="py-4 text-md text-secondary-300 font-semibold">
        Assigned {type}
      </h2>
      <table className="min-w-full">
        <thead>
          <tr className="bg-secondary-1200 border border-secondary-1000 border-spacing-2">
            <th
              scope="col"
              className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary-100 "
            >
              Title
            </th>
            <th
              scope="col"
              className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              ID
            </th>
            <th
              scope="col"
              className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              Status
            </th>
            <th scope="col" className="relative py-0.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">View</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {tasks.length === 0 ? (
            <Fragment>
              <tr className="h-2"></tr>
              <tr>
                <td
                  colSpan="4"
                  className="text-center text-md py-8 bg-secondary-1200 border border-secondary-1000"
                >
                  <div className="flex flex-col items-center justify-center text-sm gap-2 text-secondary-400">
                    <ChecklistOutlined style={{ fontSize: "2rem" }} />
                    {message}
                  </div>
                </td>
              </tr>
            </Fragment>
          ) : (
            tasks.map((task) => (
              <Fragment key={`${task._id}-${type}`}>
                <tr key={`${task._id}-gap`} className="h-2"></tr>
                <tr
                  key={task._id}
                  className=" border border-secondary-1000 text-secondary-100"
                >
                  <td className="truncate max-w-12 whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-secondary-100 ">
                    {task.title}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1.5 text-sm text-secondary-500">
                    {task?.shortId}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1.5 text-sm text-secondary-500">
                    {<StatusBadge status={task.status} />}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1.5 text-sm ">
                    <button
                      onClick={() => handleView(task)}
                      className=" px-2 py-1 text-sm font-semibold text-secondary-100 underline  hover:text-secondary-300"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))
          )}
        </tbody>
      </table>
    </div>
  );

  const assignmentChart = (
    <div className="flex-1 px-4 my-4">
      <MirrorDensityPlot data={taskHistory} />
    </div>
  );

  const deactivate = (
    <div className="h-14 px-4   flex-1 flex  items-center gap-2 m-full border-t border-secondary-1000">
      <button
        onClick={() => setShowPopup(true)}
        className="bg-reds-500 text-primary text-sm hover:bg-reds-600 px-2 py-1 rounded  shadow cursor-pointer flex items-center gap-2"
      >
        <DeleteOutline style={{ fontSize: "1rem" }} />
        Delete user
      </button>
      <p className="text-reds-500 text-sm">This action can not be undone</p>
    </div>
  );
  return (
    <div>
      {userInfoTable}
      <div className="flex gap-4 flex-col md:flex-row mx-4">
        {assignedWorkOrderTable(woTasks, "Work Orders", woMessage)}
        {assignedWorkOrderTable(
          recurringTasks,
          "Recurring Tasks",
          recurringMessage
        )}
      </div>
      <div className="flex">{assignmentChart}</div>
      <div className="flex">{deactivate}</div>

      <DeleteModal
        user={userData}
        handleDelete={handleDelete}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
      />
    </div>
  );
};

export default UserView;
