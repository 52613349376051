import { useState } from "react";
import MonthView from "./MonthView";
import CalendarHeader from "./CalendarHeader";
import DayView from "./DayView";
import WeekView from "./WeekView";
import YearView from "./YearView";

const ToDoCalendar = ({ data, dateParams, setDateParams }) => {
  const { currentView } = dateParams;

  return (
    <div className="pb-4">
      <CalendarHeader dateParams={dateParams} setDateParams={setDateParams} />
      {currentView === "day" && (
        <DayView dateParams={dateParams} setDateParams={setDateParams} />
      )}
      {currentView === "week" && <WeekView dateParams={dateParams} />}
      {currentView === "month" && (
        <MonthView dateParams={dateParams} tasks={data} />
      )}
      {currentView === "year" && (
        <YearView dateParams={dateParams} setDateParams={setDateParams} />
      )}
    </div>
  );
};

export default ToDoCalendar;
