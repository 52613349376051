import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

const SessionExpiredModal = ({ isSessionExpired, setIsSessionExpired }) => {
  const navigate = useNavigate();
  return (
    <Transition appear show={isSessionExpired} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {
          setIsSessionExpired(false);
        }}
      >
        <div className="min-h-screen px-4 text-center flex items-center justify-center ">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

          <div className="bg-white z-10 p-4 rounded">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-gray-800 bg-white"
            >
              Session expired
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Your session has expired. Please log in again.
              </p>
            </div>
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-accent-100 hover:bg-accent-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-100"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Navigate to login
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SessionExpiredModal;
