import React, { useState, useEffect, useContext } from "react";
import TeamList from "../../updatedcomponents/tables/TeamList";
import { FacilityContext } from "../../context/FacilityContext";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import NewUserForm from "../../updatedcomponents/forms/NewUserForm";
import UserInfo from "./UserView";
import { Add, ArrowBack } from "@mui/icons-material";
import Pagination from "../../updatedcomponents/table-components/Pagination";

const Team = () => {
  const { selectedFacility } = useContext(FacilityContext);
  const { currentUser } = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [users, setUsers] = useState([]);

  const permissions = currentUser.access;

  const [viewUserInfo, setViewUserInfo] = useState({});
  const isViewUserInfoEmpty = Object.keys(viewUserInfo).length === 0;

  const [itemsToShow, setItemsToShow] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedFacility !== (null || undefined || "")) {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `/api/user/list/${selectedFacility}?currentPage=${currentPage}&limit=${itemsToShow}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUsers(response.data.users);
          setTotalPages(response.data.totalPages);
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      }
    };

    fetchData();
  }, [selectedFacility, refresh, currentPage, itemsToShow]);

  const handleUser = () => {
    setShowPopup(true);
  };
  const content = (
    <div>
      {isViewUserInfoEmpty ? (
        <>
          <PageHeader
            title={"Team"}
            subtitle={"All users in the current facility"}
            buttons={
              (permissions === "regional" ||
                permissions === "organization-admin") && (
                <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly mx-6 sm:gap-4 gap-2">
                  <button
                    type="button"
                    className="flex items-center justify-center gap-2 rounded-sm bg-secondary-100 px-4 py-1 text-center text-sm font-semibold text-primary border-transparent border shadow-sm hover:bg-secondary-200"
                    onClick={handleUser}
                  >
                    <Add style={{ fontSize: "1rem" }} />
                    Add User
                  </button>
                </div>
              )
            }
          />
          <div className="px-4 sm:px-6 lg:px-8">
            <TeamList
              users={users}
              refresh={refresh}
              setRefresh={setRefresh}
              facility={selectedFacility}
              permissions={permissions}
              addUser={handleUser}
              setViewUserInfo={setViewUserInfo}
            />
            <Pagination
              itemsToShow={itemsToShow}
              setItemsToShow={setItemsToShow}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </>
      ) : (
        <>
          <PageHeader
            title={
              <button
                type="button"
                className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
                onClick={() => setViewUserInfo({})}
              >
                <ArrowBack style={{ fontSize: "1rem" }} /> Back to Team
              </button>
            }
          />
          <UserInfo userData={viewUserInfo} setUserData={setViewUserInfo} />
        </>
      )}
      {selectedFacility && (
        <NewUserForm
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          refresh={refresh}
          setRefresh={setRefresh}
          facility={selectedFacility}
          type={"new"}
          user={{}}
        />
      )}
    </div>
  );
  return <div>{content}</div>;
};

export default Team;
