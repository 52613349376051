import React, { useState } from "react";
import axios from "axios";
import { Person } from "@mui/icons-material";
import DeleteModal from "../deletemodal/DeleteModal";

const FacilitiesList = ({ facilities, setFacilities }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedfacility, setSelectedfacility] = useState([]);
  const [status, setStatus] = useState("");

  const [filters, setFilters] = useState({
    name: "",
    location: "",
  });

  // Function to handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleDeleteClick = (facility) => {
    setSelectedfacility(facility);
    setShowPopup(true);
  };

  const handleDelete = async (facility) => {
    try {
      await axios.delete(`/api/admin/facility/delete/${facility._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setFacilities((prevFacilities) =>
        prevFacilities.filter(
          (prevFacility) => prevFacility._id !== facility._id
        )
      );
      setShowPopup(false);
    } catch (error) {
      console.log("Error deleting facility:", error);
    }
  };

  const handleActivation = async (facility, status) => {
    try {
      await axios.post(
        `/api/admin/facility/status/${facility._id}`,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setFacilities((prevFacilities) =>
        prevFacilities.map((prevFacility) =>
          prevFacility._id === facility._id
            ? { ...prevFacility, isActive: status }
            : prevFacility
        )
      );
    } catch (error) {
      console.log("Error deleting facility:", error);
    }
  };

  const filteredData = facilities.filter((facility) => {
    const { name, location } = filters;

    return (
      (name === "" ||
        facility.name.toLowerCase().startsWith(name.toLowerCase())) &&
      (location === "" ||
        facility.location.toLowerCase().includes(location.toLowerCase()))
    );
  });

  return (
    <div className="facilitys-list">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="mt-4 sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Facilties
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all available facilities.
            </p>
          </div>
        </div>
        <div className="flex w-100 justify-evenly gap-1 flex-wrap mt-8">
          <div className="flex-1 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-accent-100">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-900"
            >
              Company Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Name"
              value={filters.name}
              onChange={handleFilterChange}
            />
          </div>
          <div className="flex-1 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-accent-100">
            <label
              htmlFor="type"
              className="block text-xs font-medium text-gray-900"
            >
              Location
            </label>
            <input
              type="text"
              name="location"
              id="location"
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Location"
              value={filters.location}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr></tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {filteredData.map((facility) => (
                    <tr key={facility._id}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {facility.name}
                            </div>
                            <div className="mt-1 text-gray-500">
                              {facility.email}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {facility.location}
                      </td>

                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {facility.isActive ? (
                          <button
                            className="bg-red-100 text-red-700 py-1 px-2 rounded-md shadow-md"
                            onClick={() => handleActivation(facility, false)}
                          >
                            Deactivate facility
                          </button>
                        ) : (
                          <button
                            className="bg-green-100 text-green-800 py-1 px-2 rounded-md shadow-md"
                            onClick={() => handleActivation(facility, true)}
                          >
                            Activate facility
                          </button>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <button
                          className="bg-red-600 text-primary py-1 px-2 rounded-md shadow-md"
                          onClick={() => handleDeleteClick(facility)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <DeleteModal
          user={selectedfacility}
          handleDelete={handleDelete}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        />
      )}
    </div>
  );
};

export default FacilitiesList;
