import React, { useState, useEffect, useContext, Fragment } from "react";
import axios, { all } from "axios";
import { AuthContext } from "../../context/AuthContext";
import { CheckCircleOutline, ErrorOutline, Person } from "@mui/icons-material";
import UploadImage from "../../updatedcomponents/modals/UploadImage";
import { useForm } from "react-hook-form";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ActionResponse from "../../updatedcomponents/modals/ActionResponse";

const SettingsPage = () => {
  const { currentUser, dispatch } = useContext(AuthContext);

  const permissions = currentUser.access;

  const schema = yup.object().shape({
    firstname: yup.string().required("*Required"),
    lastname: yup.string().required("*Required"),
    phone: yup.string(),
    email: yup
      .string()
      .email("*Must be a valid email address.")
      .required("*Required"),
    password: yup.string(),
    "new-password": yup.string(),
    "confirm-new-password": yup
      .string()
      .oneOf([yup.ref("new-password"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: currentUser.firstname || "",
      lastname: currentUser.lastname || "",
      email: currentUser.email || "",
      phone: currentUser.phone || "",
      emailNotifications: {
        taskAssignment:
          currentUser?.emailNotifications?.taskAssignment || false,
        workOrderCreation:
          currentUser?.emailNotifications?.workOrderCreation || false,
        urgentWorkOrderCreation:
          currentUser?.emailNotifications?.urgentWorkOrderCreation || false,
      },
      smsNotifications: {
        taskAssignment: currentUser?.smsNotifications?.taskAssignment || false,
        workOrderCreation:
          currentUser?.smsNotifications?.workOrderCreation || false,
        urgentWorkOrderCreation:
          currentUser?.smsNotifications?.urgentWorkOrderCreation || false,
      },
      password: "",
      confirmPassword: "",
    },
  });
  const [showPhotoAdd, setShowPhotoAdd] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [changePhone, setChangePhone] = useState(false);

  const [response, setResponse] = useState({
    show: false,
    title: "",
    body: "",
    icon: null,
  });

  const userId = currentUser._id;

  const handleFormSubmit = async (data, event) => {
    event.preventDefault();
    console.log(data);
    const updatedUserData = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.phone,
      password: data.password,
      newPassword: data["new-password"],
      emailNotifications: data["emailNotifications"],
      smsNotifications: data["smsNotifications"],
    };

    const updateUser = async (dispatch, updatedUserData) => {
      const token = localStorage.getItem("token");
      await axios
        .put(`/api/settings/user/${userId}`, updatedUserData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const updatedUser = { ...currentUser, ...updatedUserData };
          localStorage.setItem("user", JSON.stringify(updatedUser));

          setResponse({
            show: true,
            title: "Success",
            body: "User data updated",
            icon: (
              <CheckCircleOutline
                className="text-accent-500"
                style={{ fontSize: "1rem" }}
              />
            ),
          });

          setTimeout(() => {
            dispatch({ type: "UPDATE_USER", payload: updatedUser });
          }, 2000);
        })
        .catch((error) => {
          console.error("Error updating user:", error);
          if (error.response.status === 400) {
            setError("password", { message: "Invalid password" });
          }
          setResponse({
            show: true,
            title: "Error updating user data",
            body: "Invalid password.",
            icon: (
              <ErrorOutline
                className="text-reds-500"
                style={{ fontSize: "1rem" }}
              />
            ),
          });
        });
    };
    await updateUser(dispatch, updatedUserData);
  };

  const checkboxes = [
    {
      label: "Task Assignment",
      description: "Receive notifications whenever you are assigned a new task",
      types: [
        "emailNotifications.taskAssignment",
        "smsNotifications.taskAssignment",
      ],
      access: [
        "worker",
        "administrator",
        "director",
        "regional",
        "organization-admin",
      ],
    },
    {
      label: "All Work Order Creation",
      description: "Receive notifications whenever a work order is created",
      types: [
        "emailNotifications.workOrderCreation",
        "smsNotifications.workOrderCreation",
      ],
      access: ["administrator", "director", "regional", "organization-admin"],
    },
    {
      label: "Urgent Work Order Creation",
      description:
        "Receive notifications whenever an urgent work order is created",
      types: [
        "emailNotifications.urgentWorkOrderCreation",
        "smsNotifications.urgentWorkOrderCreation",
      ],
      access: ["administrator", "director", "regional", "organization-admin"],
    },
  ];

  const Checkbox = ({ register, setValue, watchValue, name, id }) => (
    <div className="flex justify-end">
      <label htmlFor={id} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            {...register(name)}
            type="checkbox"
            id={id}
            className="sr-only"
            onChange={(e) => setValue(name, e.target.checked)}
          />
          <div
            className={`block w-10 h-6 rounded-full ${
              watchValue ? "bg-secondary-100 " : "bg-secondary-600"
            }`}
          ></div>
          <div
            className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${
              watchValue ? "translate-x-full" : ""
            }`}
          ></div>
        </div>
      </label>
    </div>
  );

  const content = (
    <div>
      <ActionResponse response={response} setResponse={setResponse} />
      <PageHeader
        title="Settings"
        buttons={
          <div className="w-full  justify-end">
            <button
              onClick={handleSubmit(handleFormSubmit)}
              className="hidden sm:flex  items-center gap-2 rounded-sm bg-secondary-100 px-4 py-1 text-center text-sm font-semibold text-primary border-transparent border shadow-sm hover:bg-secondary-200"
            >
              Save
            </button>
          </div>
        }
      />

      <div className="px-4 sm:px-6 lg:px-8 flex flex-col justify-center max-w-5xl mx-auto">
        <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
          {/* PERSONAL INFORMATION SECTION */}
          <div className="flex w-full flex-col sm:flex-row justify-between mx-auto py-6 border-b border-secondary-1000">
            <h2 className="text-secondary-100 text-xl">Personal Information</h2>
            <h3 className="text-secondary-500 text-md font-normal">
              Information related to your account
            </h3>
          </div>
          <div className="my-4 flex items-center gap-x-4">
            {currentUser.avatar ? (
              <img
                src={currentUser.avatar}
                alt=""
                className="h-20 w-20 flex-none rounded-full bg-gray-800 object-cover"
              />
            ) : (
              <Person className="flex-none rounded-full bg-gray-50 h-24 object-cover" />
            )}
            <div>
              <button
                type="button"
                className="rounded-sm bg-secondary-1000 px-3 py-1 text-xs font-medium text-secondary-100  hover:bg-secondary-1100"
                onClick={() => setShowPhotoAdd(true)}
              >
                Change avatar
              </button>
              <p className="mt-2 text-xs  text-secondary-100">
                JPG, GIF or PNG. 1MB max.
              </p>
            </div>
          </div>
          <div className="my-4 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-3 space-y-4">
              <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                Name
              </label>
              <div className="flex flex-col sm:flex-row gap-4 justify-start w-full">
                <div className="flex flex-col">
                  <label className="block text-xs text-secondary-100 pb-1">
                    First name
                  </label>
                  <input
                    className="block w-full rounded-sm border-secondary-1000 outline-none  py-1.5 text-secondary-300 ring-0 focus:ring-2 focus:ring-inset focus:ring-secondary-100 focus:border-0 sm:text-sm "
                    type="text"
                    {...register("firstname")}
                  />
                  {errors.firstname && (
                    <p className="text-reds-400 text-xs">
                      {errors.firstname.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="block text-xs text-secondary-100 pb-1">
                    Last name
                  </label>

                  <input
                    type="text"
                    className="block w-full rounded-sm border-secondary-1000 outline-none  py-1.5 text-secondary-300 ring-0 focus:ring-2 focus:ring-inset focus:ring-secondary-100 focus:border-0 sm:text-sm "
                    {...register("lastname")}
                  />
                  {errors.lastname && (
                    <p className="text-reds-400 text-xs">
                      {errors.lastname.message}
                    </p>
                  )}
                </div>
              </div>

              <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                Phone
              </label>
              <div className="flex w-full gap-4">
                <div className="flex w-full flex-col">
                  <input
                    type="text"
                    disabled={!changePhone}
                    className={`${
                      changePhone
                        ? "bg-primary"
                        : "bg-secondary-1100 cursor-not-allowed"
                    } block w-full rounded-sm border-secondary-1000 outline-none  py-1.5 text-secondary-300 ring-0 focus:ring-2 focus:ring-inset focus:ring-secondary-100 focus:border-0 sm:text-sm `}
                    {...register("phone")}
                  />
                  {errors.phone && (
                    <p className="text-reds-400 text-xs">
                      {errors.phone.message}
                    </p>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => setChangePhone(!changePhone)}
                  className="flex items-center justify-center gap-2 rounded-sm bg-secondary-1000 px-4 py-1 text-center text-sm font-semibold text-secondary-100 border-transparent border shadow-sm hover:bg-secondary-1100"
                >
                  Change
                </button>
              </div>
            </div>
          </div>

          {/* SECURITY SECTION */}
          <div className="flex w-full flex-col sm:flex-row justify-between mx-auto py-6 border-b border-secondary-1000">
            <h2 className="text-secondary-100 text-xl">Account Security</h2>
            <h3 className="text-secondary-500 text-md font-normal">
              Important security information for your account
            </h3>
          </div>
          <div className="my-4 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-3 space-y-4">
              <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                Email
              </label>
              <div className="flex gap-4 w-full">
                <div className="flex w-full flex-col">
                  <input
                    disabled={!changeEmail}
                    type="email"
                    className={`${
                      changeEmail
                        ? "bg-primary"
                        : "bg-secondary-1100 cursor-not-allowed"
                    } block w-full rounded-sm border-secondary-1000 outline-none  py-1.5 text-secondary-300 ring-0 focus:ring-2 focus:ring-inset focus:ring-secondary-100 focus:border-0 sm:text-sm `}
                    {...register("email")}
                  />
                  {errors.email && (
                    <p className="text-reds-400 text-xs">
                      {errors.email.message}
                    </p>
                  )}
                </div>

                <button
                  type="button"
                  onClick={() => setChangeEmail(!changeEmail)}
                  className="flex h-fit items-center justify-center gap-2 rounded-sm bg-secondary-1000 px-4 py-1 text-center text-sm font-semibold text-secondary-100 border-transparent border shadow-sm hover:bg-secondary-1100"
                >
                  Change
                </button>
              </div>

              <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                Current Password
              </label>
              <div className="flex ">
                <div className="flex gap-4 w-full">
                  <input
                    disabled={!changePassword}
                    type="password"
                    className={`${
                      changePassword
                        ? "bg-primary"
                        : "bg-secondary-1100 cursor-not-allowed"
                    } block w-full rounded-sm border-secondary-1000 outline-none  py-1.5 text-secondary-300 ring-0 focus:ring-2 focus:ring-inset focus:ring-secondary-100 focus:border-0 sm:text-sm `}
                    {...register("password")}
                  />
                  {errors["password"] && (
                    <p className="text-reds-400 text-xs">
                      {errors["password"].message}
                    </p>
                  )}
                  <button
                    type="button"
                    onClick={() => setChangePassword(!changePassword)}
                    className="flex items-center justify-center gap-2 rounded-sm bg-secondary-1000 px-4 py-1 text-center text-sm font-semibold text-secondary-100 border-transparent border shadow-sm hover:bg-secondary-1100"
                  >
                    Change
                  </button>
                </div>
              </div>
              {changePassword && (
                <>
                  <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                    New Password
                  </label>
                  <div className="flex gap-4 ">
                    <input
                      type="password"
                      className="flex w-full rounded-sm border-secondary-1000 outline-none  py-1.5 text-secondary-300 ring-0 focus:ring-2 focus:ring-inset focus:ring-secondary-100 focus:border-0 sm:text-sm "
                      {...register("new-password")}
                    />
                  </div>
                  <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                    Confrim New Password
                  </label>
                  <div className="flex gap-4 flex-col">
                    <input
                      type="password"
                      className="flex w-full rounded-sm border-secondary-1000 outline-none  py-1.5 text-secondary-300 ring-0 focus:ring-2 focus:ring-inset focus:ring-secondary-100 focus:border-0 sm:text-sm "
                      {...register("confirm-new-password")}
                    />
                    {errors["confirm-new-password"] && (
                      <p className="text-reds-400 text-xs">
                        {errors["confirm-new-password"].message}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {/* NOTIFICATIONS SECTION */}
          <div className="flex w-full flex-col sm:flex-row justify-between mx-auto py-6 border-b border-secondary-1000">
            <h2 className="text-secondary-100 text-xl">Notifications</h2>
            <h3 className="text-secondary-500 text-md font-normal">
              Stay informed with notifications
            </h3>
          </div>
          <div className="w-full">
            <div className="grid grid-cols-3 space-y-6 text-end">
              <div></div>
              <div className="flex justify-end ">
                <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                  Email
                </label>
              </div>
              <div className="flex justify-end">
                <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                  SMS
                </label>
              </div>
              {checkboxes
                .filter(({ access }) => access.includes(permissions))
                .map(({ label, description, types }) => (
                  <Fragment key={label}>
                    <div className="text-left">
                      <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                        {label}
                      </label>
                      <p className="text-xs text-secondary-500">
                        {description}
                      </p>
                    </div>
                    {types.map((type) => (
                      <Checkbox
                        key={type}
                        register={register}
                        setValue={setValue}
                        watchValue={watch(type)}
                        name={type}
                        id={type}
                      />
                    ))}
                  </Fragment>
                ))}
            </div>
          </div>
          {/* 
          <div className="my-6 w-full">
            <div className="grid grid-cols-2 sm:grid-cols-3 space-y-6">
              <div className="col-span-1 sm:col-span-2">
                <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                  SMS Text
                </label>
                <p className="text-xs text-secondary-500">
                  Receive SMS texts whenever you are assigned a new task
                </p>
              </div>
              <div className="flex justify-end">
                <label
                  htmlFor="smsNotify"
                  className="flex items-center cursor-pointer"
                >
                  <div className="relative">
                    <input
                      {...register("smsNotify")}
                      type="checkbox"
                      id="smsNotify"
                      className="sr-only"
                      onChange={(e) => setValue("smsNotify", e.target.checked)}
                    />
                    <div
                      className={`block w-10 h-6 rounded-full ${
                        watchSmsNotify
                          ? "bg-secondary-100 "
                          : "bg-secondary-600"
                      }`}
                    ></div>
                    <div
                      className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${
                        watchSmsNotify ? "translate-x-full" : ""
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
              <div className="col-span-1 sm:col-span-2">
                <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                  Email
                </label>
                <p className="text-xs text-secondary-500">
                  Receive an Email whenever you are assigned a new task
                </p>
              </div>
              <div className="flex justify-end">
                <label
                  htmlFor="emailNotify"
                  className="flex items-center cursor-pointer"
                >
                  <div className="relative">
                    <input
                      {...register("emailNotify")}
                      type="checkbox"
                      id="emailNotify"
                      className="sr-only"
                      onChange={(e) =>
                        setValue("emailNotify", e.target.checked)
                      }
                    />
                    <div
                      className={`block w-10 h-6 rounded-full ${
                        watchEmailNotify
                          ? "bg-secondary-100 "
                          : "bg-secondary-600"
                      }`}
                    ></div>
                    <div
                      className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${
                        watchEmailNotify ? "translate-x-full" : ""
                      }`}
                    ></div>
                  </div>
                </label>
              </div>
              {permissions !== "worker" && (
                <>
                  <div className="col-span-1 sm:col-span-2">
                    <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                      All Work Orders
                    </label>
                    <p className="text-xs text-secondary-500">
                      Receive a notification whenever a work order is created
                    </p>
                  </div>
                  <div className="flex justify-end">
                    <label
                      htmlFor="allNotify"
                      className="flex items-center cursor-pointer"
                    >
                      <div className="relative">
                        <input
                          {...register("allNotify")}
                          type="checkbox"
                          id="allNotify"
                          className="sr-only"
                          onChange={(e) =>
                            setValue("allNotify", e.target.checked)
                          }
                        />
                        <div
                          className={`block w-10 h-6 rounded-full ${
                            watchAllNotify
                              ? "bg-secondary-100 "
                              : "bg-secondary-600"
                          }`}
                        ></div>
                        <div
                          className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${
                            watchAllNotify ? "translate-x-full " : ""
                          }`}
                        ></div>
                      </div>
                    </label>
                  </div>
                  <div className="col-span-1 sm:col-span-2">
                    <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                      Urgent Work Orders
                    </label>
                    <p className="text-xs text-secondary-500">
                      Receive a notification whenever an urgent work order is
                      created
                    </p>
                  </div>
                  <div className="flex justify-end">
                    <label
                      htmlFor="urgentNotify"
                      className="flex items-center cursor-pointer"
                    >
                      <div className="relative">
                        <input
                          {...register("urgentNotify")}
                          type="checkbox"
                          id="urgentNotify"
                          className="sr-only"
                          onChange={(e) =>
                            setValue("urgentNotify", e.target.checked)
                          }
                        />
                        <div
                          className={`block w-10 h-6 rounded-full ${
                            watchUrgentNotify
                              ? "bg-secondary-100 "
                              : "bg-secondary-600"
                          }`}
                        ></div>
                        <div
                          className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${
                            watchUrgentNotify ? "translate-x-full " : ""
                          }`}
                        ></div>
                      </div>
                    </label>
                  </div>
                </>
              )}
            </div>
          </div> */}
          <div className="my-4 w-full flex justify-center">
            <button
              type="submit"
              className="sm:hidden flex  items-center gap-2 rounded-sm bg-secondary-100 px-6 py-1 text-center text-sm font-semibold text-primary border-transparent border shadow-sm hover:bg-secondary-200"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  return (
    <div>
      {content}
      {showPhotoAdd && (
        <UploadImage setShowFileAdd={setShowPhotoAdd} fileType={"image"} />
      )}
    </div>
  );
};

export default SettingsPage;
