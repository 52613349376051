import React, { useState, useEffect, Fragment, useContext } from "react";
import axios from "axios";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import StatusBadge from "../general-ui/StatusBadges";
import {
  CalendarMonthOutlined,
  RepeatOutlined,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import moment from "moment";
import { FacilityContext } from "../../context/FacilityContext";
import { capitalizeString, formatDate, classNames } from "../../utils/FormatFunctions";

const ToDoTable = ({
  data,
  setShowForm,
  users,
  setInstanceToEdit,
  refresh,
  setRefresh,
  handleLogInstanceView,
}) => {
  const { selectedFacility } = useContext(FacilityContext);
  

  const handleAssignment = (task, selectedUser) => {
    if (!task.childId) {
      task.childId = "createId";
    }
    axios
      .put(
        `/api/log/assign`,
        {
          assignedTo: selectedUser === "unassign" ? null : selectedUser,
          taskInfo: task,
          facility: selectedFacility,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Task assigned successfully:", response.data);
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error("Error assigning task:", error);
      });
  };

  const handleComplete = (task) => {
    setShowForm(true);
    setInstanceToEdit(task);
  };

  return (
    <div className="pb-16">
      <table className="min-w-full">
        <thead>
          <tr className="bg-secondary-1200 border border-secondary-1000 border-spacing-2">
            <th
              scope="col"
              className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              <span className="group inline-flex">Task</span>
            </th>
            <th
              scope="col"
              className="hidden 2xl:table-cell py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary-100 sm:pl-0 "
            >
              Date Due
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell  px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              <span className="group inline-flex">Status</span>
            </th>
            <th
              scope="col"
              className="hidden 2xl:table-cell px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              <span className="group inline-flex">Frequency</span>
            </th>
            <th
              scope="col"
              className="hidden xl:table-cell  px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              <span className="group inline-flex">Source </span>
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell  px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              <span className="group inline-flex">Details</span>
            </th>
            {users.length > 0 && (
              <th
                scope="col"
                className="hidden sm:table-cell  px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
              >
                <span className="group inline-flex">Assign</span>
              </th>
            )}
            <th
              scope="col"
              className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              <span className="group inline-flex"></span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-primary">
          {!data || data.length === 0 ? (
            <>
              <tr key={`gap`} className="h-2"></tr>
              <tr>
                <td
                  className="align-middle text-center italic"
                  colSpan={users.length > 0 ? 8 : 7}
                >
                  <div className="flex flex-col w-full justify-center items-center bg-secondary-1200 border border-secondary-1000 text-accent-300 py-8">
                    <ThumbUpAltOutlined style={{ fontSize: "2rem" }} />
                    <span className="py-2 text-sm ">
                      Looks like your all caught up!
                    </span>
                  </div>
                </td>
              </tr>
            </>
          ) : (
            data.map((task) => (
              <Fragment key={task.parentTask + task.date}>
                <tr
                  key={`${task.parentTask + task.date}-gap`}
                  className="h-2"
                ></tr>
                <tr
                  key={task.parentTask + task.date}
                  className=" border border-secondary-1000 text-secondary-100"
                >
                  <td className=" px-3 py-1 text-xs font-medium ">
                    {capitalizeString(task.title)}
                    <dl className="font-normal 2xl:hidden">
                      <dt className="sr-only">Date</dt>
                      <dd className="mt-1 truncate  flex items-center gap-1">
                        <span
                          className={classNames(
                            "flex items-center gap-1",
                            moment(task.date).isBefore(moment())
                              ? "text-reds-600"
                              : ""
                          )}
                        >
                          <CalendarMonthOutlined style={{ fontSize: "1rem" }} />
                          {formatDate(task.date, "MMM DD, YYYY", "utc")}
                        </span>
                      </dd>
                      <dt className="sr-only sm:hidden">Status</dt>
                      <dd className="mt-1 truncate  lg:hidden">
                        {<StatusBadge status={task.status} />}
                      </dd>
                      <dt className="sr-only">Frequency</dt>
                      <dd className="mt-1 truncate flex items-center 2xl:hidden">
                        <span className="flex items-center gap-1">
                          <RepeatOutlined style={{ fontSize: "1rem" }} />{" "}
                          {capitalizeString(task.recurrence.frequency)}
                        </span>
                      </dd>
                      <dt className="sr-only sm:hidden">Source</dt>
                      <dd className="mt-1 truncate  xl:hidden">
                        {capitalizeString(task.source)}
                      </dd>
                    </dl>
                  </td>
                  <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-0 hidden 2xl:table-cell">
                    <span
                      className={classNames(
                        "flex items-center gap-1",
                        moment(task.date).isBefore(moment())
                          ? "text-reds-600"
                          : ""
                      )}
                    >
                      <CalendarMonthOutlined style={{ fontSize: "1rem" }} />
                      {formatDate(task.date, "MMM DD, YYYY", "utc")}
                    </span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-1 text-sm  hidden lg:table-cell">
                    {<StatusBadge status={task.status} />}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1 text-sm hidden 2xl:table-cell">
                    <span className="flex items-center gap-1">
                      <RepeatOutlined style={{ fontSize: "1rem" }} />{" "}
                      {capitalizeString(task.recurrence.frequency)}
                    </span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-1 text-sm hidden xl:table-cell">
                    {capitalizeString(task.source)}
                  </td>

                  <td className="whitespace-nowrap px-3 py-1 text-sm hidden sm:table-cell max-w-40 truncate">
                    {capitalizeString(task.details)}
                  </td>
                  {users.length > 0 && (
                    <td className="whitespace-nowrap px-3 py-1 text-sm hidden sm:table-cell">
                      <AssignmentSelector
                        users={users}
                        selectedUser={task.assignedTo ? task.assignedTo : ""}
                        workOrder={task}
                        handleChange={handleAssignment}
                      />
                    </td>
                  )}
                  <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-sm  w-fit ">
                    {task.status === "complete" ? (
                      <button
                        className="w-full rounded-sm bg-secondary-100 py-1 text-xs font-semibold text-primary shadow-sm ring-1 ring-inset ring-secondary-300 hover:bg-secondary-300"
                        onClick={() => handleLogInstanceView(task)}
                      >
                        View
                      </button>
                    ) : (
                      <button
                        className={`w-full rounded-sm px-2 py-1.5 text-xs font-semibold ring-1 ring-inset ring-secondary-900
                           ${
                             !task?.readyToComplete
                               ? " bg-secondary-900 text-primary shadow-sm  cursor-not-allowed"
                               : "bg-accent-500  text-accent-1000 shadow-sm  hover:bg-accent-600"
                           }`}
                        onClick={() => handleComplete(task)}
                        disabled={!task?.readyToComplete}
                      >
                        Complete
                      </button>
                    )}
                  </td>
                </tr>
              </Fragment>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ToDoTable;
