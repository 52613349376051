import React, { Fragment, useState } from "react";
import axios from "axios";
import {
  Business,
  DeleteOutline,
  EmailOutlined,
  Person,
  PhoneAndroid,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import GenericModal from "../modals/GenericModal";

const VendorsTable = ({
  permissions,
  vendors,
  refresh,
  setRefresh,
  handleVendor,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState([]);

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return "";
    }
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    if (numericPhoneNumber.length !== 10) {
      return "Invalid phone number";
    }

    const formattedPhoneNumber = `(${numericPhoneNumber.slice(
      0,
      3
    )}) ${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6, 10)}`;

    return formattedPhoneNumber;
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleDeleteClick = (vendor) => {
    setSelectedVendor(vendor);
    setShowPopup(true);
  };

  const handleDelete = async (vendor) => {
    try {
      await axios.delete(`/api/vendor/delete/${vendor._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setRefresh(!refresh);
      setShowPopup(false);
    } catch (error) {
      console.log("Error deleting vendor:", error);
    }
  };
  return (
    <div className="w-full ">
      <div className="mt-2 flow-root">
        <div className="overflow-auto thin-scrollbar mb-14">
          <div className="inline-block min-w-full py-2 align-middle ">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="bg-secondary-1200 border border-secondary-1000 border-spacing-2">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary-100 "
                  >
                    Company
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-100"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-100"
                  >
                    Address
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-100"
                  >
                    Contact Person
                  </th>

                  {permissions == "system-admin" ||
                  permissions == "organization-admin" ? (
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-100"
                    >
                      Contract Totals
                    </th>
                  ) : null}
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-primary">
                {vendors.map((vendor) => (
                  <Fragment key={vendor._id}>
                    <tr key={`${vendor._id}-gap`} className="h-2"></tr>
                    <tr
                      key={vendor._id}
                      className=" border border-secondary-1000 text-secondary-100"
                    >
                      <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm ">
                        <div className="flex ">
                          <div className="flex-shrink-0">
                            <Business style={{ fontSize: "1rem" }} />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-secondary-100">
                              {vendor.name}
                            </div>
                            <div className="mt-1 text-secondary-400 gap-2 flex items-center">
                              <EmailOutlined style={{ fontSize: "1rem" }} />
                              {vendor.email}
                            </div>
                            <div className="mt-1 text-secondary-400 gap-2 flex items-center">
                              <PhoneAndroidOutlined
                                style={{ fontSize: "1rem" }}
                              />
                              {formatPhoneNumber(vendor.phone)}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
                        {capitalizeFirstLetter(vendor.type)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
                        {vendor.address}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
                        {vendor.contact}
                      </td>
                      {permissions == "system-admin" ||
                      permissions == "organization-admin" ? (
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
                          {vendor.contracts ? vendor.contracts : ""}
                        </td>
                      ) : null}

                      <td className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400 ">
                        <button
                          className="text-secondary-100 underline mr-2"
                          onClick={() => handleVendor(vendor)}
                        >
                          Edit
                        </button>
                        <button
                          className="text-reds-500"
                          onClick={() => handleDeleteClick(vendor)}
                        >
                          <DeleteOutline style={{ fontSize: "1rem" }} />
                        </button>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showPopup && (
        <GenericModal
          text={"Delete Vendor"}
          item={selectedVendor}
          handleFunction={handleDelete}
          showModal={showPopup}
          setShowModal={setShowPopup}
          color="reds-500"
          secondaryColor={"reds-1000"}
          buttonText="Delete"
        />
      )}
    </div>
  );
};

export default VendorsTable;
