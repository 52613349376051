import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const inputs = [
  {
    id: "name",
    label: "Company Name",
    type: "text",
    placeholder: "Company Name",
    required: true,
  },

  {
    id: "contact",
    label: "Contact Person",
    type: "text",
    placeholder: "(i.e. John Doe)",
    required: true,
  },
  /*   {
    id: "phone",
    label: "Phone #",
    type: "number",
    placeholder: "(123) 456-7890",
    required: false,
  }, */
  {
    id: "email",
    label: "Email Address",
    type: "email",
    placeholder: "email@example.com",
    required: false,
  },
/*   {
    id: "contracts",
    label: "Contract Total",
    type: "number",
    placeholder: "$x,xxxx",
    required: false,
  }, */
  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "(i.e. 123 Sesame Street Court, NY, NY)",
    required: false,
  },
];

const NewVendorForm = ({
  showPopup,
  setShowPopup,
  refresh,
  setRefresh,
  facility,
  selectedVendor,
}) => {
  const isEditForm = selectedVendor ? true : false;

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    type: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: selectedVendor ? selectedVendor.name : "",
      contact: selectedVendor ? selectedVendor.contact : "",
      phone: selectedVendor ? selectedVendor.phone : "",
      email: selectedVendor ? selectedVendor.email : "",
      contracts: selectedVendor ? selectedVendor.contracts : "",
      address: selectedVendor ? selectedVendor.address : "",
      type: selectedVendor ? selectedVendor.type : "",
    },
  });

  const [message, setMessage] = useState("");

  const onSubmit = async (data) => {
    console.log(data);
    /* If there is no vendor, create a new one */
    if (!isEditForm) {
      try {
        await axios.post(`/api/vendor/create/${facility}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setShowPopup(false);
        reset();
        setRefresh(!refresh);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await axios.put(`/api/vendor/update/${selectedVendor._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setShowPopup(false);
        reset();
        setRefresh(!refresh);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formatPhoneNumber = (value) => {
    // Remove all non-digit characters from the input value
    const cleanedValue = value.replace(/\D/g, "");

    let formattedValue = cleanedValue;

    if (cleanedValue.length > 0) {
      formattedValue = `(${cleanedValue.slice(0, 3)}`;
    }
    if (cleanedValue.length > 3) {
      formattedValue += `)${cleanedValue.slice(3, 6)}`;
    }
    if (cleanedValue.length > 6) {
      formattedValue += `-${cleanedValue.slice(6, 10)}`;
    }

    return formattedValue;
  };

  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShowPopup}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-secondary-100 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-primary">
                              New Vendor
                            </Dialog.Title>
                            <p className="text-sm text-gray-300">
                              Get started by filling in the information below to
                              create the new vendor.
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => setShowPopup(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <Close className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* Inputs */}
                        {inputs.map((input) => (
                          <div
                            key={input.id}
                            className={`space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5`}
                          >
                            <label
                              htmlFor={input.id}
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              {input.label}
                            </label>

                            <div className="sm:col-span-2">
                              <input
                                {...register(input.id, {
                                  required: input.required,
                                })}
                                type={input.type}
                                placeholder={input.placeholder}
                                className={`block w-full rounded-sm border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 `}
                              />
                              {errors[input.id] && (
                                <p className="text-xs text-reds-500">
                                  *This field is required
                                </p>
                              )}
                            </div>
                          </div>
                        ))}

                        <div
                          key={"phone"}
                          className={`space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5`}
                        >
                          <label
                            htmlFor={"phone"}
                            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >
                            Phone #
                          </label>

                          <div className="sm:col-span-2">
                            <input
                              {...register("phone")}
                              type={"text"}
                              placeholder={"(123) 456-7890"}
                              className={`block w-full rounded-sm border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6 `}
                              onChange={(e) => {
                                e.target.value = formatPhoneNumber(
                                  e.target.value
                                );
                              }}
                            />
                            {errors["phone"] && (
                              <p className="text-xs text-reds-500">
                                *This field is required
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="type"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Type
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                              {...register("type", { required: true })}
                              className="block w-full rounded-sm border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
                              id="type"
                            >
                              <option value={""} hidden>
                                Please select a value
                              </option>
                              <option value={"plumber"}>Plumber</option>
                              <option value={"HVAC"}>HVAC</option>
                              <option value={"fire"}>Fire Safety</option>
                              <option value={"electrician"}>Electrician</option>
                              <option value={"other"}>Other</option>
                            </select>
                            {errors.type && (
                              <p className="text-xs text-reds-500">
                                *This field is required
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="message">{message}</div>
                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-sm bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setShowPopup(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-sm bg-accent-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
                        >
                          {isEditForm ? "Update" : "Create"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewVendorForm;
