import React, { useState } from "react";
import "./confirmpin.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ConfirmPinPopup = ({ setshowLogoutVerification }) => {
  const [pin, setPin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const kiosk = JSON.parse(localStorage.getItem("kiosk"));
  const kioskId = kiosk._id;

  const handleVerify = () => {
    axios
      .post("/api/kiosk/verify-pin", { kioskId, pin })
      .then((response) => {
        const { isPinCorrect } = response.data;
        if (isPinCorrect) {
          localStorage.removeItem("kiosk");
          localStorage.removeItem("token");
          navigate("/kiosks/login");
        } else {
          setErrorMessage("Invalid pin. Please try again.");
          setPin(""); // Clear the input field on incorrect pin
        }
      })
      .catch((error) => {
        console.error("Error verifying PIN:", error);
        // Handle API error here
      });
  };

  return (
    <div className="bg-primary p-6 rounded-lg">
      <div>
        <label
          htmlFor="pin"
          className="block text-lg font-medium leading-6 text-gray-900"
        >
          Enter PIN
        </label>
        <div className="mt-2">
          <input
            type="password"
            name="pin"
            id="pin"
            value={pin}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-100 sm:text-sm sm:leading-6"
            placeholder="Enter your pin"
            onChange={(e) => setPin(e.target.value)}
          />
        </div>
      </div>
      <p className="text-red-500 p-2">{errorMessage}</p>
      <div className="flex w-full justify-center gap-4">
        <button
          type="button"
          className="rounded-full bg-accent-100 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600"
          onClick={handleVerify}
        >
          Verify
        </button>
        <button
          type="button"
          className="rounded-full bg-white px-3.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setshowLogoutVerification(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ConfirmPinPopup;
