import React, { useState, useEffect } from "react";
import axios from "axios";
import WorkOrderModal from "../modals/CreateWorkOrderModal";
import { Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Error } from "@mui/icons-material";

const inputs = [
  {
    id: "location",
    label: "Where is the issue located?",
    type: "text",
    placeholder: "Location",
    required: true,
  },
  {
    id: "requestedBy",
    label: "Who is making the request?",
    type: "text",
    placeholder: "Requested By",
    required: true,
  },
  {
    id: "title",
    label: "What is the request about?",
    type: "text",
    placeholder: "Request Title",
    required: true,
  },
];

const KioskFormComponent = ({ kioskId }) => {
  
  // Define your schema
  const validationSchema = yup.object().shape({
    location: yup.string().required("*This field is required"),
    requestOrigin: yup.string().required("*This field is required"),
    requestedBy: yup.string().required("*This field is required"),
    requestType: yup.string().required("*This field is required"),
    title: yup.string().required("*This field is required"),
    details: yup
      .string()
      .required("*This field is required")
      .min(10, "Minimum length of 10 characters"),
    urgency: yup.string().required("*This field is required"),
    requestType: yup.string().required("*This field is required"),
  });

  const { register, handleSubmit, formState, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      location: "",
      requestOrigin: "",
      requestedBy: "",
      requestType: "not-specified",
      title: "",
      urgency: "standard",
      status: "incomplete",
      dateReported: new Date(),
      facility: "",
      kiosk: "",
    },
  });

  const [kiosk, setKiosk] = useState({});


  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (kioskId) {
        try {
          const response = await axios.get(`/api/kiosk/single/${kioskId}`);

          setKiosk(response.data.kiosk);
          setValue("facility", response.data.kiosk.facility);
          setValue("kiosk", response.data.kiosk._id);
          setValue("requestOrigin", response.data.kiosk.location);
        } catch (error) {
          console.log("error", error);
          // navigate("/kiosks/not-found");
        }
      } else {
        const kioskData = JSON.parse(localStorage.getItem("kiosk"));
        if (kioskData) {
          setKiosk(kioskData);
          setValue("facility", kioskData.facility);
          setValue("kiosk", kioskData._id);
          setValue("requestOrigin", kioskData.location);
        }
      }
    };
    fetchData();
  }, [kioskId]);

  const [showPopUp, setShowPopup] = useState(false);

  const handleAdd = async (data) => {
    try {
      await axios.post("/api/kiosk/work-order/create", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("Successfully created work order", data);
      setShowPopup(true);
    } catch (error) {
      console.log(error);
      setErrorMessage("Error creating request. Please try again later.");
    }
  };

  const handleReset = () => {
    setShowPopup(false);
    reset();
    setValue("facility", kiosk.facility);
    setValue("kiosk", kiosk._id);
    setValue("requestOrigin", kiosk.location);
    setErrorMessage("");
  };
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  }

  return (
    <div>
      <h2 className="text-secondary-100 text-lg sm:text-2xl">
        {capitalizeFirstLetter(kiosk.name)}
      </h2>
      <h4 className="text-secondary-300 font-thin text-lg border-b sm:border-secondary-900">
        {capitalizeFirstLetter(kiosk.location)}
      </h4>

      <form onSubmit={handleSubmit(handleAdd)}>
        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
          {inputs.map((input) => (
            <div className="sm:col-span-3" key={input.id}>
              <div className="mt-1">
                <div key={input.id}>
                  <label
                    htmlFor={input.id}
                    className="block text-sm font-medium leading-2 text-secondary-100"
                  >
                    {input.label}
                  </label>
                  <input
                    {...register(input.id, { required: true })}
                    className="mt-2 block w-full rounded-md border-0 py-1 text-secondary-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-secondary-500 focus:ring-2 focus:ring-inset focus:ring-accent-400 sm:text-sm sm:leading-6"
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                  />
                  {formState.errors[input.id] && (
                    <div className=" text-xs text-reds-600">
                      {formState.errors[input.id].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          <div className="sm:col-span-4">
            <label
              htmlFor="Request Type"
              className="block text-sm font-medium leading-6 text-secondary-100"
            >
              What kind of request is this?
            </label>
            <div className="mt-2">
              <select
                id="requestType"
                name="requestType"
                className="block w-full rounded-md border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-1000 placeholder:text-secondary-500 focus:ring-2 focus:ring-inset focus:ring-accent-400 sm:text-sm sm:leading-6"
                {...register("requestType")}
              >
                <option value={"not-specified"}>Please select a value</option>
                <option value={"Electrical"}>Electrical</option>
                <option value={"Plumbing"}>Plumbing</option>
                <option value={"HVAC"}>HVAC</option>
                <option value={"Other"}>Other</option>
              </select>
            </div>
            {formState.errors?.requestType && (
              <div className="py-1 text-xs text-reds-600">
                *This field is required.
              </div>
            )}
          </div>
          <div className="col-span-full">
            <label
              htmlFor="about"
              className="block text-sm font-medium leading-6 text-secondary-100"
            >
              Description
            </label>
            <div className="mt-2">
              <p className="mt-3 text-sm leading-6 text-secondary-100">
                Write a brief description regarding the request.
              </p>
              <textarea
                rows={3}
                className="resize-none block w-full rounded-md border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-1000 placeholder:text-secondary-500 focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
                placeholder="Enter details here"
                id="details"
                {...register("details")}
              />
            </div>
            {formState.errors?.details && (
              <div className="py-1 text-xs text-reds-600">
                {formState.errors.details.message}
              </div>
            )}
          </div>
          <div className="sm:col-span-4">
            <label
              htmlFor="Urgency"
              className="block text-sm font-medium leading-6 text-secondary-100"
            >
              How urgent is this request?
            </label>
            <div className="mt-2">
              <select
                id="urgency"
                name="urgency"
                className="block w-full rounded-md border-0 py-1.5 text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-1000 placeholder:text-secondary-500 focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6"
                {...register("urgency")}
              >
                <option value={"standard"}>Standard</option>
                <option value={"urgent"}>Urgent</option>
              </select>
            </div>
          </div>
        </div>
        <button
          className={`mt-6 mx-auto w-full text-center justify-center rounded-md px-3.5 py-2 text-md font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100 ${
            formState.isSubmitting
              ? "bg-gray-500 hover:bg-gray-400"
              : "bg-accent-500 hover:bg-accent-300"
          }`}
          type="submit"
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting ? "Submitting..." : "Create"}
        </button>
        {errorMessage && (
          <span className="rounded-sm py-1 px-2 bg-red-50 text-red-500 text-xs">
            <Error style={{ fontSize: "1rem", marginRight: ".2rem" }} />
            {errorMessage}
          </span>
        )}
      </form>

      <WorkOrderModal
        showPopUp={showPopUp}
        setShowPopup={setShowPopup}
        handleReset={handleReset}
      />
    </div>
  );
};

export default KioskFormComponent;
