import React, { useState, useRef, useContext } from "react";
import axios from "axios";
import { FacilityContext } from "../../context/FacilityContext";
import { AuthContext } from "../../context/AuthContext";
import { Close } from "@mui/icons-material";

function UploadImage({ setShowFileAdd, fileType }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null);

  const { selectedFacility } = useContext(FacilityContext);
  const { currentUser, dispatch } = useContext(AuthContext);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.size <= 1 * 1024 * 1024) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setMessage("Error uploading. File must be below 1MB");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setSelectedFile(droppedFile);
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const handleUpload = async () => {
    setShowFileAdd(false);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("avatar", selectedFile);
      const userId = currentUser._id;
      formData.append("userId", userId);

      try {
        await axios
          .put(`/api/settings/upload/avatar/${userId}`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            const link = response.data.uri;
            const updatedUser = { ...currentUser, avatar: link };
            localStorage.setItem("user", JSON.stringify(updatedUser));
            dispatch({ type: "UPDATE_USER", payload: updatedUser });
          })
          .catch((error) => {
            console.error("Error updating user:", error);
          });
      } catch (error) {
        console.error("Error uploading avatar:", error);
      }
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-secondary-500 opacity-50"></div>
      <div className="relative bg-primary p-4 rounded-sm flex flex-col items-center">
        <div
          className="flex w-full mb-3 text-secondary-500 justify-end cursor-pointer hover:text-secondary-400"
          onClick={() => setShowFileAdd(false)}
        >
          <Close />
        </div>
        <div
          className="relative block w-full rounded-lg border-2 border-dashed border-secondary-1000 p-12 text-center hover:border-secondary-900 focus:outline-none focus:ring-2 focus:ring-accent-100 focus:ring-offset-2"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onClick={openFileInput}
        >
          <svg
            className="mx-auto h-12 w-12 text-secondary-100"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
            />
          </svg>
          {selectedFile ? (
            <p className="mt-2 block text-sm font-semibold text-secondary-100">
              Selected File: {selectedFile.name}
            </p>
          ) : (
            <p className="mt-2 block text-sm font-semibold text-secondary-100">
              Drag and drop a {fileType} here or click to select
            </p>
          )}
        </div>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <div className="flex gap-2 w-full">
          <button
            type="button"
            className="flex-1 rounded-sm bg-secondary-1000 mt-6 px-5 py-1 text-sm font-semibold text-secondary-100 shadow-sm hover:bg-secondary-1100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
            onClick={() => setShowFileAdd(false)}
          >
            Cancel
          </button>
          <button
            className={`flex-1 rounded-sm  mt-6 px-5 py-1 text-sm shadow-sm font-semibold ${
              selectedFile
                ? "bg-secondary-100 text-primary  hover:bg-secondary-300"
                : "bg-secondary-500 text-primary  "
            } cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100`}
            onClick={handleUpload}
            disabled={!selectedFile}
          >
            Upload
          </button>
        </div>
        <span>{message}</span>
      </div>
    </div>
  );
}

export default UploadImage;
