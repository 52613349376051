import React, { Fragment, useEffect, useState } from "react";
import { ChevronRight, ExpandMore } from "@mui/icons-material";

const LogsList = ({ data, setSelectedLog }) => {
  const capitalizeString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="">
      <div className="n mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr
              className="bg-secondary-1200 border border-secondary-1000 rounded-sm"
              key={"header"}
            >
              <th
                scope="col"
                className="py-3.5 group pl-4 pr-2 text-left text-sm font-medium text-secondary-100 cursor-pointer"
              >
                Log Title
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Frequency
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Log Type
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Data Source
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                <span className="sr-only">View</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((log) => (
                <Fragment key={log._id}>
                  <tr key={`${log._id}-gap`} className="h-2"></tr>
                  <tr
                    key={`${log._id}-title`}
                    className="border border-secondary-1000 rounded-sm"
                  >
                    <td className=" py-1 pl-4 pr-2 text-sm font-medium text-secondary-100 ">
                      {log.title}
                      <dl className="font-normal lg:hidden">
                        <dt className="sr-only">Frequency</dt>
                        <dd className="mt-1 truncate text-gray-700">
                          {capitalizeString(log.recurrence.frequency)}
                        </dd>
                        <dt className="sr-only sm:hidden">Type</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {capitalizeString(log.category)}
                        </dd>
                      </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {capitalizeString(log.recurrence.frequency)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {capitalizeString(log.category)}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {capitalizeString(log.source)}
                    </td>
                    <td className="py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => setSelectedLog(log)}
                        className="text-accent-500 hover:text-accent-400 underline"
                      >
                        View
                        <span className="sr-only">
                          , {capitalizeString(log.title)}
                        </span>
                      </button>
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className="p-4 text-center italic font-medium text-text"
                >
                  No Data Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LogsList;
