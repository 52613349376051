import { Fragment, useEffect, useRef } from "react";
import moment from "moment-timezone";

function generateWeek(date) {
  const weekStart = moment(date).startOf("week");
  const weekEnd = moment(date).endOf("week");

  const days = [];

  for (
    let date = weekStart.clone();
    date.isSameOrBefore(weekEnd);
    date.add(1, "day")
  ) {
    const isToday = date.isSame(moment(), "day");
    const isSelected = date.isSame(moment(date), "day");

    days.push({
      date: date.format("YYYY-MM-DD"),
      isCurrentMonth: date.isSame(moment(), "month"),
      isToday: isToday,
      isSelected: isSelected,
    });
  }

  return days;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function WeekView({ dateParams }) {
  const { currentDate } = dateParams;
  const days = generateWeek(currentDate);

  return (
    <div className="flex h-full flex-col">
      <div className="isolate flex flex-auto flex-col overflow-auto bg-white">
        <div
          style={{ width: "165%" }}
          className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
        >
          <div className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
            <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
              {days.map((day, index) => (
                <button
                  key={index}
                  type="button"
                  className="flex flex-col items-center pb-3 pt-2"
                >
                  {moment(day.date).format("ddd")}{" "}
                  <span
                    className={classNames(
                      "mt-1 flex h-8 w-8 items-center justify-center font-semibold",
                      day.isToday
                        ? "text-white bg-accent-200 rounded-full"
                        : "text-gray-900"
                    )}
                  >
                    {moment(day.date).format("D")}
                  </span>
                </button>
              ))}
            </div>

            <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
              <div className="col-end-1 w-14" />
              {days.map((day, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center py-3"
                >
                  <span>
                    {moment(day.date).format("ddd")}{" "}
                    <span
                      className={classNames(
                        "items-center justify-center font-semibold",
                        day.isToday
                          ? "text-white bg-accent-200 rounded-full h-8 w-8 flex items-center justify-center"
                          : "text-gray-900"
                      )}
                    >
                      {moment(day.date).format("D")}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-auto min-h-96">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{
                  gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
                }}
              >
                <li className="relative mt-px flex sm:col-start-1 bg-blue-50 rounded-lg  p-2">
                  <a
                    href="#"
                    className="group inset-1 flex flex-col  text-xs leading-5 hover:bg-blue-100"
                  >
                    <p className="order-1 font-semibold text-gray-700">
                      Breakfast
                    </p>
                  </a>
                </li>
                <li className="relative mt-px flex sm:col-start-3 bg-blue-50 rounded-lg  p-2">
                  <a
                    href="#"
                    className="group inset-1 flex flex-col  text-xs leading-5 hover:bg-blue-100"
                  >
                    <p className="order-1 font-semibold text-gray-700">
                      Breakfast
                    </p>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
