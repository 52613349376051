import React, { Fragment, useContext, useState } from "react";
import axios from "axios";
import {
  EditNote,
  SearchOffOutlined,
  ThumbUpAltOutlined,
  WarningOutlined,
  ZoomIn,
} from "@mui/icons-material";
import TaskNotes from "../modals/TaskNotes";
import IncompleteNotes from "../../components/incompletenotes/IncompleteNotes";
import { AuthContext } from "../../context/AuthContext";
import StatusBadge from "../general-ui/StatusBadges";
import { useNavigate } from "react-router-dom";
import { capitalizeString } from "../../utils/FormatFunctions";

const ToDoTableMaint = ({
  data,
  setData,
  user,
  selectedWorkOrder,
  setSelectedWorkOrder,
  setShowNotes,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [showNoteInput, setShowNoteInput] = useState(false);
  const [note, setNote] = useState("");

  const handleView = (workOrder) => {
    navigate(`/work-orders/instance/${workOrder._id}`, {
      state: { workOrder },
    });
  };

  // Function to compare values for sorting
  const compareValues = (value1, value2) => {
    if (value1 < value2) return -1;
    if (value1 > value2) return 1;
    return 0;
  };

  // Function to sort the filtered data array
  const sortedData = [...data].sort((a, b) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];
    const sortOrder = sortDirection === "asc" ? 1 : -1;

    return compareValues(valueA, valueB) * sortOrder;
  });

  const handleNotes = (task) => {
    setSelectedWorkOrder(task);
    setShowNotes(true);
  };

  const handleMarkComplete = async (workOrder) => {
    const workOrderId = workOrder._id;
    try {
      const response = await axios.put(
        `/api/employee-tasks/complete/${workOrderId}`,
        { completedBy: user },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Work order marked as complete:", response.data);
        const updatedWorkOrders = data.map((wo) => {
          if (wo._id === workOrder._id) {
            const updatedHistory = [
              ...wo.history,
              {
                completedBy: currentUser,
                timestamp: new Date(),
                action: "complete",
              },
            ];

            return {
              ...wo,
              status: "complete",
              history: updatedHistory,
            };
          }
          return wo;
        });
        setData(updatedWorkOrders);
      }
    } catch (error) {
      console.error("Error marking work order as complete:", error);
    }
  };

  const handleMarkIncomplete = async (workOrder) => {
    const workOrderId = workOrder._id;
    try {
      const response = await axios.put(
        `/api/employee-tasks/incomplete/${workOrderId}`,
        { reopenedBy: user },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Work order marked as complete:", response.data);
        const updatedWorkOrders = data.map((wo) => {
          if (wo._id === workOrder._id) {
            return {
              ...wo,
              status: "incomplete",
              history: [
                ...wo.history,
                {
                  completedBy: currentUser,
                  timestamp: new Date(),
                  action: "incomplete",
                },
              ],
            };
          }
          return wo;
        });
        setData(updatedWorkOrders);
      }
    } catch (error) {
      console.error("Error marking work order as complete:", error);
    }
  };

  const handleMarkUnable = async (event, workOrder) => {
    event.preventDefault();

    if (!note || note.trim() === "") {
      console.error("Justification required.");
      return; // Exit the function without making the request
    }

    const workOrderId = workOrder._id;

    try {
      const response = await axios.put(
        `/api/employee-tasks/unable/${workOrderId}`,
        {
          note: note,
          user: currentUser._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedWorkOrders = data.map((wo) => {
          console.log("wo", wo);
          if (wo._id === workOrder._id) {
            const updatedHistory = [
              ...wo.history,
              {
                completedBy: currentUser,
                timestamp: new Date(),
                action: "unable",
              },
            ];

            return {
              ...wo,
              status: "unable",
              history: updatedHistory,
            };
          }
          return wo;
        });
        setData(updatedWorkOrders);
        setShowNoteInput(false);
        setNote("");
      }
    } catch (error) {
      console.error("Error marking work order as unable:", error);
    }
  };

  

  const calculateDays = (date) => {
    const startDate = new Date(date);
    const today = new Date();

    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    let workDays = 0;

    const isWeekend = (date) => {
      const day = date.getDay();
      return day === 0 || day === 6;
    };

    while (startDate < today) {
      if (!isWeekend(startDate)) {
        workDays++;
      }
      startDate.setTime(startDate.getTime() + millisecondsPerDay);
    }

    return workDays;
  };

  const getStatusClasses = (status) => {
    switch (status) {
      case "complete":
        return "bg-accent-1000 text-accent-200 border-accent-200 ring-accent-1000 focus:ring-accent-700";
      case "incomplete":
        return "bg-secondary-1100 text-secondary-100 border-secondary-100 ring-secondary-1000  focus:ring-secondary-700";
      case "unable":
        return "bg-reds-1100 text-reds-500 border-reds-500 ring-reds-1000  focus:ring-reds-700";
      default:
        return "bg-white text-black";
    }
  };

  return (
    <div>
      <table className="min-w-full">
        <thead>
          <tr
            key={"header"}
            className="bg-secondary-1200 border border-secondary-1000 rounded-sm"
          >
            <th
              scope="col"
              className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 "
            >
              Title
            </th>
            <th
              scope="col"
              className="hidden px-3 py-1.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Reported
            </th>
            <th
              scope="col"
              className="hidden px-3 py-1.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Urgency
            </th>
            <th
              scope="col"
              className=" px-3 py-1.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Notes
            </th>
            <th
              scope="col"
              className=" px-3 py-1.5 text-left text-sm font-semibold text-gray-900 table-cell"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-3 py-1.5 text-left text-sm font-semibold text-gray-900"
            ></th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {sortedData.length === 0 ? (
            <>
              <tr key={`gap`} className="h-2"></tr>
              <tr>
                <td
                  className="align-middle text-center italic"
                  colSpan={user.length > 0 ? 8 : 7}
                >
                  <div className="flex flex-col w-full justify-center items-center bg-secondary-1200 border border-secondary-1000 text-secondary-300 py-8">
                    <ThumbUpAltOutlined style={{ fontSize: "2rem" }} />
                    <span className="py-2 text-sm">
                      No work orders outstanding
                    </span>
                  </div>
                </td>
              </tr>
            </>
          ) : (
            sortedData.map((task) => (
              <Fragment key={task._id}>
                <tr key={`${task._id}-gap`} className="h-2"></tr>
                <tr
                  key={task._id}
                  className="border border-secondary-1000 rounded-sm"
                >
                  <td
                    className="whitespace-nowrap py-1 pl-4 pr-2 text-sm font-medium text-secondary-100 "
                    style={{
                      maxWidth: "100px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {capitalizeString(task.title)}
                    <dl className="">
                      <dt className="sr-only sm:hidden">Days since reported</dt>
                      <dd className="mt-1 truncate text-secondary-100 font-light">
                        ID {task?.shortId}
                      </dd>

                      <dt className="sr-only sm:hidden">Days since reported</dt>
                      <dd className="mt-1 truncate text-secondary-100 sm:hidden font-light">
                        {calculateDays(task.dateReported)} days
                      </dd>

                      <dt className="sr-only sm:hidden">urgency</dt>
                      <dd className="mt-1 text-secondary-100 sm:hidden">
                        <StatusBadge status={task.urgency} />
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-left text-sm text-gray-500 sm:table-cell">
                    {calculateDays(task.dateReported)} days ago
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {capitalizeString(task.urgency)}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-0 sm:table-cell">
                    <button
                      className="rounded-sm bg-white p-1 text-xs font-semibold text-secondary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => handleNotes(task)}
                    >
                      <EditNote style={{ fontSize: "1.2rem" }} />
                    </button>
                  </td>
                  <td className="gap-4 py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-0">
                    <select
                      className={`rounded-sm py-1 text-sm font-medium border ring-1 ring-inset focus:z-10  focus:border-transparent ${getStatusClasses(
                        task.status
                      )}`}
                      value={task.status}
                      onChange={(e) => {
                        switch (e.target.value) {
                          case "complete":
                            handleMarkComplete(task);
                            break;
                          case "incomplete":
                            handleMarkIncomplete(task);
                            break;
                          case "unable":
                            setSelectedWorkOrder(task);
                            setShowNoteInput(true);
                            break;
                          default:
                            break;
                        }
                      }}
                    >
                      <option value="complete">Complete</option>
                      <option value="incomplete">Incomplete</option>
                      <option
                        value="unable"
                        disabled={task.status === "unable"}
                      >
                        Unable
                      </option>
                    </select>
                  </td>
                  <td className="py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-0">
                    <button
                      onClick={() => handleView(task)}
                      className="text-xs font-semibold text-secondary-100 underline "
                    >
                      View
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))
          )}
        </tbody>
      </table>

      {showNoteInput && (
        <IncompleteNotes
          note={note}
          setNote={setNote}
          selectedTask={selectedWorkOrder}
          showNotes={showNoteInput}
          setShowNotes={setShowNoteInput}
          handleMarkUnable={handleMarkUnable}
        />
      )}
    </div>
  );
};

export default ToDoTableMaint;
