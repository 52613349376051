import moment from "moment-timezone";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function generateYear(currentDate) {
  const months = [];

  for (let i = 0; i < 12; i++) {
    const monthStart = moment(currentDate).month(i).startOf("month");
    const monthEnd = moment(currentDate).month(i).endOf("month");

    const days = [];

    // Add padding days at the beginning of the month until the first day of the week
    for (
      let date = monthStart.clone().startOf("week");
      date.isBefore(monthStart, "day");
      date.add(1, "day")
    ) {
      days.push({
        date: date.format("YYYY-MM-DD"),
        isCurrentMonth: false,
        isToday: false,
      });
    }

    // Add actual days of the month
    for (
      let date = monthStart.clone();
      date.isSameOrBefore(monthEnd);
      date.add(1, "day")
    ) {
      const isToday = date.isSame(moment(), "day");

      days.push({
        date: date.format("YYYY-MM-DD"),
        isCurrentMonth: true,
        isToday: isToday,
      });
    }

    // Add additional padding days at the end of the month until the total number of days is at least 42
    for (
      let date = monthEnd.clone().add(1, "day");
      days.length < 42;
      date.add(1, "day")
    ) {
      days.push({
        date: date.format("YYYY-MM-DD"),
        isCurrentMonth: false,
        isToday: false,
      });
    }

    months.push({
      name: monthStart.format("MMMM"),
      days,
    });
  }

  return months;
}

export default function YearView({ dateParams, setDateParams }) {
  const { currentDate } = dateParams;

  const months = generateYear(currentDate);

  return (
    <div>
      <div className="bg-white">
        <div className="mx-auto max-h-96 overflow-y-auto grid max-w-3xl grid-cols-1 gap-x-8 gap-y-16 px-4 py-16 sm:grid-cols-2 sm:px-6 xl:max-w-none xl:grid-cols-3 xl:px-8 2xl:grid-cols-4">
          {months.map((month) => (
            <section key={month.name} className="text-center">
              <h2 className="text-sm font-semibold text-gray-900">
                {month.name}
              </h2>
              <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
              </div>
              <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                {month.days.map((day, dayIdx) => (
                  <button
                    key={`${day.date}-${dayIdx}`}
                    type="button"
                    className={classNames(
                      day.isCurrentMonth
                        ? "bg-white text-gray-900"
                        : "bg-gray-50 text-gray-400",
                      dayIdx === 0 && "rounded-tl-lg",
                      dayIdx === 6 && "rounded-tr-lg",
                      dayIdx === month.days.length - 7 && "rounded-bl-lg",
                      dayIdx === month.days.length - 1 && "rounded-br-lg",
                      "py-1.5 hover:bg-gray-100 focus:z-10"
                    )}
                    onClick={() => {
                      setDateParams((prev) => ({
                        ...prev,
                        currentDate: day.date,
                        currentView: "day",
                      }));
                    }}
                  >
                    <time
                      dateTime={day.date}
                      className={classNames(
                        day.isToday && "bg-accent-200 font-semibold text-white",
                        "mx-auto flex h-7 w-7 items-center justify-center rounded-full"
                      )}
                    >
                      {day.date.split("-").pop().replace(/^0/, "")}
                    </time>
                  </button>
                ))}
              </div>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
}
