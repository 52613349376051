import { useNavigate } from "react-router-dom";
import ProgressWheel from "../data-display/ProgressWheel";
const allCategories = [
  {
    name: "Incomplete",
    color: "bg-secondary-900",
    chart: ["work-orders", "recurring"],
    //routeParam: "/incomplete"
  },
  {
    name: "Complete",
    color: "bg-accent-500",
    chart: ["work-orders", "recurring"],
    //routeParam: "/complete"
  },
  {
    chart: ["work-orders", "recurring"],
    name: "Closed",
    color: "bg-accent-200",
    routeParam: "/logs",
  },
  {
    chart: ["work-orders"],
    name: "Unable",
    color: "bg-reds-500",
    //routeParam: "/unable"
  },
  {
    chart: ["recurring"],
    name: "Overdue",
    color: "bg-reds-500",
    //routeParam: "/tasks"
  },
];

export default function HomeChart({ recurringTable, workOrders }) {
  const navigate = useNavigate();
  const charts = [
    {
      title: "Recurring Task Progress",
      type: "recurring",
      href: "/tasks",
      data: recurringTable,
      text: "recurring tasks",
    },
    {
      title: "Work Order Progress",
      type: "work-orders",
      href: "/work-orders",
      data: workOrders,
      text: "work orders",
    },
  ];

  return (
    <div className="overflow-hidden sm:grid sm:grid-cols-2 gap-4 ">
      {charts.map((chart) => {
        const categories = allCategories.filter((category) =>
          category.chart.includes(chart.type)
        );
        return (
          <div
            key={chart.title}
            className="border border-gray-200 rounded-sm bg-primary"
          >
            <h3 className="text-base font-medium leading-6 text-secondary-100 ml-4 mt-4">
              {chart.title}
            </h3>

            <ProgressWheel type={chart.type} values={chart.data} />
            <ul
              role="list"
              className="flex w-full justify-evenly cursor-pointer"
            >
              {categories.map((category) => (
                <li
                  key={category.name}
                  onClick={() => {
                    if (category.routeParam === "/logs") {
                      navigate("/logs");
                      return;
                    } else {
                      let filter = category.name.toLowerCase();

                      navigate(
                        `${chart.href}?filters=%7B"status"%3A"${filter}"%7D`
                      );
                    }
                  }}
                  className="flex flex-col items-center w-full h-full py-4 outline outline-1 outline-secondary-1000"
                >
                  <div className={`h-2 w-2 ${category.color} rounded-full`} />
                  <p className="text-sm  leading-8 text-secondary-500">
                    {category.name}
                  </p>
                  <span className="mt-1 truncate text-xs leading-none font-semibold text-secondary-100">
                    {chart.data[category.name.toLowerCase()]}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
