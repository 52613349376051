import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { useLocation, useSearchParams } from "react-router-dom";

// Create the context
export const WorkOrderContext = createContext();

// Create the provider component
export const WorkOrderProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = searchParams.get("filters") || "";
  const page = searchParams.get("page") || 1;
  const pageSize = searchParams.get("pageSize") || 10;
  const sortColumn = searchParams.get("sortColumn") || "dateReported";
  const sortOrder = searchParams.get("sortOrder") || 1;

  const [workOrders, setWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const updateSearchParams = (newParams) => {
    setSearchParams(
      {
        ...Object.fromEntries(searchParams.entries()), // Preserve existing params
        ...newParams,
      },
      { replace: true }
    );
  };

  const fetchWorkOrders = useCallback(
    async (selectedFacility) => {
      try {
        setIsLoading(true);
        const filterObject = filters ? JSON.parse(filters) : {};

        if (!selectedFacility) {
          return;
        }

        const token = localStorage.getItem("token");
        const queryString = new URLSearchParams({
          ...filterObject,
          page,
          pageSize,
          sortColumn,
          sortOrder,
        }).toString();
        const response = await axios.get(
          `/api/work-order/list/${selectedFacility}?${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.workOrders === undefined) {
          return;
        }
        setWorkOrders(response.data.workOrders || []);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching work orders:", error);
        setIsLoading(false);
      }
    },
    [isLoading, searchParams]
  );

  const fetchAdjacentWorkOrder = useCallback(
    async (direction, selectedFacility) => {
      if (!selectedWorkOrder) return;
      const workOrderId = selectedWorkOrder._id;
      try {
        const token = localStorage.getItem("token");
        const filterObject = filters ? JSON.parse(filters) : {};
        const queryString = new URLSearchParams({
          workOrderId,
          direction,
          sortColumn,
          sortOrder,
          ...filterObject,
        }).toString();
        const response = await axios.get(
          `/api/work-order/next/${selectedFacility}?${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          setSelectedWorkOrder(response.data);
        } else {
          console.log("No more work orders in that direction");
        }
      } catch (error) {
        console.error("Error loading work order", error.response);
      }
    },
    [selectedWorkOrder]
  );

  const updateWorkOrderInContext = (updatedWorkOrder) => {
    setWorkOrders((prevWorkOrders) =>
      prevWorkOrders.map((wo) =>
        wo._id === updatedWorkOrder._id ? updatedWorkOrder : wo
      )
    );
    setSelectedWorkOrder(updatedWorkOrder);
  };

  return (
    <WorkOrderContext.Provider
      value={{
        workOrders,
        setWorkOrders,
        totalPages,
        isLoading,
        searchParams,
        updateSearchParams,
        fetchWorkOrders,
        selectedWorkOrder,
        setSelectedWorkOrder,
        updateWorkOrderInContext,
        fetchAdjacentWorkOrder,
      }}
    >
      {children}
    </WorkOrderContext.Provider>
  );
};
