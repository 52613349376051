import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./context/AuthContext";
import { FacilityProvider } from "./context/FacilityContext";
import { NotificationContextProvider } from "./context/PushNotificationContext";
import { WorkOrderProvider } from "./context/WorkOrderContext";
import { BrowserRouter } from "react-router-dom";
import { RecurringTaskProvider } from "./context/RecurringTaskContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NotificationContextProvider>
        <AuthContextProvider>
          <FacilityProvider>
            <WorkOrderProvider>
              <RecurringTaskProvider>
                <App />
              </RecurringTaskProvider>
            </WorkOrderProvider>
          </FacilityProvider>
        </AuthContextProvider>
      </NotificationContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
