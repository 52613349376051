import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FacilityContext } from "../../context/FacilityContext";
import WorkOrdersTable from "../../updatedcomponents/tables/WorkOrdersTable";
import NewWorkOrder from "../../updatedcomponents/forms/WorkOrderForm";
import WorkOrderListPrint from "../../components/workorderprint/WorkOrderListPrint";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Add,
  FilterAltOutlined,
  FilterList,
  PrintOutlined,
} from "@mui/icons-material";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import TableFilter from "../../updatedcomponents/table-components/TableFilter";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import useFetchData from "../../hooks/useFetchData";
import { WorkOrderContext } from "../../context/WorkOrderContext";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";

const WorkOrders = () => {
  const emptyFilters = {
    title: "",
    details: "",
    id: "",
    status: "",
    requestedBy: "",
    requestOrigin: "",
    requestType: "",
    urgency: "",
    assignedTo: "",
  };
  const {
    workOrders,
    setWorkOrders,
    totalPages,
    isLoading,
    searchParams,
    updateSearchParams,
    fetchWorkOrders,
    setSelectedWorkOrder,
  } = useContext(WorkOrderContext);

  const [filters, setFilters] = useState(() => {
    const filtersString = searchParams.get("filters");
    return filtersString ? JSON.parse(filtersString) : emptyFilters;
  });

  // const { filter } = useParams();
  const { selectedFacility } = useContext(FacilityContext);

  const [refresh, setRefresh] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPrintWorkOrders, setShowPrintWorkOrders] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  useEffect(() => {
    if (!selectedFacility) return;
    fetchWorkOrders(selectedFacility);
    setFilters(JSON.parse(searchParams.get("filters")) ?? emptyFilters);
    updateActiveFilterCount();
  }, [searchParams, selectedFacility]);

  const updateActiveFilterCount = () => {
    const currentFilters = JSON.parse(searchParams.get("filters") || "{}");
    const count = Object.values(currentFilters).filter(
      (value) => value !== ""
    ).length;
    setActiveFilterCount(count);
  };

  // useEffect(() => {
  //   if (!selectedFacility) return;
  //   fetchWorkOrders(selectedFacility);
  // }, [searchParams, selectedFacility]);

  //Endpoint for fetching maintenace workers
  const { data: usersData, error: usersError } = useFetchData(
    selectedFacility ? `/api/work-order/users/${selectedFacility}` : null,
    {},
    [selectedFacility],
    [] // default value for users data
  );
  const users = usersData || [];

  if (usersError) {
    console.error("Error fetching users:", usersError);
  }

  //Endpoint for fetching kiosk locations
  const { data: kiosksData, error: kiosksError } = useFetchData(
    selectedFacility ? `/api/work-order/kiosks/${selectedFacility}` : null,
    {},
    [selectedFacility],
    [] // default value for kiosks data
  );
  const kiosks = kiosksData || [];

  if (kiosksError) {
    console.error("Error fetching kiosks:", kiosksError);
  }

  const resetFilters = () => {
    updateSearchParams({ filters: JSON.stringify(emptyFilters) });
  };

  const handleNewWorkOrder = () => {
    setShowPopup(true);
  };
  const handleShowPrint = () => {
    setShowPrintWorkOrders(true);
  };

  const navigate = useNavigate();

  const handleView = (workOrder) => {
    if (workOrder && workOrder._id) {
      setSelectedWorkOrder(workOrder);
      navigate(`/work-orders/instance/${workOrder._id}?${searchParams}`);
    } else {
      console.error("Error viewing work order: work order not, ", workOrder);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const currentFilters = JSON.parse(searchParams.get("filters") || "{}");
    const updatedFilters = { ...currentFilters, [name]: value };
    updateSearchParams({ filters: JSON.stringify(updatedFilters) });
  };

  const page = searchParams.get("page") || 1;
  const pageSize = searchParams.get("pageSize") || 10;

  const filterOptions = [
    { name: "title", label: "Title", type: "text" },
    { name: "details", label: "Details", type: "text" },
    { name: "id", label: "ID", type: "text" },
    {
      name: "status",
      label: "Status",
      type: "selector",
      options: [
        { value: "", label: "All Statuses" },
        { value: "incomplete", label: "Incomplete" },
        { value: "unable", label: "Unable to complete" },
        { value: "complete", label: "Complete" },
      ],
    },
    { name: "requestedBy", label: "Requested By", type: "text" },
    {
      name: "requestOrigin",
      label: "Request Origin",
      type: "selector",
      options: [
        { value: "", label: "All Origins" },
        ...kiosks.map((kiosk) => ({
          value: kiosk.location,
          label: kiosk.location,
        })),
        { value: "user-login", label: "User-login" },
      ],
    },
    {
      name: "requestType",
      label: "Request Type",
      type: "selector",
      options: [
        { value: "", label: "All Types" },
        { value: "Electrical", label: "Electrical" },
        { value: "Plumbing", label: "Plumbing" },
        { value: "HVAC", label: "HVAC" },
        { value: "not-specified", label: "Not Specified" },
        { value: "Other", label: "Other" },
      ],
    },
    {
      name: "urgency",
      label: "Urgency",
      type: "selector",
      options: [
        { value: "", label: "All Urgencies" },
        { value: "standard", label: "Standard" },
        { value: "urgent", label: "Urgent" },
      ],
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      type: "selector",
      options: [
        { value: "", label: "All Assignments" },
        { value: "unassigned", label: "Unassigned" },
        ...users.map((user) => ({
          value: user._id,
          label: `${user.firstname} ${user.lastname}`,
        })),
      ],
    },
  ];
  const handlePageSizeChange = ({ pageSize, page }) => {
    updateSearchParams({ pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage) => {
    updateSearchParams({ page: newPage.toString() });
  };

  const content = (
    <div className="flex flex-col h-[calc(100vh-132px)] sm:h-[calc(100vh-132px)]">
      <PageHeader
        title={"Work Orders"}
        subtitle={"Work orders that have not been closed out yet."}
        buttons={
          <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly mx-6 sm:gap-4 gap-2">
            <button
              type="button"
              className="flex items-center justify-center gap-2 rounded-sm bg-primary px-4 py-1 text-center text-sm font-semibold text-secondary outline outline-1 outline-secondary-100 border shadow-sm hover:bg-gray-50"
              onClick={handleShowPrint}
            >
              <PrintOutlined style={{ fontSize: "1rem" }} />
              <span className="text-secondary-100">Work Orders</span>
            </button>
            <button
              type="button"
              className="flex items-center justify-center gap-2 rounded-sm bg-secondary-100 px-4 py-1 text-center text-sm font-semibold text-primary border-transparent border shadow-sm hover:bg-secondary-200"
              onClick={handleNewWorkOrder}
            >
              <Add style={{ fontSize: "1rem" }} />
              <span>Work Order</span>
            </button>
          </div>
        }
      />
      <div className=" px-4 sm:px-6 lg:px-8 sm:overflow-y-scroll pb-16 sm:pb-2  thin-scrollbar">
        <button
          className="mt-4 flex gap-1 bg-secondary-1100 text-secondary-100 items-center px-2 py-1.5 rounded-sm"
          onClick={() => setShowFilterModal(true)}
        >
          <FilterAltOutlined style={{ fontSize: "1rem" }} />
          <span className="text-sm font-semibold">Advanced Filters</span>
          {activeFilterCount > 0 && (
            <span className="ml-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-black rounded-full">
              {activeFilterCount}
            </span>
          )}
        </button>
        <FilterModal
          show={showFilterModal}
          setShow={setShowFilterModal}
          filters={JSON.parse(searchParams.get("filters") || "{}")}
          setFilters={(newFilters) => {
            updateSearchParams({
              filters: JSON.stringify(newFilters),
              page: 1,
            });
            updateActiveFilterCount();
          }}
          filterOptions={filterOptions}
          onApply={(newFilters) => {
            updateSearchParams({
              filters: JSON.stringify(newFilters),
              page: 1,
            });
            updateActiveFilterCount();
          }}
          onReset={(emptyFilters) => {
            updateSearchParams({
              filters: JSON.stringify(emptyFilters),
              page: 1,
            });
            updateActiveFilterCount();
          }}
        />
        {/* This TableFilter component will be hidden on screens smaller than 1536px (2xl) */}
        {/* <div className="hidden 2xl:block">
          <TableFilter
            filterOptions={filters}
            origins={kiosks}
            users={users}
            handleFilterChange={handleFilterChange}
            resetFilters={resetFilters}
          />
        </div> */}
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className="mt-2">
            <WorkOrdersTable
              workOrders={workOrders}
              setWorkOrders={setWorkOrders}
              users={users}
              origins={kiosks}
              refresh={refresh}
              setRefresh={setRefresh}
              newWorkOrder={handleNewWorkOrder}
              handlePrint={handleShowPrint}
              handleView={handleView}
              updateSearchParams={updateSearchParams}
              searchParams={searchParams}
            />
          </div>
        )}
        <Pagination
          itemsToShow={pageSize}
          setItemsToShow={handlePageSizeChange}
          currentPage={page}
          setCurrentPage={handlePageChange}
          totalPages={totalPages}
        />
      </div>
      <NewWorkOrder
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      <div>
        <WorkOrderListPrint
          showPrintWorkOrders={showPrintWorkOrders}
          setShowWorkOrderPrint={setShowPrintWorkOrders}
          users={users}
        />
      </div>
    </div>
  );
  return <>{content}</>;
};

export default WorkOrders;
