import React, { useState, useEffect, useContext } from "react";
import ToDoTableMaint from "../../updatedcomponents/tables/ToDoTableMaint";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import WorkOrderListPrint from "../../components/workorderprint/WorkOrderListPrint";
import ToDoTable from "../../updatedcomponents/tables/ToDoTable";
import { FacilityContext } from "../../context/FacilityContext";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import { ArrowBack, RefreshOutlined } from "@mui/icons-material";
import WorkOrderDetails from "../ApplicationPages/WorkOrderDetails";
import TaskNotes from "../../updatedcomponents/modals/TaskNotes";
import TableFormRHF from "../../updatedcomponents/forms/TableFormRHF";

const TasksWorker = () => {
  const { selectedFacility } = useContext(FacilityContext);
  const { currentUser } = useContext(AuthContext);
  const [workOrders, setWorkOrders] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPrintWorkOrders, setShowPrintWorkOrders] = useState(false);

  const [recurringTask, setRecurringTask] = useState([]);

  const [selectedTask, setSelectedTask] = useState(null);
  const [showWorkOrder, setShowWorkOrder] = useState(false);
  const [showRecurringTask, setShowRecurringTask] = useState(false);
  const [instanceToEdit, setInstanceToEdit] = useState(null);

  const employeeId = currentUser._id;

  const [showNotes, setShowNotes] = useState(false);

  //Enpoint for fetching work orders
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        setIsLoading(true);

        const response = await axios.get(
          `/api/employee-tasks/list/${employeeId}?facility=${selectedFacility}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setWorkOrders(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching work orders:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [refresh, employeeId]);

  //Enpoint for fetching recurring tasks
  useEffect(() => {
    if (selectedFacility && employeeId) {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem("token");
          setIsLoading(true);

          const response = await axios.get(
            `/api/employee-tasks/recurring/list/${selectedFacility}/${employeeId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setRecurringTask(response.data);
        } catch (error) {
          console.error("Error fetching recurring tasks:", error);
        }
      };

      fetchData();
    }
  }, [refresh, selectedFacility, employeeId]);

  const handleLogInstanceView = (logInstance) => {
    setInstanceToEdit(logInstance);
    setShowRecurringTask(true);
  };

  const content = (
    <div>
      {!(showWorkOrder || showRecurringTask) ? (
        <div className="relative">
          <PageHeader
            title={"To Do List"}
            subtitle={"Tasks that have not yet been closed out."}
            buttons={
              <button
                className="mx-2 flex-1 flex items-center gap-2 justify-center rounded-sm bg-secondary-100 px-2 py-1 h-fit text-center text-sm text-primary border-transparent border hover:bg-secondary-200"
                onClick={() => setRefresh(!refresh)}
              >
                <RefreshOutlined fontSize="1rem" /> <span>Refresh</span>
              </button>
            }
          />
          {/* Work Orders */}
          <div className="rounded-sm border border-secondary-1000 p-2 sm:px-4 m-2 min-h-96 overflow-y-auto">
            <h2 className="py-2 text-secondary-100 font-medium">Work Orders</h2>
            <ToDoTableMaint
              data={workOrders}
              setData={setWorkOrders}
              user={employeeId}
              refresh={refresh}
              setRefresh={setRefresh}
              permissions={currentUser.access}
              setShowTask={setShowWorkOrder}
              selectedWorkOrder={selectedTask}
              setSelectedWorkOrder={setSelectedTask}
              setShowNotes={setShowNotes}
            />
          </div>
          <div className="rounded-sm border border-secondary-1000 p-2 m-2 min-h-96 overflow-y-auto">
            <h2 className="py-2 text-secondary-100 font-medium">
              Recurring Tasks
            </h2>
            <ToDoTable
              data={recurringTask}
              users={[]}
              setShowForm={setShowRecurringTask}
              setInstanceToEdit={setInstanceToEdit}
              refresh={refresh}
              setRefresh={setRefresh}
              handleLogInstanceView={handleLogInstanceView}
            />
          </div>

          {/*   <div className="flex w-full justify-center align-middle">
          <button
            className=" mt-8 bg-gray-50 text-secondary p-4 rounded-lg border border-secondary border-dashed "
            onClick={handlePrintList}
          >
            Print Tasks
          </button>
        </div> */}
          {showPrintWorkOrders && (
            <div>
              <WorkOrderListPrint
                setShowWorkOrderPrint={setShowPrintWorkOrders}
                users={[currentUser]}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {showWorkOrder && (
            <div>
              <PageHeader
                title={
                  <button
                    type="button"
                    className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
                    onClick={() => {
                      setShowWorkOrder(false);
                      setShowRecurringTask(false);
                    }}
                  >
                    <ArrowBack style={{ fontSize: "1rem" }} /> Back to list
                  </button>
                }
              />
              <WorkOrderDetails
                workOrder={selectedTask}
                refresh={refresh}
                setRefresh={setRefresh}
                handleDeleteClick={null}
                permissions={currentUser.access}
                setShowNotes={setShowNotes}
                images={images}
                users={[]}
              />
            </div>
          )}
          {showRecurringTask && (
            <div>
              <PageHeader
                title={
                  <button
                    type="button"
                    className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
                    onClick={() => {
                      setShowWorkOrder(false);
                      setShowRecurringTask(false);
                      setInstanceToEdit(null);
                      setImages([]);
                    }}
                  >
                    <ArrowBack style={{ fontSize: "1rem" }} /> Back to list
                  </button>
                }
              />
              <TableFormRHF
                logData={instanceToEdit}
                setShowForm={setShowRecurringTask}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </div>
          )}
        </div>
      )}
      {showNotes && selectedTask && (
        <TaskNotes
          selectedTask={selectedTask}
          showNotes={showNotes}
          setShowNotes={setShowNotes}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </div>
  );
  return <div>{content}</div>;
};

export default TasksWorker;
