import React, { createContext, useEffect, useState, useContext } from "react";
import axios from "axios";

export const FacilityContext = createContext();

export const FacilityProvider = ({ children }) => {
  const [selectedFacility, setSelectedFacility] = useState("");
  const [FacilityLocation, setFacilityLocation] = useState("");

  useEffect(() => {
    const storedFacility = localStorage.getItem("selectedFacility");
    if (storedFacility) {
      setSelectedFacility(storedFacility);
    }
  }, []);

  const updateLastLoggedFacility = async (userId, facilityId) => {
    try {
      const response = await axios.post(
        `/api/user/update/facility-change/${facilityId}`,
        {
          userId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error updating facility:", error);
    }
  };

  const handleFacilityChange = (facility, userId) => {
    updateLastLoggedFacility(userId, facility);
    setSelectedFacility(facility);
    localStorage.setItem("selectedFacility", facility);
  };

  return (
    <FacilityContext.Provider
      value={{ selectedFacility, handleFacilityChange }}
    >
      {children}
    </FacilityContext.Provider>
  );
};
