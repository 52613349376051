import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const categories = [
  {
    name: "Completed Tasks",
    dataKey: "Complete",
    stroke: "#008D86",
    fill: "#99DFDC",
  },
  {
    name: "Incomplete",
    dataKey: "Incomplete",
    stroke: "#585858",
    fill: "#E7E7E7",
  },
  {
    name: "Unable to complete",
    dataKey: "Unable",
    stroke: "#8D001C",
    fill: "#EFCCD3",
  },
];

const MirrorDensityPlot = ({ data }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {categories.map((category, index) => (
        <div
          key={category.name}
          className={`${
            index === 0 ? "md:col-span-2" : ""
          } p-4 border border-secondary-1000`}
        >
          <h4 className="mt-1 py-1 text-secondary-300 text-medium">
            {category.name}
          </h4>
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart
              width={500}
              height={200}
              data={data}
              syncId="anyId"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient
                  id={`colorUv${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="60%"
                    stopColor={category.fill}
                    stopOpacity={0.8}
                  />
                  <stop offset="99%" stopColor="#fff" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey={category.dataKey}
                stroke={category.stroke}
                fill={`url(#colorUv${index})`}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ))}
    </div>
  );
};

export default MirrorDensityPlot;
