import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Add, Close, Edit, HeatPumpRounded, Person } from "@mui/icons-material";

const WorkOrderListPrint = ({
  showPrintWorkOrders,
  setShowWorkOrderPrint,
  users,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleUserCheckboxChange = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handlePrintList = async () => {
    if (selectedUsers.length === 0) {
      console.log("No users selected.");
      return;
    }

    try {
      const response = await axios.post(
        "/api/work-order/to-do/print",
        { userIds: selectedUsers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "arraybuffer",
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(pdfBlob);
      window.open(url, "work-orders");
    } catch (error) {
      console.error("Error fetching work orders:", error);
    }
  };

  return (
    <Transition.Root show={showPrintWorkOrders} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={setShowWorkOrderPrint}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setShowWorkOrderPrint(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <Close className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8">
                    <div className="space-y-6 pb-16">
                      <div>
                        <h3 className="font-medium text-gray-900">
                          Print work orders
                        </h3>
                        <div className="mt-2 flex items-center justify-between">
                          <p className="text-sm italic text-gray-500">
                            Select who you would like to print for
                          </p>
                        </div>
                      </div>
                      <div>
                        <fieldset>
                          <legend className="text-base font-semibold leading-6 text-gray-700">
                            Available Members
                          </legend>
                          <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                            {users.map((user, userIdx) => (
                              <div
                                key={userIdx}
                                className="relative flex items-start py-4"
                              >
                                <div className="min-w-0 flex-1 text-sm leading-6">
                                  <label
                                    htmlFor={`user-${user.id}`}
                                    className="select-none font-medium text-gray-900"
                                  >
                                    <span className="flex items-center">
                                      {user.avatar ? (
                                        <img
                                          className="h-5 w-5 rounded-full flex "
                                          src={user.avatar}
                                          alt=""
                                        />
                                      ) : (
                                        <span>
                                          <Person className="p-1 rounded-xl bg-gray-200 mr-2" />
                                        </span>
                                      )}
                                      <span className="px-2">
                                        {capitalizeFirstLetter(user.firstname)}{" "}
                                        {capitalizeFirstLetter(user.lastname)}
                                      </span>
                                    </span>
                                  </label>
                                </div>
                                <div className="ml-3 flex h-6 items-center">
                                  <input
                                    id={`user-${user._id}`}
                                    name={`user-${user._id}`}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-accent-100 focus:ring-accent-100"
                                    value={user._id}
                                    checked={selectedUsers.includes(user._id)}
                                    onChange={() =>
                                      handleUserCheckboxChange(user._id)
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                      <div className="flex">
                        <button
                          type="button"
                          className="flex-1 rounded-md bg-accent-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-100"
                          onClick={handlePrintList}
                        >
                          Print
                        </button>
                        <button
                          type="button"
                          className="ml-3 flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setShowWorkOrderPrint(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WorkOrderListPrint;
