import React, { Fragment, useState } from "react";
import NewUserForm from "../forms/NewUserForm";
import { Edit, Person } from "@mui/icons-material";

const TeamList = ({
  users,
  refresh,
  setRefresh,
  facility,
  permissions,
  facilities,
  setViewUserInfo,
}) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const formatDate = (date) => {
    return date.slice(0, 10);
  };
  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return "";
    }
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    if (numericPhoneNumber.length !== 10) {
      return "Invalid phone number";
    }

    const formattedPhoneNumber = `(${numericPhoneNumber.slice(
      0,
      3
    )}) ${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6, 10)}`;

    return formattedPhoneNumber;
  }

  return (
    <div className="w-full ">
      <div className="mt-2 flow-root">
        <div className="overflow-auto thin-scrollbar mb-14">
          <div className="inline-block min-w-full py-2 align-middle ">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="bg-secondary-1200 border border-secondary-1000 border-spacing-2">
                  <th
                    scope="col"
                    className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary-100 "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
                  >
                    Date Joined
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody className="bg-primary">
                {users.map((user) => (
                  <Fragment key={user._id}>
                    <tr key={`${user._id}-gap`} className="h-2"></tr>
                    <tr
                      key={user._id}
                      className=" border border-secondary-1000 text-secondary-100"
                    >
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm ">
                        <div className="flex">
                          <div className="h-6 w-6 flex flex-shrink-0 ">
                            {user.avatar ? (
                              <img
                                className="h-6 w-6 rounded-full"
                                src={user.avatar}
                                alt=""
                              />
                            ) : (
                              <Person className="h-6 w-6 rounded-full" />
                            )}
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-secondary-100">
                              {user.firstname} {user.lastname}
                            </div>
                            <div className="mt-1 text-gray-500">
                              {user.email}
                            </div>
                            <div className="mt-1 text-gray-500">
                              {formatPhoneNumber(user.phone)}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {/* <div className="text-secondary-100">Date </div> */}
                        <div className="mt-1 text-gray-500">
                          {formatDate(user.joinDate)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {user.isVerified === true ? (
                          <span className="inline-flex items-center rounded-sm bg-accent-900 px-2 py-1 text-xs font-medium text-accent-200 ring-1 ring-inset ring-accent-200/20">
                            Verified
                          </span>
                        ) : (
                          <span className="inline-flex items-center rounded-sm bg-reds-1000 px-2 py-1 text-xs font-medium text-reds-300 ring-1 ring-inset ring-reds-300/20">
                            Unverified
                          </span>
                        )}
                      </td>

                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {capitalizeFirstLetter(user.access)}
                      </td>
                      {(permissions == "regional" ||
                        permissions == "system-admin" ||
                        permissions == "organization-admin") && (
                        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-sm font-medium">
                          <button
                            onClick={() => setViewUserInfo(user)}
                            className="px-2 py-1 text-sm font-semibold text-secondary-100  underline hover:text-secondary-300"
                          >
                            View
                            <span className="sr-only">, {user.name}</span>
                          </button>
                        </td>
                      )}
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamList;
