import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronLeft, ChevronRight, ExpandMore } from "@mui/icons-material";
import moment from "moment";

const CalendarHeader = ({ setDateParams, dateParams }) => {
 
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const capitalizeString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  let formatString;

  switch (dateParams.currentView) {
    case "month":
    case "week":
      formatString = "MMMM YYYY";
      break;
    case "year":
      formatString = "YYYY";
      break;
    case "day":
      formatString = "MMMM D, YYYY";
      break;
    default:
      formatString = "MMMM D, YYYY";
  }

  const dateText = moment(dateParams.currentDate).format(formatString);

  return (
    <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
      <h1 className="text-base font-semibold leading-6 text-gray-900">
        <time>{dateText}</time>
      </h1>
      <div className="flex items-center">
        <div className="relative flex items-center rounded-sm bg-white shadow-sm md:items-stretch">
          <button
            type="button"
            className="flex h-9 w-12 items-center justify-center rounded-l-sm border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
            onClick={() => {
              const newCurrentDate = moment(dateParams.currentDate)
                .subtract(1, dateParams.currentView)
                .format("YYYY-MM-DD");

              setDateParams((prev) => ({
                ...prev,
                currentDate: newCurrentDate,
                startDate: moment(newCurrentDate)
                  .startOf(dateParams.currentView)
                  .format("YYYY-MM-DD"),
                endDate: moment(newCurrentDate)
                  .endOf(dateParams.currentView)
                  .format("YYYY-MM-DD"),
              }));
            }}
          >
            <span className="sr-only">Previous {dateParams.currentView}</span>
            <ChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
            onClick={() =>
              setDateParams((prev) => ({
                ...prev,
                currentDate: moment().format("YYYY-MM-DD"),
                startDate: moment()
                  .startOf(dateParams.currentView)
                  .format("YYYY-MM-DD"),
                endDate: moment()
                  .endOf(dateParams.currentView)
                  .format("YYYY-MM-DD"),
              }))
            }
          >
            Today
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            type="button"
            className="flex h-9 w-12 items-center justify-center rounded-r-sm border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
            onClick={() => {
              const newCurrentDate = moment(dateParams.currentDate)
                .add(1, dateParams.currentView)
                .format("YYYY-MM-DD");

              setDateParams((prev) => ({
                ...prev,
                currentDate: newCurrentDate,
                startDate: moment(newCurrentDate)
                  .startOf(dateParams.currentView)
                  .format("YYYY-MM-DD"),
                endDate: moment(newCurrentDate)
                  .endOf(dateParams.currentView)
                  .format("YYYY-MM-DD"),
              }));
            }}
          >
            <span className="sr-only">Next {dateParams.currentView}</span>
            <ChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          <Menu as="div" className="relative">
            <Menu.Button
              type="button"
              className="flex items-center gap-x-1.5 rounded-sm bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              {capitalizeString(dateParams.currentView)}
              <ExpandMore
                className="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() =>
                          setDateParams((prev) => ({
                            ...prev,
                            currentView: "day",
                            startDate: moment("day")
                              .startOf(dateParams.currentView)
                              .format("YYYY-MM-DD"),
                            endDate: moment(dateParams.currentDate)
                              .endOf("day")
                              .format("YYYY-MM-DD"),
                          }))
                        }
                      >
                        Day view
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() =>
                          setDateParams((prev) => ({
                            ...prev,
                            currentView: "week",
                            startDate: moment(dateParams.currentDate)
                              .startOf("week")
                              .format("YYYY-MM-DD"),
                            endDate: moment(dateParams.currentDate)
                              .endOf("week")
                              .format("YYYY-MM-DD"),
                          }))
                        }
                      >
                        Week view
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() =>
                          setDateParams((prev) => ({
                            ...prev,
                            currentView: "month",
                            startDate: moment(dateParams.currentDate)
                              .startOf("month")
                              .format("YYYY-MM-DD"),
                            endDate: moment(dateParams.currentDate)
                              .endOf("month")
                              .format("YYYY-MM-DD"),
                          }))
                        }
                      >
                        Month view
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        onClick={() =>
                          setDateParams((prev) => ({
                            ...prev,
                            currentView: "year",
                            startDate: moment(dateParams.currentDate)
                              .startOf("year")
                              .format("YYYY-MM-DD"),
                            endDate: moment(dateParams.currentDate).endOf(
                              "year"
                            ),
                          }))
                        }
                      >
                        Year view
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <Menu as="div" className="relative ml-6 md:hidden">
          <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open menu</span>
            {/* expand */}
            <div className="h-5 w-5 " aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Create event
                    </a>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Go to today
                    </a>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Day view
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Week view
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Month view
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Year view
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  );
};

export default CalendarHeader;
