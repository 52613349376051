import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";

const WorkOrderLogTable = ({ workOrders}) => {
  const navigate = useNavigate();

  const handleView = (workOrder) => {
    // navigate to the work order instance page pass the workOrder in state
    navigate(`/work-orders/instance/${workOrder._id}`, {
      state: { workOrder },
    });
  };

  return (
    <div className="">
      <div className="inline-block min-w-full py-1 align-middle mt-4 mb-14">
        <table className="min-w-full">
          <thead>
            <tr className="bg-secondary-1200 border border-secondary-1000 rounded-sm">
              <th
                scope="col"
                className="py-3.5 group pl-4 pr-2 text-left text-sm font-medium text-secondary-100 cursor-pointer"
              >
                Request Title
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Date Reported
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Date Closed
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Request Origin
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Request Type
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Location
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Urgency
              </th>
              <th
                scope="col"
                className="hidden group px-2 py-3.5 text-left text-sm font-medium text-secondary-100 cursor-pointer sm:table-cell"
              >
                Actions
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody className="">
            {workOrders.length > 0 ? (
              workOrders.map((workOrder) => (
                <Fragment key={workOrder._id}>
                  <tr key={`${workOrder._id}-gap`} className="h-2"></tr>
                  <tr
                    key={workOrder._id}
                    className="border border-secondary-1000 rounded-sm"
                  >
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-secondary-100 sm:w-auto sm:max-w-none">
                      {capitalizeString(workOrder.title)}
                      <dl className="font-normal lg:hidden">
                        <dt className="sr-only sm:hidden">Origin</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {capitalizeString(workOrder.requestOrigin)}
                        </dd>
                        <dt className="sr-only sm:hidden">Type</dt>
                        <dd className="mt-1 truncate text-gray-500 md:hidden">
                          {capitalizeString(workOrder.requestType)}
                        </dd>
                        <dt className="sr-only sm:hidden">Location</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {capitalizeString(workOrder.location)}
                        </dd>
                        <dt className="sr-only sm:hidden">Urgency</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {capitalizeString(workOrder.urgency)}
                        </dd>
                        <dt className="sr-only sm:hidden">Date reported</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          Reported:{" "}
                          {formatDate(
                            workOrder.dateReported,
                            "MM/DD/YYYY hh:mm A"
                          )}
                        </dd>
                        <dt className="sr-only sm:hidden">Date closed</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          Closed:{" "}
                          {formatDate(
                            workOrder.dateClosed,
                            "MM/DD/YYYY hh:mm A"
                          )}
                        </dd>
                      </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell ">
                      {formatDate(workOrder.dateReported)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {formatDate(workOrder.dateClosed)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {capitalizeString(workOrder.requestOrigin)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500  md:table-cell">
                      {capitalizeString(workOrder.requestType)}
                    </td>

                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {capitalizeString(workOrder.location)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {capitalizeString(workOrder.urgency)}
                    </td>
                    <td className="flex py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => handleView(workOrder)}
                        className="text-accent-500 hover:text-accent-400 underline"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className="p-4 text-center italic font-medium text-text"
                >
                  No Data Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkOrderLogTable;
