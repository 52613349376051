import React, { createContext, useCallback, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

export const RecurringTaskContext = createContext();

export const RecurringTaskProvider = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [recurringTasks, setRecurringTasks] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const updateSearchParams = (newParams) => {
        setSearchParams(
            {
                ...Object.fromEntries(searchParams.entries()),
                ...newParams,
            },
            { replace: true }
        );
    };

    const fetchRecurringTasks = useCallback(async (selectedFacility) => {
        try {
            setIsLoading(true);
            const filtersString = searchParams.get("filters");
            const filters = filtersString ? JSON.parse(filtersString) : {};
            const page = searchParams.get("page") || "1";
            const pageSize = searchParams.get("pageSize") || "10";
            const readyToComplete = searchParams.get("readyToComplete") || "true";

            if (!selectedFacility) {
                return;
            }

            const token = localStorage.getItem("token");
            const queryParams = new URLSearchParams({
                ...filters,
                page,
                pageSize,
                readyToComplete,
            });

            const response = await axios.get(
                `/api/log/task/list/${selectedFacility}?${queryParams.toString()}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.tasks === undefined) {
                console.error("No tasks in response");
                return;
            }
            setRecurringTasks(response.data.tasks || []);
            setTotalPages(response.data.pages);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching recurring tasks:", error);
            setIsLoading(false);
        }
    }, [searchParams]);

    return (
        <RecurringTaskContext.Provider
            value={{
                recurringTasks,
                setRecurringTasks,
                totalPages,
                isLoading,
                searchParams,
                updateSearchParams,
                fetchRecurringTasks,
            }}
        >
            {children}
        </RecurringTaskContext.Provider>
    );
}