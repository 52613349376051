import React, { useState, useEffect, useContext } from "react";
import ToDoTable from "../../updatedcomponents/tables/ToDoTable";
import ToDoCalendar from "../../updatedcomponents/calendars/ToDoCalendar";
import {
  ArrowBack,
  CalendarTodayOutlined,
  FilterAltOutlined,
  FormatListBulleted,
} from "@mui/icons-material";
import axios from "axios";
import { FacilityContext } from "../../context/FacilityContext";
import TableFormRHF from "../../updatedcomponents/forms/TableFormRHF";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import ActionResponse from "../../updatedcomponents/modals/ActionResponse";
import useFetchData from "../../hooks/useFetchData";
import { useSearchParams } from "react-router-dom";
import { RecurringTaskContext } from "../../context/RecurringTaskContext";

const Tasks = () => {
  const { selectedFacility } = useContext(FacilityContext);
  const { currentUser } = useContext(AuthContext);
  const {
    recurringTasks,
    totalPages,
    isLoading,
    searchParams,
    updateSearchParams,
    fetchRecurringTasks,
  } = useContext(RecurringTaskContext);

  const emptyFilters = {
    status: "",
    taskType: "",
    frequency: "",
    dueDateFrom: "",
    dueDateTo: "",
  };

  const [filters, setFilters] = useState(emptyFilters);
  const [view, setView] = useState(searchParams.get("view") || "list");
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [instanceToEdit, setInstanceToEdit] = useState(null);
  const [showLog, setShowLog] = useState(false);

  const readyToComplete = searchParams.get("readyToComplete") !== "false";
  const currentPage = parseInt(searchParams.get("page") || "1");
  const tasksPerPage = parseInt(searchParams.get("pageSize") || "10");

  const filterOptions = [
    {
      label: "Start Date",
      type: "date",
      name: "dueDateFrom",
    },
    {
      label: "End Date",
      type: "date",
      name: "dueDateTo",
    },
    {
      label: "Status",
      type: "selector",
      options: [
        { label: "All", value: "" },
        { label: "Incomplete", value: "incomplete" },
        { label: "In Progress", value: "in progress" },
        { label: "Overdue", value: "overdue" },
        { label: "Complete", value: "complete" },
      ],
      name: "status",
    },
    {
      label: "Task Type",
      type: "selector",
      options: [
        { label: "All", value: "" },
        { label: "Preventative Maintenance", value: "prev-maint" },
        { label: "Life Safety", value: "life-safety" },
      ],
      name: "taskType",
    },
    {
      label: "Task Frequency",
      type: "selector",
      options: [
        { label: "All", value: "" },
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Bi-weekly", value: "bi-weekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Semi-annually", value: "semi-annually" },
        { label: "Annually", value: "annually" },
        { label: "2 Year", value: "biennially" },
        { label: "3 Year", value: "3-year" },
        { label: "4 Year", value: "4-year" },
        { label: "5 Year", value: "5-year" },
      ],
      name: "frequency",
    },
  ];

  //Endpoint for fetching maintenace workers
  const { data: usersData, error: usersError } = useFetchData(
    selectedFacility ? `/api/user/list/${selectedFacility}` : null,
    {},
    [selectedFacility],
    { users: [] }
  );
  const users = usersData?.user || [];

  if (usersError) {
    console.error("Error fetching users:", usersError);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    if (!selectedFacility) return;
    fetchRecurringTasks(selectedFacility);
    setFilters(JSON.parse(searchParams.get("filters")) ?? emptyFilters);
    updateActiveFilterCount();
  }, [searchParams, selectedFacility]);

  const handleResetFilters = () => {
    updateSearchParams({
      filters: JSON.stringify(emptyFilters),
      page: "1",
      view,
      readyToComplete: "true",
    });
    setFilters(emptyFilters);
    setActiveFilterCount(0);
    setShowFilter(false);
  };

  const handleApplyFilters = (newFilters) => {
    updateSearchParams({
      filters: JSON.stringify(newFilters),
      page: "1",
    });
    setFilters(newFilters);
    setShowFilter(false);
  };

  const handlePageSizeChange = ({ pageSize, page }) => {
    updateSearchParams({ pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage) => {
    updateSearchParams({ page: newPage.toString() });
  };

  const updateActiveFilterCount = () => {
    const currentFilters = JSON.parse(searchParams.get("filters") || "{}");
    const count = Object.values(currentFilters).filter(
      (value) => value !== ""
    ).length;
    setActiveFilterCount(count);
  };

  const handleLogInstanceView = (logInstance) => {
    setInstanceToEdit(logInstance);
    setShowLog(true);
  };

  const viewButtons = () => {
    return (
      <div className="isolate inline-flex rounded-sm shadow-sm my-2 bg-secondary-1100 text-secondary-100 text-xs font-medium cursor-pointer">
        <button
          type="button"
          className={classNames(
            view === "list" ? "bg-primary my-0.5 mx-0.5 shadow" : "",
            "relative inline-flex items-center rounded-l-sm px-2 py-2"
          )}
          onClick={() => {
            setView("list");
          }}
        >
          <FormatListBulleted style={{ fontSize: "1rem" }} aria-hidden="true" />
          <label className="mx-2 cursor-pointer">List View</label>
        </button>
        <button
          type="button"
          className={classNames(
            view === "calendar" ? "bg-primary my-0.5 mx-0.5 shadow" : "",
            "relative inline-flex items-center rounded-r-sm px-2 py-2"
          )}
          onClick={() => {
            setView("calendar");
          }}
        >
          <CalendarTodayOutlined
            style={{ fontSize: "1rem" }}
            aria-hidden="true"
          />
          <label className="mx-2 cursor-pointer">Calendar View</label>
        </button>
      </div>
    );
  };

  const [closeoutButton, setCloseoutButton] = useState(null);

  const [response, setResponse] = useState({
    show: false,
    title: "",
    body: "",
    icon: null,
  });

  const content = (
    <div>
      <ActionResponse response={response} setResponse={setResponse} />

      {!showLog ? (
        <>
          <PageHeader
            title={"Recurring Tasks"}
            subtitle={"All tasks waiting to be closed out"}
          />
          <div className="flex w-full justify-between">
            <button
              className="ml-2 sm:ml-8 mt-4 flex gap-1 bg-secondary-1100 text-secondary-100 items-center px-2 py-1.5 rounded-sm"
              onClick={() => setShowFilter(true)}
            >
              <FilterAltOutlined style={{ fontSize: "1rem" }} />
              <span className="text-sm font-semibold">Advanced Filters</span>
              {activeFilterCount > 0 && (
                <span className="ml-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-black rounded-full">
                  {activeFilterCount}
                </span>
              )}
            </button>
            <div className="mr-2 sm:mr-8 mt-4 flex gap-1 bg-secondary-1100 text-secondary-100 items-center px-2 py-1.5 rounded-sm">
              <input
                type="checkbox"
                className="mr-2 rounded-sm text-secondary-100 focus:ring-accent-500"
                checked={readyToComplete}
                onChange={(e) => {
                  updateSearchParams({
                    readyToComplete: e.target.checked.toString(),
                    page: "1",
                  });
                }}
              />
              <span className="text-sm font-semibold">Ready to complete</span>
            </div>
          </div>
          <FilterModal
            filters={filters}
            filterOptions={filterOptions}
            onApply={handleApplyFilters}
            onReset={handleResetFilters}
            show={showFilter}
            setShow={setShowFilter}
          />

          <div className="mt-4 px-2 sm:px-8">
            {/* {viewButtons()} */}
            <div className="">
              {view === "list" ? (
                <>
                  <ToDoTable
                    data={recurringTasks}
                    users={users}
                    setShowForm={setShowLog}
                    setInstanceToEdit={setInstanceToEdit}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    handleLogInstanceView={handleLogInstanceView}
                  />
                  <Pagination
                    itemsToShow={tasksPerPage}
                    setItemsToShow={handlePageSizeChange}
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    totalPages={totalPages}
                  />
                </>
              ) : (
                <ToDoCalendar
                  data={recurringTasks}
                  dateParams={dateParams}
                  setDateParams={setDateParams}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div>
          <PageHeader
            title={
              <button
                type="button"
                className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
                onClick={() => setShowLog(false)}
              >
                <ArrowBack style={{ fontSize: "1rem" }} />{" "}
                {instanceToEdit.title}
              </button>
            }
            buttons={closeoutButton}
          />
          <TableFormRHF
            logData={instanceToEdit}
            setLogData={setInstanceToEdit}
            showForm={showLog}
            setShowForm={setShowLog}
            refresh={refresh}
            setRefresh={setRefresh}
            setCloseoutButton={setCloseoutButton}
            response={response}
            setResponse={setResponse}
          />
        </div>
      )}
    </div>
  );

  return <div>{content}</div>;
};

export default Tasks;
