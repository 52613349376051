import React, { useState, useEffect, useContext } from "react";
import { FacilityContext } from "../../context/FacilityContext";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { Add } from "@mui/icons-material";
import CreateRecurring from "../../updatedcomponents/forms/CreateRecurring";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import RecurringTasks from "../../updatedcomponents/tables/RecurringTaskList";
import ActionResponse from "../../updatedcomponents/modals/ActionResponse";
import { useNavigate } from "react-router-dom";
import OnboardingModal from "./Onboarding";

const TaskScheduler = () => {
  const navigate = useNavigate();
  const { selectedFacility } = useContext(FacilityContext);
  const { currentUser } = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [recurringTasks, setRecurringTasks] = useState([]);
  const [prevMaint, setPrevMaint] = useState([]);
  const [administrative, setAdministrative] = useState([]);

  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const permissions = currentUser.access;

  //Enpoint for fetching all tasks
  useEffect(() => {
    if (selectedFacility) {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `/api/log/list/${selectedFacility}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          
          if (response.data.length === 0) {
            setShowOnboarding(true);
          } else {
            setShowOnboarding(false);
          }

          const categorizedData = categorizeDataByType(response.data);
          setRecurringTasks(categorizedData.lifeSafetyData);
          setPrevMaint(categorizedData.prevMaintData);
          setAdministrative(categorizedData.administrativeData);
        } catch (error) {
          console.error("Error fetching log:", error);
        }
      };
      fetchData();
    }
  }, [selectedFacility, refresh]);

  function categorizeDataByType(data) {
    const categorizedData = {
      lifeSafetyData: [],
      prevMaintData: [],
      administrativeData: [],
    };

    data.forEach((item) => {
      if (item.taskType === "life-safety") {
        categorizedData.lifeSafetyData.push(item);
      } else if (item.taskType === "prev-maint") {
        categorizedData.prevMaintData.push(item);
      } else if (item.taskType === "administrative") {
        categorizedData.administrativeData.push(item);
      }
    });

    return categorizedData;
  }

  const handleAddLog = () => {
    setShowPopup(true);
  };

  const [response, setResponse] = useState({
    show: false,
    title: "",
    body: "",
    icon: null,
  });

  const handleOnboarding = () => {
    setShowOnboardingModal(true);
  };

  const content = (
    <div>
      <PageHeader
        title="Task Scheduler"
        buttons={
          (permissions == "regional" ||
            permissions == "organization-admin") && (
            <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly mx-6 sm:gap-4 gap-2">
              <button
                type="button"
                className="flex items-center justify-center gap-2 rounded-sm bg-secondary-100 px-4 py-1 text-center text-sm font-semibold text-primary border-transparent border shadow-sm hover:bg-secondary-200"
                onClick={handleAddLog}
              >
                <Add style={{ fontSize: "1rem" }} />
                <span>Schedule Recurring Task</span>
              </button>
            </div>
          )
        }
      />
      <ActionResponse response={response} setResponse={setResponse} />
      {!showOnboarding ? (
        <>
          <RecurringTasks
            permissions={permissions}
            recurringTasks={recurringTasks}
            type={"life-safety"}
            refresh={refresh}
            setRefresh={setRefresh}
            setResponse={setResponse}
          />

          <RecurringTasks
            permissions={permissions}
            recurringTasks={prevMaint}
            type={"prev-maint"}
            refresh={refresh}
            setRefresh={setRefresh}
            setResponse={setResponse}
          />

          {currentUser.access === "organization-admin" && (
            <RecurringTasks
              permissions={permissions}
              recurringTasks={administrative}
              type={"administrative"}
              refresh={refresh}
              setRefresh={setRefresh}
              setResponse={setResponse}
            />
          )}
        </>
      ) : (
        <div className="px-4 overflow-auto border border-gray-200 rounded-sm mx-2 sm:mx-4 md:mx-8 mt-8 flex flex-col justify-center items-center h-48 gap-4">
          <span className="max-w-96 text-center text-sm text-secondary-300">
            <p>
              Look like you dont have any tasks scheduled yet. Click the button
              below to begin creating tasks!
            </p>
          </span>
          <button
            onClick={() => handleOnboarding("prev-maint")}
            className="bg-accent-500 hover:bg-accent-400 hover:shadow text-primary px-3 py-1.5 rounded-sm"
          >
            Begin Onboarding
          </button>
        </div>
      )}
      <CreateRecurring
        permissions={permissions}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        facility={selectedFacility}
        refresh={refresh}
        setRefresh={setRefresh}
        existingTask={null}
        setExistingTask={null}
        setResponse={setResponse}
      />
      <OnboardingModal
        show={showOnboardingModal}
        setShow={setShowOnboardingModal}
        facilityId={selectedFacility}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
  return <div>{content}</div>;
};

export default TaskScheduler;
