import {
  BuildOutlined,
  CalendarMonthOutlined,
  PlumbingOutlined,
} from "@mui/icons-material";
import React, { useContext, useState } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { useNavigate } from "react-router-dom";
import { WorkOrderContext } from "../../context/WorkOrderContext";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    payload,
    fill,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <g className="hidden md:flex">
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 6}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.name} ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 6}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    </g>
  );
};

const ProgressWheel = ({ type, values }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const navigate = useNavigate();

  const onPieClick = (data) => {
    let path;
    if (type === "recurring") {
      path = "/tasks";
    } else {
      path = "/work-orders";
    }

    switch (data.payload.payload.name) {
      case "Closed":
        navigate("/logs");
        break;
      case "Complete":
        navigate(`${path}?filters=%7B"status"%3A"${data.payload.payload.name.toLowerCase()}"%7D`);
        break;
      case "Incomplete":
        navigate(`${path}?filters=%7B"status"%3A"${data.payload.payload.name.toLowerCase()}"%7D`);
        break;
      case "Unable":
        navigate(`${path}?filters=%7B"status"%3A"${data.payload.payload.name.toLowerCase()}"%7D`);
        break;
      case "Overdue":
        navigate(`${path}?filters=%7B"status"%3A"${data.payload.payload.name.toLowerCase()}"%7D`);
        break;
      default:
        console.log("No Task");
    }
  };

  const { closed, complete, incomplete, unable, overdue } = values;

  const data = [
    { name: "Closed", value: closed, color: "#004643" },
    { name: "Complete", value: complete, color: "#00B0A8" },
    { name: "Incomplete", value: incomplete, color: "#B8B7B7" },
    { name: "Unable", value: unable, color: "#8D001C" },
    { name: "Overdue", value: overdue, color: "#8D001C" },
  ];

  let title;
  let icon;
  switch (type) {
    case "recurring":
      title = "Recurring Tasks";
      icon = <CalendarMonthOutlined className="text-secondary-100 flex item" />;
      break;
    case "work-orders":
      title = "Work Orders";
      icon = <BuildOutlined className="text-secondary-100" />;
      break;
    default:
      title = "No Tasks";
  }

  return (
    <div className="">
      <ResponsiveContainer width="100%" height={300}>
        <PieChart width={200} height={200}>
          <Pie
            activeIndex={activeIndex}
            activeShape={(props) => renderActiveShape(props, title, icon)}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            dataKey="value"
            onMouseEnter={onPieEnter}
            onClick={onPieClick}
            className="cursor-pointer"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <foreignObject
            x="50%"
            y="50%"
            width="30"
            height="30"
            transform="translate(-15, -15)"
          >
            <div className="flex items-center justify-center w-full h-full ">
              {icon}
            </div>
          </foreignObject>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProgressWheel;
