import React, { useEffect, useState } from "react";
import KioskFormComponent from "../../updatedcomponents/forms/KioskFormComponent";
import { Logout } from "@mui/icons-material";
import ConfirmPinPopup from "../../components/kiosklogout/confirmpin";
import { useParams } from "react-router-dom";

const KioskForm = () => {
  const [showLogoutVerification, setshowLogoutVerification] = useState(false);

  const { kioskId } = useParams();
  const isDirectURI = kioskId;

  const handleLogout = () => {
    setshowLogoutVerification(true);
  };
  return (
    <div className="h-screen w-screen flex justify-center items-center bg-secondary-1000 py-4">
      <div className="hidden sm:block absolute left-5 top-5 cursor-pointer">
        <img
          className="mx-auto h-8 w-auto"
          src="\sequra_logo__primary_black.png"
          alt="SNFSafe logo"
        />
      </div>
      {!showLogoutVerification ? (
        <div className="flex-col w-full sm:max-w-md md:max-w-lg lg:max-w-xl max-h-full overflow-auto bg-primary h-fit p-4 rounded-md mx-4">
          <img
            className="h-10 w-auto mx-auto md:hidden "
            src="/sequra_logo__icon_black.png"
          />
          <KioskFormComponent kioskId={kioskId} />
          {!isDirectURI && (
            <button
              className="flex w-full justify-center cursor-pointer"
              onClick={handleLogout}
            >
              <Logout className="hover:bg-gray-50 hover:rounded-lg" />
            </button>
          )}
        </div>
      ) : (
        <ConfirmPinPopup
          setshowLogoutVerification={setshowLogoutVerification}
        />
      )}
    </div>
  );
};

export default KioskForm;
