import React, { useState, useContext, useRef } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { FacilityContext } from "../../context/FacilityContext";
import { Close } from "@mui/icons-material";

function FileUpload({ setShowFileAdd, fileType, refresh, setRefresh }) {
  const [message, setMessage] = useState("");
  const { selectedFacility } = useContext(FacilityContext);
  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm();

  const fileInputRef = useRef(null);

  const files = watch("file");

  const selectedFile = files && files.length > 0 ? files[0] : null;
  
  const handleUpload = handleSubmit(async ({ file }) => {
    const formData = new FormData();
    formData.append("file", file[0]);

    try {
      const response = await axios.post(
        `/api/building/upload/${fileType}/${selectedFacility}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        setRefresh(!refresh);
        setMessage("File uploaded succesfully");
        setShowFileAdd(false);
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative bg-white p-4 rounded-sm flex flex-col items-center">
        <div className="flex w-full mb-3 text-text justify-between cursor-pointer hover:text-gray-500">
          <h3 className="text-sm font-semibold">Upload {fileType}</h3>
          <button onClick={() => setShowFileAdd(false)}>
            <Close />
          </button>
        </div>
        <form onSubmit={handleUpload}>
          <Controller
            name="file"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <div
                className="cursor-pointer relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-accent-100 focus:ring-offset-2"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  field.onChange(e.dataTransfer.files);
                }}
                onClick={() => fileInputRef.current.click()}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => field.onChange(e.target.files)}
                  style={{ display: "none" }}
                />
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                  />
                </svg>
                {selectedFile ? (
                  <p className="w-52 mt-2 block text-sm font-semibold text-gray-900">
                    Selected File: {selectedFile.name}
                  </p>
                ) : (
                  <p className="w-52 mt-2 block text-sm font-semibold text-gray-900">
                    Drag and drop a {fileType} here or click to select
                  </p>
                )}
              </div>
            )}
          />
          <div className="flex gap-2 w-full">
            <button
              type="button"
              className="flex-1 rounded-sm bg-gray-200 mt-6 px-5 py-1 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              onClick={() => setShowFileAdd(false)}
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex-1 rounded-sm bg-secondaryTint mt-6 px-5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
              disabled={isSubmitting}
            >
              Upload
            </button>
          </div>
        </form>
        <span>{message}</span>
      </div>
    </div>
  );
}

export default FileUpload;
