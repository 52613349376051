const StatusBadge = ({ status }) => {
  const capitalizeString = (string) => {
    if (!string) return "";

    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  switch (status) {
    /* case "standard":
      return (
        <span className="inline-flex items-center justify-center rounded-full bg-secondary-1100 px-2 py-1 text-xs font-medium text-secondary-200">
        <span className="bg-secondary-200 h-2 w-2 rounded-full mr-2" />
        {capitalizeString(status)}
      </span>
      ); */
    /*  case "urgent":
      return (
        <span className="inline-flex items-center justify-center rounded-full bg-reds-1100 px-2 py-1 text-xs font-medium text-reds-500">
          <span className="bg-reds-500 h-2 w-2 rounded-full mr-2" />
          {capitalizeString(status)}
        </span>
      ); */
    case "standard":
    case "incomplete":
      return (
        <span className="inline-flex items-center justify-center rounded-sm bg-secondary-1100 px-4 py-1 text-xs font-medium text-secondary-100 ring-1 ring-inset ring-secondary-100/10">
          {capitalizeString(status)}
        </span>
      );
    case "unable":
    case "overdue":
    case "urgent":
      return (
        <span className="inline-flex items-center rounded-sm bg-reds-1100 px-4 py-1 text-xs font-medium text-reds-500 ring-1 ring-inset ring-reds-400/10">
          {capitalizeString(status)}
        </span>
      );
    case "in progress":
      return (
        <span className="inline-flex items-center rounded-sm bg-secondary-1200 px-4 py-1 text-xs font-medium text-secondary-400 ring-1 ring-inset ring-secondary-400/10">
          <span className="bg-secondary-400 h-1 w-1 rounded-full mr-2" />{" "}
          {capitalizeString(status)}
        </span>
      );
    case "complete":
      return (
        <span className="inline-flex items-center rounded-sm bg-accent-1000 px-4 py-1 text-xs font-medium text-accent-200 ring-1 ring-inset ring-accent-300/20">
          {capitalizeString(status)}
        </span>
      );
    default:
      return <span>{capitalizeString(status)}</span>;
  }
};

export default StatusBadge;
