import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import TeamList from "../../updatedcomponents/tables/TeamList";
import { AuthContext } from "../../context/AuthContext";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/admin/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users);
        setFacilities(response.data.facilities);
      } catch (error) {
        console.error("Error loading logs", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 rounded-lg  shadow">
      <TeamList
        users={users}
        facility={""}
        permissions={currentUser.access}
        refresh={refresh}
        setRefresh={setRefresh}
        facilities={facilities}
      />
    </div>
  );
};

export default Users;
