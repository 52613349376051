import React, { createContext, useState, useEffect } from "react";

export const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    const subscribeUser = async () => {
      try {
        const registration = await navigator.serviceWorker.register(
          "/worker.js"
        );
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          console.log("Permission not granted for notifications.");
          return;
        }

        let subscription = await registration.pushManager.getSubscription();
        if (!subscription) {
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
          });
        }

        setSubscription(subscription);
      } catch (error) {
        console.error("Error during subscription:", error);
      }
    };

    subscribeUser();
  }, []);

  return (
    <NotificationContext.Provider value={{ subscription, setSubscription }}>
      {children}
    </NotificationContext.Provider>
  );
};
