import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  PriorityHigh,
  CalendarMonthOutlined,
  ChevronRight,
  Person,
} from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import { WorkOrderContext } from "../../context/WorkOrderContext";
import { calculateDays, capitalizeString } from "../../utils/FormatFunctions";

const UrgentWorkOrderTable = ({ workOrders }) => {
  const navigate = useNavigate();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const formatDate = (date) => {
    date = date.slice(0, 10);
    return date;
  };

  const [sortedWorkOrders, setSortedWorkOrders] = useState([]);

  useEffect(() => {
    // Sort work orders by urgency and then by date
    const sortedOrders = [...workOrders].sort((a, b) => {
      if (a.urgency === "urgent" && b.urgency !== "urgent") {
        return -1;
      } else if (a.urgency !== "urgent" && b.urgency === "urgent") {
        return 1;
      } else {
        // If urgency is the same, sort by date
        return new Date(a.dateReported) - new Date(b.dateReported);
      }
    });

    // Limit the displayed work orders to the first 5
    const limitedOrders = sortedOrders.slice(0, 5);

    setSortedWorkOrders(limitedOrders);
  }, [workOrders]);

  const handleView = (workOrderId) => {
    navigate(
      `/work-orders/instance/${workOrderId}?filters=%7B"urgency"%3A"urgent"%7D`
    );
  };

  return (
    <div className="px-4 pt-4 border border-secondary-1000 rounded-sm bg-primary">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold  text-secondary-100">
            Urgent Work Orders
          </h1>
        </div>
      </div>
      <div className="mt-4 ">
        <table className="min-w-full  ">
          <thead>
            <tr className="bg-secondary-1200 border border-secondary-1000 border-spacing-2">
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary-100 sm:pl-2 "
              >
                Request Title
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-secondary-100 lg:table-cell"
              >
                Date Reported
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-secondary-100 sm:table-cell"
              >
                Status
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-secondary-100 sm:table-cell"
              >
                Request Type
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-secondary-100 hidden sm:table-cell"
              >
                Urgency
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left hidden sm:table-cell text-sm font-semibold text-secondary-100"
              >
                Assigned to
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedWorkOrders.map((workOrder) => (
              <Fragment key={workOrder._id}>
                <tr key={`${workOrder._id}-gap`} className="h-2"></tr>
                <tr
                  key={workOrder._id}
                  className="outline outline-1 outline-secondary-1000 rounded-sm cursor-pointer hover:bg-secondary-1200"
                  onClick={() => handleView(workOrder._id)}
                >
                  <td className="py-1 pl-4  pr-2 text-sm font-medium  text-secondary-100 ">
                    <span className="sm:max-w-96 max-w-56 flex overflow-auto text-sm">
                      {capitalizeString(workOrder.title)}
                    </span>
                    <dl className="">
                      <dt className="sr-only hidden">Details</dt>
                      <dd className="mt-1 sm:max-w-72 max-w-56  text-secondary-100  text-xs font-light">
                        {workOrder.details}
                      </dd>
                      <dt className="sr-only sm:hidden">Days since reported</dt>
                      <dd className="mt-1 truncate text-secondary-100 text-xs sm:hidden font-light">
                        <CalendarMonthOutlined style={{ fontSize: "1rem" }} />{" "}
                        {calculateDays(workOrder.dateReported)} days ago
                      </dd>
                      <dd className="sm:hidden mt-1 flex w-full justify-between text-secondary-100 font-normal text-xs">
                        <StatusBadge status={workOrder.status} />

                        <span className="">
                          {workOrder.assignedTo ? (
                            <span>
                              <Person
                                style={{ fontSize: "1rem" }}
                                className="h-4 w-4 mr-1"
                              />
                              {capitalizeString(
                                workOrder.assignedTo.firstname.slice(0, 1)
                              )}{" "}
                              {capitalizeString(workOrder.assignedTo.lastname)}
                            </span>
                          ) : (
                            <span>
                              <Person
                                style={{ fontSize: "1rem" }}
                                className="h-4 w-4 mr-1"
                              />
                              Unassigned
                            </span>
                          )}
                        </span>
                      </dd>

                      <dt className="sr-only hidden">Days since reported</dt>
                      <dd className="mt-1 truncate text-secondary-100 hidden font-light">
                        {calculateDays(workOrder.dateReported)} days
                      </dd>

                      <dt className="sr-only hidden">Status and urgency</dt>
                      <dd className="mt-1 truncate text-secondary-100 sm:hidden flex w-full justify-between my-2">
                        <StatusBadge status={workOrder.urgency} />
                        <span className="text-secondary-100 font-normal text-xs">
                          ID {workOrder?.shortId}
                        </span>
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden whitespace-nowrap px-2 py-1 text-left text-xs text-secondary-600 sm:table-cell">
                    {calculateDays(workOrder.dateReported)} days ago
                  </td>

                  <td
                    className="hidden whitespace-nowrap px-2 py-1 text-sm text-secondary-400 xl:table-cell"
                    style={{
                      maxWidth: "100px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {capitalizeString(workOrder.location)}
                  </td>
                  <td className="hidden whitespace-nowrap px-2 py-1 text-sm text-secondary-400 sm:table-cell">
                    <StatusBadge status={workOrder.urgency} />
                  </td>

                  <td className=" whitespace-nowrap px-2 py-1 text-sm text-secondary-400 hidden sm:table-cell">
                    <StatusBadge status={workOrder.status} />
                  </td>

                  <td className="hidden whitespace-nowrap px-2 py-1 text-left text-sm text-secondary-400 sm:table-cell">
                    {workOrder.assignedTo ? (
                      <span>
                        <Person
                          style={{ fontSize: "1rem" }}
                          className="h-4 w-4 mr-1"
                        />
                        {capitalizeString(
                          workOrder.assignedTo.firstname.slice(0, 1)
                        )}{" "}
                        {capitalizeString(workOrder.assignedTo.lastname)}
                      </span>
                    ) : (
                      <span>
                        <Person
                          style={{ fontSize: "1rem" }}
                          className="h-4 w-4 mr-1"
                        />
                        Unassigned
                      </span>
                    )}
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UrgentWorkOrderTable;
