import React from "react";
import { Link } from "react-router-dom";
import { ErrorOutline } from "@mui/icons-material";

function NotFound() {
  return (
    <div className="h-full my-8 flex justify-center items-center text-center w-full">
      <div className="rounded shadow w-fit m-auto px-6 py-2 max-w-sm">
        <ErrorOutline
          className="text-accent-200"
          style={{ fontSize: "3rem" }}
        />
        <h1 className="font-bold text-secondary py-4">
          Oops, we couldn't find the page you are looking for.
        </h1>

        <Link to="/home">
          <span className="text-sm text-gray-400 hover:cursor-pointer">Return to Home</span>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
