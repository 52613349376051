import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Check, KeyboardArrowUpOutlined, Person } from "@mui/icons-material";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AssignmentSelector({
  users,
  selectedUser,
  handleChange,
  workOrder,
}) {
  return (
    <Listbox value={selectedUser} onChange={(e) => handleChange(workOrder, e)}>
      {({ open }) => (
        <div className="relative flex w-fit">
          <Listbox.Button className="flex truncate w-40 sm:w-48 cursor-pointer rounded-sm bg-white py-0.5 px-1 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent-500 text-xs sm:text-sm sm:leading-6">
            <span className="flex items-center gap-1">
              {selectedUser.avatar ? (
                <img
                  src={selectedUser.avatar}
                  alt=""
                  className="h-3 w-3 flex-shrink-0 rounded-full"
                />
              ) : (
                <span className="bg-gray-100 rounded-full w-3 h-3 sm:w-4 sm:h-4 flex items-center justify-center">
                  <Person
                    style={{
                      fontSize: "1rem",
                      height: "0.75rem",
                      width: "0.75rem",
                    }}
                  />
                </span>
              )}
              <span className=" block truncate text-xs sm:text-sm">
                {selectedUser
                  ? `${selectedUser.firstname} ${selectedUser.lastname}`
                  : "Unassigned"}
              </span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0  flex items-center pr-1">
              <KeyboardArrowUpOutlined
                style={{ fontSize: "1rem" }}
                className="text-secondary-900"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-8 max-h-56 w-full overflow-auto thin-scrollbar rounded-md bg-primary py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs sm:text-sm">
              <Listbox.Option
                key={"unassign"}
                className={({ active }) =>
                  classNames(
                    active ? "bg-secondary-100 text-white" : "text-gray-900",
                    "relative cursor-default select-none py-2 pl-3 pr-9"
                  )
                }
                value={"unassign"}
              >
                {({ selected, active }) => (
                  <>
                    <div className="flex items-center">
                      <span className="text-secondary-100 bg-secondary-1000 rounded-full w-3 h-3 flex items-center justify-center">
                        <Person
                          style={{
                            fontSize: "1rem",
                            height: "0.75rem",
                            width: "0.75rem",
                          }}
                        />
                      </span>
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "ml-3 block truncate text-xs sm:text-sm"
                        )}
                      >
                        Unnasigned
                      </span>
                    </div>

                    
                  </>
                )}
              </Listbox.Option>

              {users.map((user) => (
                <Listbox.Option
                  key={user._id}
                  className={({ active }) =>
                    classNames(
                      active ? "bg-secondary-100 text-white" : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-3 pr-9"
                    )
                  }
                  value={user._id}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center">
                        {user.avatar ? (
                          <img
                            src={user.avatar}
                            alt=""
                            className="h-3 w-3 flex-shrink-0 rounded-full"
                          />
                        ) : (
                          <span className="bg-secondary-1000 rounded-full w-3 h-3 flex items-center justify-center">
                            <Person
                              className="text-secondary-100"
                              style={{
                                fontSize: "1rem",
                                height: "0.75rem",
                                width: "0.75rem",
                              }}
                            />
                          </span>
                        )}
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "ml-3 block text-xs sm:text-sm"
                          )}
                        >
                          {user.firstname} {user.lastname}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-accent-100",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <Check className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
