import React, { useState, useEffect, useContext } from "react";
import { FacilityContext } from "../../context/FacilityContext";
import axios from "axios";

const AdminHome = () => {
  const { selectedFacility } = useContext(FacilityContext);
  const [data, setData] = useState({ workOrders: 0, users: 0, facilities: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedFacility) {
          const token = localStorage.getItem("token");
          const response = await axios.get(`/api/admin/home`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setData(response.data);
        }
      } catch (error) {
        console.error("Error loading logs", error);
      }
    };
    fetchData();
  }, [selectedFacility]);

  const content = (
    <div className="p-4 sm:p-6 lg:p-8">
      <div className="text-secondary-100 flex gap-2">Total number of work orders: <p className="text-accent-500">{data.workOrders}</p></div>
      <div className="text-secondary-100 flex gap-2">Total number of users: <p className="text-accent-500">{data.users}</p></div>
      <div className="text-secondary-100 flex gap-2">Total number of facilities: <p className="text-accent-500">{data.facilities}</p></div>
    </div>
  );

  return <div>{content}</div>;
};

export default AdminHome;
