import React, { useState, Fragment, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { FacilityContext } from "../../context/FacilityContext";
import { useNavigate } from "react-router-dom";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  AttachmentOutlined,
  CancelOutlined,
  CheckCircleOutline,
  CheckOutlined,
  Close,
  CloudUpload,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import { capitalizeString, decodedString } from "../../utils/FormatFunctions";

const TableFormRHF = ({
  logData,
  setLogData,
  setShowForm,
  refresh,
  setRefresh,
  setCloseoutButton,
  response,
  setResponse,
}) => {
  const { currentUser } = useContext(AuthContext);
  const { selectedFacility } = useContext(FacilityContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState([]);

  //Process for creating table header
  let mappingData = [];
  let inputKeys = [];

  if (logData.customMap && logData.hasCycle) {
    mappingData = logData.customMap;
  } else if (logData.map) {
    mappingData = logData.map;
  } else {
    mappingData = { fields: [{ ID: 1 }] };
  }

  const mapKeys = Object.keys(mappingData.fields[0]);

  logData.inputs.forEach((input) => {
    const decodedInputName = decodeURIComponent(input.name);
    inputKeys.push(decodedInputName);
  });

  const formLabels = [...mapKeys, ...inputKeys];

  //   Load existing data if it exists, otherwise create empty array
  const [loadedData, setLoadedData] = useState(logData.customInput ?? []);

  const [files, setFiles] = useState([]);

  //   YUP validation schema for form inputs
  const validationSchema = Yup.object().shape({
    inputtedData: Yup.array().of(
      Yup.object().shape({
        ...logData.inputs.reduce((acc, input) => {
          const { validator, name, dataType } = input;

          if (dataType === "file") {
            if (!validator || validator.required !== false) {
              acc[name] = Yup.array().of(Yup.string().required("Required"));
            } else {
              acc[name] = Yup.array().of(Yup.string().nullable());
            }
          } else {
            if (!validator || validator.required !== false) {
              acc[name] = Yup.string().required("Required");
            } else {
              acc[name] = Yup.string().nullable();
            }
          }

          return acc;
        }, {}),

        Notes: Yup.string().test(
          "hasFailValue",
          "Required when there is a Fail.",
          function (value) {
            const hasFailValue = inputKeys.some(
              (key) => this.parent[encodeURIComponent(key)] === "Fail"
            );

            if (hasFailValue) {
              return Yup.string().required("Required").validateSync(value, {
                path: this.path,
                parent: this.parent,
              });
            }

            return true;
          }
        ),
      })
    ),
  });

  // Validate that all fields are filled out before marking complete
  const completeValidationSchema = Yup.object().shape({
    inputtedData: Yup.array().of(
      Yup.object().shape(
        logData.inputs.reduce((acc, input) => {
          const { name, validator } = input;

          if (validator && validator.required) {
            acc[name] = Yup.string().required(`The field ${name} is required`);
          } else {
            acc[name] = Yup.string();
          }
          return acc;
        }, {})
      )
    ),
  });

  const closeoutValidationSchema = Yup.object().shape({
    inputtedData: Yup.array().of(
      Yup.object().shape(
        logData.inputs.reduce((acc, input) => {
          const { name, validator, dataType } = input;
          let field;

          if (dataType === "file") {
            field = Yup.array();
          } else {
            field = Yup.string();
          }

          if (validator) {
            if (validator.required) {
              field = field.required(`The field ${name} is required`);
            }

            field = field.test(
              "hasFailValue",
              "The form contains failed values.",
              function () {
                const hasFailValue = inputKeys.some(
                  (key) => this.parent[encodeURIComponent(key)] === "Fail"
                );
                if (hasFailValue) {
                  return this.createError({
                    path: this.path,
                    parent: this.parent,
                  });
                }
                return !hasFailValue;
              }
            );
            if (
              validator.lowerLimit !== undefined ||
              validator.upperLimit !== undefined
            ) {
              field = field.test("inRange", function (value) {
                if (
                  validator.lowerLimit !== undefined &&
                  validator.upperLimit !== undefined
                ) {
                  // Case 1: Both lower and upper bounds are present
                  if (
                    value < parseInt(validator.lowerLimit) ||
                    value > parseInt(validator.upperLimit)
                  ) {
                    return this.createError({
                      path: this.path,
                      message: `${name} must be between ${validator.lowerLimit} and ${validator.upperLimit}`,
                    });
                  }
                } else if (validator.lowerLimit !== undefined) {
                  // Case 2: Only a lower bound is present
                  if (value < parseInt(validator.lowerLimit)) {
                    return this.createError({
                      path: this.path,
                      message: `${name} must be above ${validator.lowerLimit}`,
                    });
                  }
                } else if (validator.upperLimit !== undefined) {
                  // Case 3: Only an upper bound is present
                  if (value > parseInt(validator.upperLimit)) {
                    return this.createError({
                      path: this.path,
                      message: `${name} must be below ${validator.upperLimit}`,
                    });
                  }
                }

                return true;
              });
            }
          }

          acc[name] = field;
          return acc;
        }, {})
      )
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      certified: false,
      inputtedData: loadedData,
    },
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });
  const {} = useFieldArray({
    control,
    name: "inputtedData",
  });
  const { errors, isDirty, dirtyFields, isSubmitSuccessful } = formState;

  const { inputtedData } = getValues();
  const certified = useWatch({ control, name: "certified" });

  useEffect(() => {
    if (dirtyFields.inputtedData) {
      // Update timestamp for each dirty field
      Object.keys(dirtyFields.inputtedData).forEach((fieldIndex) => {
        const date = new Date();
        setValue(`inputtedData[${fieldIndex}].timestamp`, date);
      });
    }
  }, [dirtyFields.inputtedData]);

  const [isCloseoutButtonDisabled, setCloseoutButtonDisabled] = useState(true);

  useEffect(() => {
    const validateData = async () => {
      try {
        const closevalidation = await closeoutValidationSchema.validate({
          inputtedData,
        });
        setCloseoutButtonDisabled(false); // Enable the button if validation passes
      } catch (error) {
        console.error("Validation failed:", error);
        setCloseoutButtonDisabled(true); // Disable the button if validation fails
      }
    };

    validateData();
  }, [inputtedData]);

  const onSubmit = async (data) => {
    try {
      // Format log entry for completion
      const recurringId = logData.parentTask;

      let entryId;

      if (logData.childId) {
        entryId = logData.childId;
      } else {
        entryId = "createId";
      }

      let customMap = [];
      if (logData.hasCycle) {
        customMap = logData.map;
      }

      const inputtedData = data.inputtedData;

      const completeForm = await completeValidationSchema.isValid({
        inputtedData,
      });
      let updatedStatus;
      if (completeForm) {
        updatedStatus = "complete";
      } else {
        updatedStatus = "in progress";
      }

      const uploadForm = new FormData();

      uploadForm.append("facility", selectedFacility);
      uploadForm.append("status", updatedStatus);
      uploadForm.append("customInput", JSON.stringify(inputtedData));
      uploadForm.append("customMap", JSON.stringify(customMap));

      uploadForm.append("parentDate", logData.date);

      for (let i = 0; i < files.length; i++) {
        uploadForm.append("files", files[i]);
      }

      await axios
        .post(`/api/log/update/${recurringId}/${entryId}`, uploadForm, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const { data } = response;
          const { id } = data;
          setLogData((prevlogData) => {
            return {
              ...prevlogData,
              childId: id,
              customInput: inputtedData,
            };
          });
          setRefresh(!refresh);
          setLoadedData(inputtedData);
          setErrorMessage([]);
        });
    } catch (error) {
      console.log("errror submitting updated data: ", error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ inputtedData: loadedData, certified: false });
      setResponse({
        show: true,
        title: "Success",
        body: "Task saved successfully",
        icon: (
          <CheckCircleOutline
            className="text-accent-500"
            style={{ fontSize: "1rem" }}
          />
        ),
      });
      console.log("Form submitted successfully");
    }
  }, [isSubmitSuccessful]);

  const handleCloseOut = async (task) => {
    const parentId = task.parentTask;
    const childId = task.childId;

    try {
      const validate = await closeoutValidationSchema.validate({
        inputtedData,
      });
      await axios
        .post(
          "/api/log/close-out/task",
          {
            logId: parentId,
            entryId: childId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log("Task closed successfully:", response.data);
          setRefresh(!refresh);
          setShowForm(false);
          setResponse({
            show: true,
            title: "Task Closed",
            body: (
              <>
                {"Task closed successfully! "}
                <button
                  className="text-xs text-accent-500 hover:text-accent-400"
                  onClick={() => navigate("/logs")}
                >
                  Navigate to task
                </button>
              </>
            ),
            icon: (
              <CheckOutlined
                className="text-accent-500"
                style={{ fontSize: "1rem" }}
              />
            ),
          });
        })
        .catch((error) => {
          console.error("Error updating task:", error);
        });
    } catch (validationError) {
      const uniqueErrors = [...new Set(validationError.errors)];
      setErrorMessage(uniqueErrors);
      console.error("Validation failed", uniqueErrors);
    }
  };

  useEffect(() => {
    if (currentUser.access !== "worker") {
      let errorMessage = "";
      if (logData.childId === undefined) {
        errorMessage += "Child ID is undefined. ";
      }
      if (isDirty) {
        errorMessage += "Save your form to closeout.";
      }
      if (isCloseoutButtonDisabled) {
        errorMessage += "Please correct all errors before closing out.";
      }

      const button = (
        <button
          type="button"
          className={`inline-flex justify-center rounded-sm bg-secondary-100  px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:bg-secondary-1000 disabled:ring-gray-400 disabled:text-gray-600 disabled:cursor-not-allowed`}
          onClick={() => handleCloseOut(logData)}
          disabled={
            logData.childId === undefined || isDirty || isCloseoutButtonDisabled
          }
          title={errorMessage}
        >
          Close Out
        </button>
      );
      setCloseoutButton(button);
    }
  }, [currentUser.access, logData, isDirty, isCloseoutButtonDisabled]);

  const renderRadioOptions = (rowIndex, name, dataType) => {
    return (
      <div className="flex gap-2 w-48 my-2">
        <label className="flex items-center gap-2 px-2 py-0.5 ">
          <input
            type="radio"
            value="Pass"
            className="border-secondary-300 text-secondary-100 focus:ring-transparent"
            {...register(`inputtedData[${rowIndex}][${name}]`)}
          />
          <span className="block text-xs font-light leading-6 text-secondary-100 pr-4">
            Pass
          </span>
        </label>
        <label className="flex items-center gap-2 px-2 py-0.5 ">
          <input
            type="radio"
            value="Fail"
            className="border-secondary-300 text-secondary-100 focus:ring-transparent"
            {...register(`inputtedData[${rowIndex}][${name}]`)}
          />
          <span className="block text-xs  leading-6 text-secondary-100 font-light pr-4">
            Fail
          </span>
        </label>
        {dataType === "radio-na" && (
          <label className="rounded-sm bg-secondary-1100 w-fit flex items-center gap-2 px-2 py-0.5 border-transparent border focus-within:border-reds-300 focus-within:border">
            <input
              type="radio"
              value="N/A"
              className="border-secondary-700 text-secondary-100 focus:ring-transparent"
              {...register(`inputtedData[${rowIndex}][${name}]`)}
            />
            <span className="block text-xs  leading-6 text-secondary-100 font-light pr-4">
              N/A
            </span>
          </label>
        )}
      </div>
    );
  };

  const handleViewFile = async (link) => {
    const file = files.find((file) => file.name === link);

    if (file) {
      // If the file is in the files state, create a blob URL and open it
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, "_blank");
    } else {
      try {
        const encodedLink = encodeURIComponent(link);
        await axios
          .get("/api/log/file", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: { encodedLink },
          })
          .then((response) => {
            window.open(response.data.url, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching file:", error);
          });
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
  };

  function extractFilenameFromUrl(input) {
    if (input.includes("http://") || input.includes("https://")) {
      const parts = input.split("/");
      return parts[parts.length - 1].split("-").slice(0, -1).join("-");
    } else {
      return input;
    }
  }

  const handleRemoveFile = (file, rowIndex, name, fileIndex) => {
    const newFiles = files.filter((f) => f.name !== file);
    setFiles(newFiles);
    const newFileNames = inputtedData[rowIndex][name].filter(
      (f, index) => index !== fileIndex
    );
    setValue(`inputtedData[${rowIndex}].${name}`, newFileNames, {
      shouldDirty: true,
    });
  };

  const renderFileOptions = (rowIndex, name) => {
    return (
      <div className="flex gap-2 my-2 h-full items-center">
        <div className="">
          {inputtedData[rowIndex]?.[name] &&
            inputtedData[rowIndex][name].length > 0 &&
            inputtedData[rowIndex][name].map((file, fileIndex) => (
              <div key={fileIndex} className="flex items-center  ">
                <div className="flex justify-between items-center bg-secondary-1100 rounded-sm w-full">
                  <button
                    className="px-2 text-xs text-secondary-100 truncate"
                    type="button"
                    onClick={() => {
                      handleViewFile(file);
                    }}
                  >
                    {extractFilenameFromUrl(file)}
                  </button>
                  <button
                    onClick={() =>
                      handleRemoveFile(file, rowIndex, name, fileIndex)
                    }
                    type="button"
                    className="border-l px-2 border-secondary-900  text-reds-500"
                  >
                    <Close fontSize="1rem" />
                  </button>
                </div>
              </div>
            ))}
        </div>
        <input
          type="file"
          className="hidden"
          id={`inputtedData.${rowIndex}.${name}`}
          accept=".png,.jpg,.jpeg,.pdf"
          onChange={(e) => {
            if (e.target.files.length > 0) {
              const newFile = e.target.files[0];
              setFiles((prevFiles) => [...prevFiles, newFile]);
              setValue(
                `inputtedData[${rowIndex}].${name}`,
                [...(inputtedData[rowIndex]?.[name] || []), newFile.name],
                {
                  shouldDirty: true,
                }
              );
            }
            e.target.value = null;
          }}
        />
        <label htmlFor={`inputtedData.${rowIndex}.${name}`} className="w-full ">
          <button
            type="button"
            onClick={() =>
              document
                .getElementById(`inputtedData.${rowIndex}.${name}`)
                .click()
            }
            className={`flex w-48 text-secondary-100 text-xs  items-center justify-center py-2 border border-secondary-1000 focus:ring-0 hover:border-dashed hover:shadow-sm hover:text-secondary-300 ${
              errors.inputtedData?.[rowIndex]?.[name]
                ? "ring-1 ring-inset ring-reds-500 focus:ring-reds-500 focus:ring-2 placeholder:text-xs placeholder:text-red-500 placeholder:italic"
                : ""
            }`}
          >
            <CloudUpload style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
            Upload File
          </button>
        </label>
      </div>
    );
  };

  const renderDefaultInput = (rowIndex, name, type) => {
    return (
      <div className="flex w-48 sm:w-96 my-2 ">
        <input
          type={type}
          className={`w-full focus:ring-0 text-xs rounded-sm outline-none border-none ${
            errors.inputtedData?.[rowIndex]?.[name]
              ? "ring-1 ring-inset border-reds-500 ring-reds-500 focus:ring-reds-500 focus:ring-2 placeholder:text-xs placeholder:text-reds-500 placeholder:italic"
              : "ring-1 ring-inset ring-secondary-1000 focus:ring-secondary-100 focus:ring-1"
          }`}
          placeholder={"Type text here"}
          {...register(`inputtedData[${rowIndex}][${name}]`)}
        />
      </div>
    );
  };

  const specifications = (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2 text-sm mb-4">
      <label className="text-secondary-100">Due Date:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        {logData?.date.slice(0, 10)}
      </span>

      <label className="text-secondary-100">Status:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        <StatusBadge status={logData?.status} />
      </span>

      <label className="text-secondary-100">Source:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        {capitalizeString(logData?.source)}
      </span>

      <label className="text-secondary-100">Details:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3 border border-secondary-1000 mb-4 px-2 rounded-md">
        {logData?.details}
      </span>
      <label className="text-secondary-100">Attachments:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        {logData?.attachments?.length > 0 ? (
          logData.attachments.map((attachment) => (
            <div key={attachment._id} className="flex gap-2">
              <button
                className="px-2 py-0.5 bg-secondary-1100 rounded-sm font-semibold text-sm cursor-pointer my-1"
                onClick={() => handleViewFile(attachment.url)}
              >
                <AttachmentOutlined fontSize="1rem" /> {attachment.name}
              </button>
            </div>
          ))
        ) : (
          <p className="text-sm italic">No attachments for this task</p>
        )}
      </span>
    </div>
  );

  const actionButtons = (
    <div className="px-4 h-full items-center bg-white flex w-full justify-between">
      {Object.keys(dirtyFields).length > 0 ? (
        <div className="flex items-center">
          <input
            id="certified"
            aria-describedby="certified-description"
            name="certified"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-accent-200 focus:ring-accent-200"
            {...register("certified")}
          />
          <label
            htmlFor="certified"
            className="ml-3 text-sm leading-6 text-gray-700"
          >
            I hereby <span className="font-bold">certify</span> that the data
            recorded above is accurate to the best of my ability.
          </label>
          {certified && (
            <div className="ml-6 bg-gray-100 rounded-md">
              <span className="p-1 text-sm text-gray-800 font-light">
                Signature:{" "}
              </span>
              <span className="font-bold font-script">
                {currentUser.firstname} {currentUser.lastname}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
      <div className="flex gap-2">
        <button
          className="inline-flex justify-center rounded-sm bg-primary px-4 py-1 text-sm font-semibold text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-100 hover:bg-secondary-1100"
          onClick={() => setShowForm(false)}
        >
          Cancel
        </button>
        <button
          disabled={!certified}
          type="submit"
          className="inline-flex justify-center rounded-sm bg-secondary-100 px-4 py-1 text-sm font-semibold text-primary shadow-sm hover:bg-secondary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:bg-secondary-1000 disabled:ring-secondary-500 disabled:text-secondary-400 disabled:cursor-not-allowed"
        >
          Save
        </button>
      </div>
    </div>
  );

  const [expandedRow, setExpandedRow] = useState(null);

  // Toggle function
  const toggleRow = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null); // Collapse if it's already expanded
    } else {
      setExpandedRow(rowId); // Expand the new row
    }
  };

  const FormContent = (
    <div className="max-h-[650px] overflow-auto thin-scrollbar mb-12">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col relative gap-4">
          {mappingData.fields.map((field, rowIndex) => (
            <div
              className="w-full flex flex-col border border-secondary-1100 rounded-md p-4 text-secondary-100 text-sm cursor-pointer"
              key={field.ID}
            >
              <div
                className="flex w-full justify-between border-b border-accent-500 hover:border-accent-400 hover:shadow py-2"
                onClick={() => toggleRow(field.ID)}
              >
                <div className="flex">
                  {/* Display data for the item */}
                  {mapKeys &&
                    Object.keys(field).map((key) => (
                      <div key={key} className="flex gap-x-2 ml-2">
                        <label className="text-secondary-100">{key}:</label>
                        {/* Pseudo input item to display map data */}
                        <input
                          className="border-none w-8 font-medium text-accent-500 focus:ring-0 text-xs p-0 cursor-pointer"
                          value={field[key]}
                          readOnly
                          {...register(`inputtedData[${rowIndex}].${key}`)}
                        />
                      </div>
                    ))}
                </div>
                <div className="text-secondary-100 px-2">
                  {expandedRow === field.ID ? (
                    <ExpandLess style={{ fontSize: "1rem" }} />
                  ) : (
                    <ExpandMore style={{ fontSize: "1rem" }}/>
                  )}
                </div>
              </div>
              {/* Display inputs */}
              {expandedRow === field.ID && (
                <>
                  {logData.inputs.map((input) => (
                    <div
                      key={input.name}
                      className="flex justify-between border border-secondary-1000 rounded-sm my-2 px-4 py-2"
                    >
                      <label className="text-left">
                        {decodedString(input.name)}:
                      </label>
                      <div>
                        {(() => {
                          switch (input.dataType) {
                            case "file":
                              return renderFileOptions(rowIndex, input.name);
                            case "radio":
                            case "radio-na":
                              return renderRadioOptions(
                                rowIndex,
                                input.name,
                                input.dataType
                              );
                            default:
                              return renderDefaultInput(
                                rowIndex,
                                input.name,
                                input.dataType
                              );
                          }
                        })()}
                      </div>
                    </div>
                  ))}

                  <div className="flex justify-between border border-secondary-1000 rounded-sm my-2 px-4 py-2 ">
                    <label className="text-secondary-100">Notes:</label>
                    {renderDefaultInput(rowIndex, "Notes", "text")}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <div className="fixed inset-x-0 lg:left-72 bottom-0 border-t h-14 border-secondary-1000 z-40">
          {actionButtons}
        </div>
      </form>
    </div>
  );

  const [showSpecs, setShowSpecs] = useState(false);

  return (
    <div className=" flex flex-col">
      <div className="mx-4 lg:mx-6">
        {errorMessage.length > 0 && (
          <div className="col-span-2 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CancelOutlined
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Unable to close out task
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul role="list" className="list-disc space-y-1 pl-5">
                    {errorMessage.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {errors.length > 0 && (
          <div className="col-span-2 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CancelOutlined
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Unable to save task. Please correct all errors.
                </h3>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-between my-2 py-2 text-secondary-100 border-b border-secondary-1000">
          <span className=" font-medium py-2">Details</span>
          <button
            className="px-12 py-2 font-medium text-sm bg-secondary-1100 rounded-sm"
            onClick={() => setShowSpecs(!showSpecs)}
          >
            {showSpecs ? "Hide" : "Show"} Details
          </button>
        </div>
        {showSpecs && specifications}
        {FormContent}
      </div>
    </div>
  );
};

export default TableFormRHF;
