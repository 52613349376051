import React, { useContext, useState, useEffect } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import WorkOrderDetails from "./WorkOrderDetails";
import { ArrowBack, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TaskNotes from "../../updatedcomponents/modals/TaskNotes";
import { FacilityContext } from "../../context/FacilityContext";
import axios from "axios";
import { useSwipeable } from "react-swipeable";
import { Tooltip } from "react-tooltip";
import { WorkOrderContext } from "../../context/WorkOrderContext";

const WorkOrderInstance = () => {
  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const { selectedFacility } = useContext(FacilityContext);
  const { selectedWorkOrder, setSelectedWorkOrder, fetchAdjacentWorkOrder } =
    useContext(WorkOrderContext);

  const handleAdjacentWorkOrder = (direction) => {
    fetchAdjacentWorkOrder(direction, selectedFacility);
  };

  useEffect(() => {
    const fetchWorkOrder = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/api/work-order/instance/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSelectedWorkOrder(response.data);
      } catch (err) {
        console.error("Error fetching work order:", err);
      }
    };

    if (id) {
      fetchWorkOrder();
    }
  }, [id, setSelectedWorkOrder]);

  const navigateBack = () => {
    window.history.back();
  };


  

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      currentUser.access !== "worker" && handleAdjacentWorkOrder("next");
    },
    onSwipedRight: () => {
      currentUser.access !== "worker" && handleAdjacentWorkOrder("prev");
    },
  });

  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteClick = () => {
    setShowDelete(true);
  };

  const handleDelete = (selectedWorkOrder) => {
    const workOrderId = selectedWorkOrder._id;
    try {
      axios.delete(`/api/work-order/delete/${workOrderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      navigateBack();
    } catch (error) {
      console.error("Error deleting work order:", error);
    }
  };

  const [selectedNotes, setSelectedNotes] = useState(null);

  const onCloseNotes = () => {
    setSelectedNotes(null);
  };

  const [images, setImages] = useState([]);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const keys = selectedWorkOrder?.attachments?.map((url) =>
      new URL(url).pathname.substring(1)
    );
    async function fetchImages(keys) {
      try {
        const queryString = new URLSearchParams({
          keys: keys.join(","),
        }).toString();

        const response = await axios.get(
          `/api/work-order/images?${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setImages(response.data.map((image) => image.url));
      } catch (error) {
        console.error("Error fetching image URLs:", error);
      }
    }
    async function fetchUsers() {
      if (selectedFacility) {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `/api/work-order/users/${selectedFacility}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUsers(response.data);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      }
    }
    if (selectedWorkOrder !== null) {
      if (keys?.length > 0) {
        fetchImages(keys);
      }
    }
    fetchUsers();
  }, [selectedWorkOrder, selectedFacility]);


  return (
    <>
      <PageHeader
        title={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={navigateBack}
          >
            <ArrowBack style={{ fontSize: "1rem" }} /> Back to Work Orders
          </button>
        }
        buttons={
          currentUser.access !== "worker" && (
            <div className="gap-2  hidden sm:flex">
              <button
                className="text-secondary-300 hover:text-secondary-100 hover:bg-secondary-1100 rounded-full px-0.5 py-0.5"
                onClick={() => handleAdjacentWorkOrder("prev")}
                data-tooltip-id="adjacent_work_order"
                data-tooltip-content="Previous Work Order"
                data-tooltip-place="bottom"
              >
                <ChevronLeft />
              </button>
              <button
                className="text-secondary-300 hover:text-secondary-100 hover:bg-secondary-1100 rounded-full px-0.5 py-0.5"
                onClick={() => handleAdjacentWorkOrder("next")}
                data-tooltip-id="adjacent_work_order"
                data-tooltip-content="Next Work Order"
                data-tooltip-place="bottom"
              >
                <ChevronRight />
              </button>
              <Tooltip id="adjacent_work_order" place="bottom" />
            </div>
          )
        }
      />
      <div {...swipeHandlers}>
        {selectedWorkOrder && images && users && (
          <WorkOrderDetails
            key={selectedWorkOrder._id}
            workOrder={selectedWorkOrder}
            setWorkOrder={setSelectedWorkOrder}
            handleDeleteClick={handleDeleteClick}
            permissions={currentUser.access}
            setSelectedNotes={setSelectedNotes}
            images={images}
            users={users}
          />
        )}
      </div>

      {selectedNotes && (
        <TaskNotes
          selectedTask={selectedNotes}
          showNotes={selectedNotes !== null}
          setShowNotes={onCloseNotes}
        />
      )}
      {showDelete && (
        <GenericModal
          text={"Delete this work order."}
          item={selectedWorkOrder}
          handleFunction={handleDelete}
          showModal={showDelete}
          setShowModal={setShowDelete}
        />
      )}
    </>
  );
};

export default WorkOrderInstance;
