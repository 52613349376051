import { useEffect, useState } from "react";
import axios from "axios";
import { formatDate } from "../utils/FormatFunctions";

const useFetchData = (url, params={}, dependencies=[], defaultValue) => {
  const [data, setData] = useState(defaultValue);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      if (!url || typeof url !== "string") return;
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
              url,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                params: {
                  ...params,
                  startDate: formatDate(params.startDate),
                  endDate: formatDate(params.endDate),
                  filters: JSON.stringify(params.filters || {}),
                },

              }
            );
            setData(response.data);

        } catch (error) {
          console.error("Error fetching data", error);
          setError(error)
        }
      };
      fetchData();
  },dependencies)

  return {data, error};
};

export default useFetchData;
